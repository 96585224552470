import { Button, List, Spin } from "antd"
import { Link, useNavigate } from "react-router-dom";
import { getRelativePersonOfFamilyLink } from "../../../models/familyLink";
import { useGetFamilyListQuery } from "../../../services/familyLink";
import styles from './FamilyLinkList.module.css';

export const FamilyLinkList = (props: {personId: number}) => {
    const {personId} = props;
    const navigate = useNavigate();
    const {data, isLoading} = useGetFamilyListQuery({personId});

    return (<div className="test">
        {isLoading && <Spin size="small"></Spin>}
        { data? 
        data.total == 0? <span>Не указано</span> :
        data?.items.map((item, idx)=>{
            const {burialId:bId, personName, siblingName} = getRelativePersonOfFamilyLink(item, personId);
            return (
            <div className={styles.linkItemContainer} key={`${item.person.id}${item.relativePerson.id}${idx}`}>
                <div><Link to={`/burial/${bId}`} state={{from:'list'}}>{siblingName}</Link></div>
                <div className={styles.center}>{personName}</div>
            </div>)
        }
        ): <></>}

        {/* <List 
            dataSource={data?.items} loading={isLoading}
            renderItem={(item)=>(
                <List.Item>
                    <List.Item.Meta 
                        title={<Button type="link" onClick={()=>navigate(`${item.relativePersonBurialId}`)}>{item.relativePersonSiblingName}</Button>} 
                        description={getUserName(item.relativePerson)}
                    />
                </List.Item>
            )}>
        </List> */}
    </div>)
}