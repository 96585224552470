import DocViewer, { DocViewerRenderers, IDocument } from "@cyntler/react-doc-viewer";
import { Button, Popover, QRCode, Space, Spin, Tag, Image, Row } from "antd";
import { Burial, toBurialViewModel } from "../../../models/burial";
import { PersonShort } from "../../person/personShort/PersonShort";
import Map from '../map';
import styles from './BurialDetail.module.css';
import tombpng from '../../../assets/noimage.png';
import { useEffect, useRef, useState } from "react";
import { getUserAsHidden, getUserName } from "../../../models/userUtils";
import { BurialGroup } from "../burialGroup/BurialGroup";
import { Link } from "react-router-dom";
import AppTooltip from "../../../app/base/tooltip";
import { ToolTip_Group_AddUser, ToolTip_Service_Description } from "../../../app/consts/tooltip";
import {QRCodeCanvas, QRCodeSVG} from 'qrcode.react';
import { CopyOutlined} from '@ant-design/icons';
import { IBurialDetailProps } from "./types";
import { useAuth } from "../../../app/hooks";
import { useLazyCreateBurialGroupRequestQuery } from "../../../services/burialGroup";
import notificationService from "../../../app/toast/notificationService";

function downloadStringAsFile(data: string, filename: string) {
    let a = document.createElement('a');
    a.download = filename;
    a.href = data;
    a.click();
}

export const BurialDetail = (props: IBurialDetailProps) => {
    const user = useAuth();
    const {burial, isLoading, isPreBurial, preBurialModifiedFields,preview=false} = props;
    const canEdit=(burial?.canEdit && !burial?.hasUncommitedChanges && user && !user?.hasModerateRight) || false;
    const [documents, setDocuments] = useState<IDocument[]>([]);
    const [documentDesc, setDocumentDesc] = useState<string>();
    const [mapIsOpen, setMapIsOpen] = useState(false);
    const [burialGroupRequest, {isLoading: isMakingRequest}] = useLazyCreateBurialGroupRequestQuery();

    useEffect(()=>{
        setDocuments([]);
        setDocumentDesc(undefined);
    },[burial?.id])

    if (isLoading) {
        return <div><Spin size="large" /></div>
      }
    
    if (!burial) {
    return <div>Запрошенное Захоронение не найдено</div>
    }
    const data = burial;

    const viewFile = (uri: string | undefined, desc: string | undefined)=>{
        if (uri){
            setDocumentDesc(desc);
            setDocuments([{uri}]);
        }
        else{
            setDocumentDesc(undefined);
        }
    }

    const showMap = () => {
        setMapIsOpen(true);
    }
    const hideMap = () =>{
        setMapIsOpen(false);
    }
    const downloadQR = ()=>{
        const node = document.getElementById('qrc')?.querySelector<HTMLCanvasElement>('canvas');
        if (!node) return;
        const ctx = node.getContext('2d');
        if (!ctx) return;
        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillStyle = '#fff';
        ctx.fillRect(0, 0, node.width, node.height);
        const dataURI = node.toDataURL();
        const filename = `qrcode-${burial.name}.png`;
        let a = document.createElement('a');
        a.download = filename;
        a.href = dataURI;
        a.click();

        // const node_ = document.getElementById('qrc')?.querySelector<SVGElement>('svg');
        // if (!node_) return;
        // const serializer = new XMLSerializer();
        // const fileURI =
        //   'data:image/svg+xml;charset=utf-8,' +
        //   encodeURIComponent('<?xml version="1.0" standalone="no"?>' + serializer.serializeToString(node_));


        // downloadStringAsFile(fileURI, `qrcode-${burial.name}-svg.svg`);
    }
    const makeGroupRequest = async()=>{
        const res = await burialGroupRequest({burialId: burial?.id});
        if (res.isSuccess) notificationService.info('Ваш запрос отправлен владельцу группы.')
    }

    const colorBurialIfModerAndModified = (fieldName: string)=>{
        if (preBurialModifiedFields)
        {
            if (fieldName=='person') return '';
            const prop = fieldName as (keyof typeof preBurialModifiedFields);
            if (preBurialModifiedFields[prop]) return `${styles.modified}`;
        }
        return '';
    }

    return (
            <>
            {data && 
                <div className={styles.inline}>
                    <div className={styles.iview}>
                        <div className={`${styles.row} ${styles.burialInfo}`}>
                            <div className={`${styles.pimgContainer} ${colorBurialIfModerAndModified('image')}`}>
                                <Image className={styles.bimg} src={data.image || tombpng} alt="фото захоронения" />
                            </div>
                            <div className={styles.commonInfo}>
                                <div className={styles.section}>Местоположение</div>
                                <div className={`${styles.sectionp} ${colorBurialIfModerAndModified('cityName')}`}>Населенный пункт: {data.cityName}</div>
                                <div className={`${styles.sectionp} ${colorBurialIfModerAndModified('cemeteryName')}`}>Кладбище: {data.cemeteryName}</div>
                                <div className={`${styles.sectionp} ${colorBurialIfModerAndModified('locationNumber')}`}>Номер участка: {data.locationNumber}</div>
                                <div className={`${styles.sectionp} ${colorBurialIfModerAndModified('location')}`}>Координаты: {data.locationAsString}
                                {data.location && 
                                <Row gutter={6}>
                                 <Button size="small" icon={<CopyOutlined />} onClick={() => {navigator.clipboard.writeText(data.locationAsString || '')}}/>
                                 <Popover open={mapIsOpen} content={<Map location={data.location} burialName={data.cemeteryName} onClose={hideMap} />} trigger="click" onOpenChange={showMap}>
                                    <Button className={styles.mapLink} type="link">На карте</Button>
                                 </Popover>
                                 </Row> }
                                </div>
                                <div className={styles.section}>Примечание</div>
                                <div className={`${styles.sectionp} ${colorBurialIfModerAndModified('note')}`}> {data.note || ' '}</div>
                                <div className={styles.section}> {data.tags?.map((i,idx)=><Tag key={`${idx}${i}`}>{i}</Tag>)}
                                </div>
                                <div className={styles.section}>Владелец</div>
                                <div className={styles.sectionp}>
                                    {getUserAsHidden(data.createdByUser)} 
                                    {user && !canEdit && !preview && <Button type="link" onClick={makeGroupRequest} loading={isMakingRequest}>Присоединиться к группе</Button>}
                                </div>
                                <div>
                                {data.attachedFile && <Button type="link" onClick={()=>viewFile(data.attachedFile, 'Описание захоронения')}>Посмотреть описание</Button>}
                                {burial.canEdit && !preview && <>
                                    <Button type="link"><Link to='servicelist'>Услуги</Link></Button>
                                    <AppTooltip title={ToolTip_Service_Description}/>
                                </>}
                                </div>
                            </div>
                            <div className={styles.last} id='qrc'>
                                <div></div>
                                <QRCode size={1000} value={window.location.href} bordered={false} style={{display: 'none'}} />
                                <QRCodeSVG size={100} value={window.location.href} />
                                <div>
                                    <a onClick={downloadQR}>Скачать QR-код</a>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className={styles.section}>Персоны</div>
                            <Space direction="vertical">
                            {data.persons && data.persons.map((p, i)=>
                                <>
                                    <PersonShort key={`person-${i}_${p.id}`} 
                                        data={p} 
                                        onViewAttached={viewFile} 
                                        editable={burial.canEdit} 
                                        preBurialPersonModifiedFields={preBurialModifiedFields?.persons?.find(i=>i.id==p.id)}
                                        preview={preview}
                                     />
                                    {canEdit && !preview && <div key={`div${i}_${p.id}`} className={styles.editPerson}><Button><Link to={`edit`}>Редактировать </Link></Button></div>}
                                </>
                            )}
                            </Space>
                        </div> 
                        {burial.canEdit && !isPreBurial &&         
                        <div className="row">
                            <div>
                                <Space direction="horizontal">
                                <span className={styles.section}>Группа </span>
                                <AppTooltip placement="rightTop"
                                    title={ToolTip_Group_AddUser}
                                />
                                </Space>
                            </div>
                            <BurialGroup groupId={data.id} />
                        </div> 
                        }                      
                    </div>
                    <div className={styles.fview}>
                        <div>{documentDesc}</div>
                        <DocViewer config={{header: {disableHeader: true, disableFileName: true}}} documents={documents} pluginRenderers={DocViewerRenderers} />
                    </div>
                    <div style={{clear: 'both'}}>
                    </div>
                </div>
            }
            </>

    )
}