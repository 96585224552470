import { Button, Space, Spin, Statistic } from 'antd';
import styles from './Home.module.css';
import cladbiche from '../../assets/cladb.jpg';
import cladbiche2 from '../../assets/cladb2.jpg';
import { News } from '../../models/news';
import { formatDateTime } from '../../services/models/util';
import { useNavigate } from 'react-router-dom';
import { buildListRequest, ListRequest } from '../../models/base/list';
import { useGetNewsListQuery } from '../../services/news';
import { useGetStatisticQuery } from '../../services/statistic';

const params: ListRequest = buildListRequest(0,3);

export const Home = () => {
  const navigate = useNavigate();
  const {data: news, isLoading } = useGetNewsListQuery(params);
  const {data: statistic, isLoading: isStatisticLoading} = useGetStatisticQuery();

  return (
      <div className={styles.centeredHeader}>
        <Space.Compact direction='vertical' block size={'large'}>
          <h2>О проекте</h2>  
          <Space.Compact block size={'large'}>
          <div className={styles.w880}>
              На этом сайте Вы можете найти места захоронения своих родственников и близких людей, установить контакты с ныне живущими людьми, посещающими интересующие  вас захоронения, 
              узнать больше о захороненных личностях и получить другую полезную информацию по тематике поддержания памяти об ушедших от нас людях.
          </div> 
          <div className={styles.w500}>
              <img src={cladbiche} alt=''></img>
          </div>
        </Space.Compact>
        <Space.Compact block size={'large'}>
          <div className={styles.w880}>
            Каждый из Вас может внести данные о поддерживаемых Вами захоронениях для того, чтобы другие люди узнали о  Ваших предках и их могилах. 
            Установите QR-код на памятник ваших близких и введите данные о них. Любой человек сможет посмотреть Вашу информацию и установить с Вами контакт. 
            Создайте свою группу родственников и совместно ухаживайте за общими могилами предков.  Заказывайте  услуги по поддержанию  захоронений
          </div>
            <div className={styles.w500}>
              <img src={cladbiche2} alt=''></img>
            </div>
        </Space.Compact>
          <h2>Статистика:</h2>  
        </Space.Compact>
        <div className={styles.centeredText}>
          <Statistic title='Общее число захоронений в системе' loading={isStatisticLoading} value={statistic?.burialCount} />
        </div>
        <div className={styles.centeredText}>
          <Statistic title='Доступна информация о людях' loading={isStatisticLoading} value={statistic?.personCount} />
        </div>
        <div className={styles.centeredText}>
          <Statistic title='Зарегистрированных пользователей' loading={isStatisticLoading} value={statistic?.userCount} />
        </div>
        <h2>Новости:</h2>
        { isLoading? <Spin></Spin>
        : news ? news.items.map((item:News) => {
          return(
            <div key={item.id} className={styles.w880}>
                <h4 className={styles.centeredText}>Новость {item.createdTimeStamp}</h4>
                  {item.name}
          </div>
          )
          })
          :<></>
        }
        <div className={styles.w880}>
          <h2><Button className={styles.allNewsBtn} type="link" onClick={() => navigate('/news')}>Все новости</Button></h2>
        </div>
      </div>
  );
};
