import { Button, Popover, Space, Image, Tag } from 'antd';
import { getUserAge, getUserAsHidden, getUserGenderString, getUserName } from '../../../models/userUtils';
import styles from './PersonShort.module.css';
import pimg from '../../../assets/person.png';
import { FamilyLinkList } from '../../familyLink/list/FamilyLinkList';
import { useNavigate } from 'react-router-dom';
import { IPersonShortProps } from './types';
import AppTooltip from '../../../app/base/tooltip';
import { ToolTip_RelationLink_CanRead,ToolTip_RelationLink_CanEdit } from '../../../app/consts/tooltip';
import { IPersonFIOStyle } from '../../../models/userUtils';
import { PersonFio } from './PersonFio';
import { PersonLifeTimeFrame } from './PersonLifeTimeFrame';

export const PersonShort = (props: IPersonShortProps) => {
    const {data, onViewAttached, editable=false, preBurialPersonModifiedFields, preview=false} = props;
    const navigate = useNavigate();
    const colorPersonIfModified = (fieldName: string)=>{
        if (preBurialPersonModifiedFields)
        {
            const prop = fieldName as (keyof typeof preBurialPersonModifiedFields);
            if (preBurialPersonModifiedFields[prop]) return `${styles.modified}`;
        }
        return '';
    }
    const fioStyled = (): IPersonFIOStyle|undefined => {
        return preBurialPersonModifiedFields?
        {
            first:preBurialPersonModifiedFields.firstName?`${styles.modified}`:undefined,
            last:preBurialPersonModifiedFields.lastName?`${styles.modified}`:undefined,
            middle:preBurialPersonModifiedFields.middleName?`${styles.modified}`:undefined,
            lastreplaced:preBurialPersonModifiedFields.lastNameReplaced?`${styles.modified}`:undefined
        }
        :undefined};

    return (
        <>
            <div key={`pp-${data.id}`} className={styles.row}>
                <Space size={'large'}>
                    <div className={`${styles.pimgContainer} ${colorPersonIfModified('image')}`}>
                        <Image className={styles.pimg} src={data.image || pimg} />
                    </div>
                    <div>

                                <Space direction={'vertical'}>
                                <div key={`ppp1-${data.id}`}>
                                    <div className={`${styles.personName} ${styles.personInfo}`}>
                                        <PersonFio data={data} styled={fioStyled()} />
                                    </div>
                                    <div className={styles.personInfo}>
                                        <div>Годы жизни: </div>
                                        <div>
                                            <PersonLifeTimeFrame birth={data.birth} death={data.death} styled={{birth:colorPersonIfModified('birth'),death:colorPersonIfModified('death')}} />
                                             
                                        </div>
                                        <div>{getUserAge(data.age)}</div>
                                    </div>
                                    <div className={styles.personInfo}>
                                        <div>Место рождения: </div>
                                        <div className={`${colorPersonIfModified('birthPlace')}`}>
                                            {data.birthPlace}
                                        </div> 
                                    </div>
                                    <div className={styles.personInfo}>
                                        <div>Пол: </div>
                                        <div className={`${colorPersonIfModified('genderId')}`}>
                                            {getUserGenderString(data.gender)}
                                        </div>
                                    </div>
                                    <div className={`${colorPersonIfModified('tags')}`}> 
                                        {data.tags?.map((i,idx)=><Tag key={`${idx}${i}`}>{i}</Tag>)}
                                    </div>
                                    <div className={`${colorPersonIfModified('note')}`}>
                                        {data.note?'Примечание: '+ data.note: ''}
                                    </div>
                                    <div>Создал: {getUserAsHidden(data.createdByUser)}</div>
                                </div>
                                <div key={`ppp2-${data.id}`}>
                                    {data.attachedFile && <Button type='link' onClick={()=>onViewAttached(data.attachedFile || '', `Описание персоны ${getUserName(data)}`)}  className={`${colorPersonIfModified('attachedFile')}`}>Посмотреть описание</Button>}
                                    {!preview ?
                                    !editable ? 
                                    <Popover content={<FamilyLinkList personId={data.id}/>} trigger='click'>
                                        <Button type='link'>Нити памяти <AppTooltip title={`${editable?ToolTip_RelationLink_CanEdit:ToolTip_RelationLink_CanRead}`}/>
                                        </Button>
                                    </Popover>
                                    : 
                                    <>
                                        <Button type='link' onClick={()=>navigate(`/person/${data.id}`)}>Нити памяти</Button>
                                        <AppTooltip title={`${editable?ToolTip_RelationLink_CanEdit:ToolTip_RelationLink_CanRead}`}/>
                                    </>
                                    :<></>}
                                </div>
                                <div>                                    
                                </div>
                                {/* {familyOpen && <PersonFamilyForm personId={data.id} editable={editable} />} */}
                                </Space>


                    </div>
                </Space>
            </div>
        </>
    );
}