import { Button, Form, Input } from "antd";
import { InfoCircleOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';
import AppCard from "../../../app/base/card";
import { changePasswordRequest, useLazyChangePasswordQuery } from "../../../services/auth";
import styles from './Profile.module.css';

export const ChangePassword = ()=>{
    const [changePassword, { isLoading }] = useLazyChangePasswordQuery();
    const change = async(data: changePasswordRequest)=>{
        await changePassword(data);
    }

    return (
    <AppCard title="Смена пароля">
        <div className={styles.formPswChg}>
        <Form name="changePswdForm" onFinish={change} layout="vertical">
            <Form.Item name="currentPassword" rules={[{ required: true, message: 'Введите пароль!' }]} label='Текущий пароль'>
                <Input.Password 
                type="password"
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="пароль"/>
            </Form.Item>
            <Form.Item name="newPassword" rules={[{ required: true, message: 'Введите новый пароль!' }]} label='Новый пароль'>
                <Input.Password 
                type="password"
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="пароль"/>
            </Form.Item>
            <Form.Item>
                <Button className='defaultActionButton' loading={isLoading} htmlType='submit'>Изменить пароль</Button>
            </Form.Item>
        </Form>
        </div>
    </AppCard>
    )
}