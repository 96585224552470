import { EventEmitter } from 'events';

import { INotificationItem, NotificationTitle } from './notificationModel';

const EventName = 'NotificationCreated';

class NotificationService extends EventEmitter {
  timeOut: number;

  constructor() {
    super();
    this.timeOut = 3000;
  }

  /** Создать уведомление */
  private create(notification: INotificationItem) {
    const defaultNotification: INotificationItem = {
      type: 'info',
      title: NotificationTitle.INFO,
      message: '',
      timeOut: this.timeOut
    };

    // this.notificationList.push(Object.assign(defaultNotification, notification));
    // this.emitCreated();
    this.emit(EventName, Object.assign(defaultNotification, notification));
  }

  /** Создать информационное уведомление  */
  info(message: string) {
    this.create({
      type: 'info',
      title: NotificationTitle.INFO,
      message: message
    });
  }

  /** Создать уведомление об успешной операции */
  success(message: string) {
    this.create({ type: 'success', title: NotificationTitle.SUCCESS, message });
  }

  /** Создать предупреждающее уведомление  */
  warning(message: string) {
    this.create({ type: 'warning', title: NotificationTitle.WARNING, message });
  }

  /** Создать уведомление об ошибке */
  error(message: string) {
    this.create({
      type: 'error',
      title: NotificationTitle.ERROR,
      message: message
    });
  }

  //   remove(notification: INotificationItem) {
  //     this.notificationList = this.notificationList.filter(n => notification.id !== n.id);
  //     this.emitChange();
  //   }

  //   private emitCreated() {
  //     this.emit(EventName, this.notificationList);
  //   }

  addNotificationCreatedListener(callback: (notifications: INotificationItem) => void) {
    this.addListener(EventName, callback);
  }

  removeNotificationCreatedListener(callback: (notifications: INotificationItem) => void) {
    this.removeListener(EventName, callback);
  }
}

export default new NotificationService();
