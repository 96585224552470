import { useState } from "react";
import AppCard from "../../app/base/card"
import AppList from "../../app/base/list"
import { ListRequest, PagingInfo, buildListRequest, initialFilterParams } from "../../models/base/list";
import { useConfirmFamilyLinkRequestMutation, useGetFamilyLinkRequestListQuery, useRejectFamilyLinkRequestMutation } from "../../services/familyLink";
import { Button } from "antd";
import { OwnedLinkRequestListItem } from "./OwnedLinkRequestListItem";

export const OwnedLinkRequestList = ()=>{
    const [searchParams, setSearchParams] = useState<ListRequest>(initialFilterParams);
    const {data:requests, isLoading} = useGetFamilyLinkRequestListQuery(searchParams);
    const [confirmRequest, {isLoading: isConfirming}] = useConfirmFamilyLinkRequestMutation();
    const [rejectRequest, {isLoading: isRejecting}] = useRejectFamilyLinkRequestMutation();
    const [actionId, setActionId] = useState<number>();

    const fetch = ({page, pageSize}:PagingInfo)=>{
        setSearchParams(buildListRequest(page,pageSize));
    }
    const confirm = (id: number)=>{
        setActionId(id);
        confirmRequest(id);
    }
    const reject = (id: number)=>{
        setActionId(id);
        rejectRequest(id);
    }
        
    return ( 
        <AppCard title="Запросы на добавление связей к моим персонам">
            <AppList
                isLoading={isLoading}
                showFilter = {false}
                data={requests?.items || [] }
                totalItems={requests?.total || 0}
                renderItem={(item, index) => (<OwnedLinkRequestListItem key={index} {...item} />)}
                fetchData = {fetch}
                renderActions={(item)=>  
                   
                [ 
                    <Button disabled={ typeof item.granted !=='undefined' } type="link" loading={isConfirming && actionId==item.id} onClick={()=>confirm(item.id)}>принять</Button>,
                    <Button disabled={ typeof item.granted !=='undefined' } type="link" loading={isRejecting && actionId==item.id} onClick={()=>reject(item.id)}>отклонить</Button>
                ]
                }
                showTotal = {true}
            />
        </AppCard>
)}