import { Button, Form, Input } from "antd";
import AppCard from "../../../app/base/card";
import styles from '../Admin.module.css';
import { useCreateCityMutation } from "../../../services/city";

export const CreateCity = () => {
    const [form] = Form.useForm();
    const [create, { isLoading }] = useCreateCityMutation();
  
    const handleSubmit = async ({name}: {name: string}) => {
      await create(name);
      form.resetFields();
    }
  
    return (
        <AppCard title="Добавить местонахождение кладбища" className={styles.newItemContainer}>
            <Form form={form} onFinish={handleSubmit}>
                <Form.Item label='Название' name="name" rules={[{required: true, message: 'Введите название.'}]}>
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button htmlType="submit" loading={isLoading}>Сохранить</Button>
                </Form.Item>
            </Form>
      </AppCard>
    )
  }
  