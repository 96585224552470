import { Col, DatePicker, Form, FormInstance, Input, Row, Select } from "antd";
import { IDictionary } from "../../models/base/dictionary";
import styles from './BurialServiceList.module.css';

export const BurialServiceFilter = ({statusTypeSource:statusTypes, filterForm, typeSource:types}: {
    statusTypeSource : IDictionary[] | undefined,
    filterForm:  FormInstance<any>,
    typeSource:  IDictionary[] | undefined
}) => {
    return <Form form={filterForm} layout='vertical' className={styles.findFilter}>

    <Row>
        <Col span={24}>
            <Form.Item name='statusTypeIds' label='Статус услуги'>
                <Select options={statusTypes?.map(({id,name})=>{return {label:name, value:id};})} allowClear mode="multiple" showArrow></Select>
            </Form.Item> 
        </Col>       
    </Row>
    <Row>
        <Col span={24}>
            <Form.Item name='typeIds' label='Тип услуги'>
                <Select options={types?.map(({id,name})=>{return {label:name, value:id};})} allowClear mode="multiple" showArrow></Select>
            </Form.Item>         
        </Col>
    </Row>
    <Row>
        <Col span={6}> 
            <Form.Item name='lastName' label='Фамилия'>
                <Input/>
            </Form.Item>
        </Col>
        <Col span={6}> 
            <Form.Item name='firstName' label='Имя'>
                <Input/>
            </Form.Item>
        </Col>
        <Col span={6}>          
            <Form.Item name='middleName' label='Отчество'>
                <Input/>
            </Form.Item>
        </Col>
        <Col span={6}>
            <Form.Item name='createDatePeriod' label='Дата заказа'>
                <DatePicker.RangePicker allowEmpty={[true,true]} showTime={false} format="DD.MM.YYYY" />
            </Form.Item>
        </Col>
    </Row>
</Form>
}