import { noInfoString } from "../../../app/consts/tooltip";
import { Burial, BurialCreateModel, BurialViewModel, EmptyBurial, StrToLocation, StrToTags } from "../../../models/burial";
import { Person, PersonViewModel } from "../../../models/person";
import { User } from "../../../models/user";
import { formatDateTime } from "../../../services/models/util";

export const BurialModelToFormData = (values: BurialCreateModel): FormData => {
    if (!values.name) values.name = 'Название';
    if (values.tagsAsString)  values.tags = StrToTags(values.tagsAsString);
    if (values.locationAsString) values.location = StrToLocation(values.locationAsString);

    const formData = new FormData();
    Object.entries(values).map(obj => {
      if (obj[1])
        if (obj[0]=='persons'){
            values.persons?.forEach((i, idx)=>{
                if (i.id) formData.append(`persons[${idx}].id`, i.id.toString());
                if (i.attachedFile && Array.isArray(i.attachedFile) && i.attachedFile.length>0){  
                    if (i.attachedFile[0].originFileObj) 
                        formData.append(`persons[${idx}].attachedFile`, i.attachedFile[0].originFileObj);
                    else                
                    if (i.attachedFile[0].name) 
                        formData.append(`persons[${idx}].prevAttachedFile`, i.attachedFile[0].name); 
                }               
                if (i.firstName) formData.append(`persons[${idx}].firstName`, i.firstName);
                if (i.lastName) formData.append(`persons[${idx}].lastName`, i.lastName);
                if (i.middleName) formData.append(`persons[${idx}].middleName`, i.middleName);
                if (i.birth) formData.append(`persons[${idx}].birth`, i.birth.toISOString());
                if (i.death) formData.append(`persons[${idx}].death`, i.death.toISOString());
                if (i.birthPlace) formData.append(`persons[${idx}].birthPlace`, i.birthPlace);                
                if (i.image && Array.isArray(i.image) && i.image.length>0){ 
                    if (i.image[0].originFileObj) formData.append(`persons[${idx}].image`, i.image[0].originFileObj);
                else
                    if (i.image[0].name) formData.append(`persons[${idx}].prevImage`, i.image[0].name);            
                }
                if (i.gender) formData.append(`persons[${idx}].genderId`, i.gender.toString());  
                if (i.note) formData.append(`persons[${idx}].note`, i.note);
                if (i.tagsAsString){ 
                    const tagsValue = StrToTags(i.tagsAsString);
                    if (tagsValue) tagsValue.forEach(i=> formData.append(`persons[${idx}].tags[]`, i));
                }
                if (i.lastNameReplaced) formData.append(`persons[${idx}].lastNameReplaced`, i.lastNameReplaced);         
            })
        }
        else if (obj[0]=='image' && Array.isArray(obj[1]) && obj[1].length>0) { 
            if (obj[1][0].originFileObj)
                formData.append(`image`, obj[1][0].originFileObj); 
            else if (obj[1][0].name) formData.append(`prevImage`, obj[1][0].name);
        }
        else if (obj[0]=='attachedFile' && Array.isArray(obj[1]) && obj[1].length>0){
            if (obj[1][0].originFileObj)
                formData.append(`attachedFile`, obj[1][0].originFileObj);
            else if (obj[1][0].name) formData.append(`prevAttachedFile`, obj[1][0].name);
        }
        else if (obj[0]=='tags'){
            values.tags?.forEach(
                i=> formData.append('tags[]',i)
            )
        }
        else if (obj[0] == 'location' && values.location){
            formData.append('location.lat', values.location.lat.toString().replace('.',','));
            formData.append('location.lon', values.location.lon.toString().replace('.',','));
        }
        else
            formData.append(obj[0],obj[1])
    });
    return formData;
}

export const BurialCreateModelToBurialPreview = (values: BurialCreateModel, sourceBurial: Burial | undefined, currUser: User): BurialViewModel => {
    let result: BurialViewModel = EmptyBurial ;
    
    result =  {...result,
        id: sourceBurial?.id || 0,
        canEdit: true,
        createdByUser: sourceBurial?.createdByUser || currUser,
        name: values.name,
        locationNumber: values.locationNumber,
        location: values.location,
        locationAsString: values.locationAsString,
        cityName: values.cityName,
        cemeteryName: values.cemeteryName,
        note: values.note,
        image: values.image && values.image.length>0 ? (values.image[0].url || values.image[0].thumbUrl): undefined,
        attachedFile: values.attachedFile && values.attachedFile.length>0 ? (values.attachedFile[0].url || values.attachedFile[0].thumbUrl): undefined,
    };
    if (values.locationAsString) result.location = StrToLocation(values.locationAsString);
    if (values.persons){
        result.persons = [];
        values.persons?.forEach((i, idx)=>{
            result.persons?.push({
                id: i.id,
                createdByUser: sourceBurial?.persons?.find(x=>x.id==i.id)?.createdByUser || currUser,
                firstName: i.firstName,
                lastName: i.lastName,
                middleName: i.middleName,
                lastNameReplaced: i.lastNameReplaced,
                birth: i.birth ? formatDateTime(i.birth.toISOString()) : noInfoString,
                death: i.death? formatDateTime(i.death.toISOString()): noInfoString,
                birthPlace: i.birthPlace,       
                age: i.age,
                gender: i.gender,
                tags: StrToTags(i.tagsAsString),
                note: i.note,
                image:  i.image && i.image.length>0 ? (i.image[0].url || i.image[0].thumbUrl): undefined,          
                attachedFile: i.attachedFile && i.attachedFile.length>0 ? (i.attachedFile[0].url || i.attachedFile[0].thumbUrl): undefined 
            } as PersonViewModel);
        })
    }

    return result;
}