import { message } from "antd";
import { RcFile } from "antd/es/upload";

const defaultMaxAllowedSize = 2;
const defaultTypes = ['image/jpeg','image/png']

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

const beforeUpload = (file: RcFile) => {
    let isJpgOrPng = true;
    if (false){
        isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
    }
    const isLt2M = file.size / 1024 / 1024 < defaultMaxAllowedSize;
    // const isLt2M = false;
    if (!isLt2M) {
      message.error(`Размер изображения не должен превышать ${defaultMaxAllowedSize}MB!`);
    }
    return isJpgOrPng && isLt2M;
  };

export {
    getBase64,
    beforeUpload
}

const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };