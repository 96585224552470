import { baseProtectedApi } from './baseProtectedApi';
import { ListData, ListRequest, ListResponse } from '../models/base/list';
import { ApiResponse } from './models/response';
import { ServiceType, ServiceTypeCreateUpdateModel } from '../models/service';

const url = 'servicetype';

const serviceTypeApi = baseProtectedApi.injectEndpoints({
  endpoints: build => ({
    getServiceTypeList: build.query<ListData<ServiceType>, ListRequest>({
        query: (params) => ({method: 'POST', url: `${url}/list/`, body: params }),
        transformResponse: (response:ListResponse<ServiceType>, meta, arg) =>{
            return   {
                items: response.result.items,
                total: response.result.total
            }
        },
        providesTags: (result) => result?.items
        ? [
            ...result.items.map(({ id }) => ({ type: 'ServiceType' as const, id })),
            { type: 'ServiceType', id: 'LIST' },
          ]
        : [{ type: 'ServiceType', id: 'LIST' }],
      }),
    getServiceTypesAll: build.query<ServiceType[], void>({
        query: (id) => `${url}/list/full`,
        transformResponse: (response: ListResponse<ServiceType>) => {
            return response.result.items;
        }
    }),   
    updateServiceTypeItem: build.mutation<ServiceType, {id: number, data: ServiceTypeCreateUpdateModel}>({
        query(data) {
          const { id, data:body } = data
          return {
            url: `${url}/${id}`,
            method: 'PUT',
            body,
          }
        },
        invalidatesTags: (result, error, arg) => [{type:'ServiceType', id: arg.id}]
    }),
    deleteServiceTypeItem: build.mutation<{ success: boolean; id: number }, number>({
        query(id) {
          return {
            url: `${url}/${id}`,
            method: 'DELETE',
          }
        },
        invalidatesTags: ['ServiceType']
    }),
    createServiceTypeItem: build.mutation<ServiceType, ServiceTypeCreateUpdateModel>({
        query(data) {
          return {
            url: `${url}`,
            method: 'POST',
            body: data
          }
        },
        invalidatesTags: ['ServiceType']
    }),
  }),
  overrideExisting: true
});

export const { 
    useGetServiceTypesAllQuery,
    useGetServiceTypeListQuery,
    useCreateServiceTypeItemMutation,
    useUpdateServiceTypeItemMutation,
    useDeleteServiceTypeItemMutation
 } = serviceTypeApi;
