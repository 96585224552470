import { Button, Form, Input, InputNumber } from "antd";
import AppCard from "../../../app/base/card";
import { ServiceTypeCreateUpdateModel } from "../../../models/service";
import { useCreateServiceTypeItemMutation } from "../../../services/serviceType";
import styles from './ServiceTypeManager.module.css';

export const CreateServiceType = () => {
    const [form] = Form.useForm();
    
    const [createNews, { isLoading }] = useCreateServiceTypeItemMutation()
  

    const handleSubmit = async (values: ServiceTypeCreateUpdateModel) => {
      await createNews(values);
      form.resetFields();
    }
  
    return (
        <AppCard title="Добавить тип услуги" className={styles.newItemContainer}>
            <Form form={form} onFinish={handleSubmit}>
                <Form.Item name="name" label='Наименование' rules={[{required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="price" label="Цена, руб." rules={[{required: true }]} >
                    <InputNumber precision={2} min="0" className={styles.priceInput} />
                </Form.Item>
                <Form.Item>
                    <Button htmlType="submit" loading={isLoading}>Сохранить</Button>
                </Form.Item>
            </Form>
      </AppCard>
    )
  }
  