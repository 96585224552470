
import { Col, Row } from 'antd';
import { Burial, BurialViewModel } from '../../models/burial';
import { getUserName } from '../../models/userUtils';
import styles from '../burial/list/Find.module.css';
import style from './OwnedBurialList.module.css';
import { UncommitedChangesIcon } from './UncommitedChangesIcon';
import { RejectedChangesIcon } from './RejectedChangesIcon';

export const OwnedBurialListItem = (item: BurialViewModel) => {
    return (
        <Row style={{width: '100%'}}>
        <Col span={8} className={styles.resultTitle}>
            {item.persons &&
                <div>
                    {item.persons.map( (i)=> 
                            <div key={`${item.id}_${i.id}`}>
                                {getUserName(i)}
                            </div>
                    )}
                </div>
            }
        </Col>
        <Col span={6}>
        {item.persons &&
                <div>
                    {item.persons.map( (i)=> 
                            <div key={`${item.id}_${i.id}`}>
                               {i.birth} - {i.death}
                            </div>
                    )}
                </div>
            }
        </Col>
        <Col span={5}>
            {`${item.cityName} ${item.cemeteryName}`}
        </Col>
        <Col span={4}>
            {item.own?'Владелец':'Участник'}
        </Col>
        <Col span={1} className={style.centered}>
            {item.hasUncommitedChanges && <UncommitedChangesIcon />}
            {item.published!==undefined && (!item.published) && !item.hasUncommitedChanges && <RejectedChangesIcon />}
        </Col>
        </Row>
    )
}