import { Button, Form, Input, Spin } from "antd";
import { useParams } from "react-router-dom"
import notificationService from "../../../app/toast/notificationService";
import { News, NewsCreateUpdateModel } from "../../../models/news";
import { useGetNewsItemQuery, useUpdateNewsItemMutation } from "../../../services/news";
import styles from '../Admin.module.css';

export const NewsDetail = (props: {id: number, onCancel: ()=>void}) => {
    // const { id } = useParams();
    const {id, onCancel} = props;
    const {data: newsItem, isLoading} = useGetNewsItemQuery(Number(id));
    const [updateNewItem, {isLoading: isUpdating}] = useUpdateNewsItemMutation();
    

    const updateNewsItem = async(values: News)=>{
        const data:NewsCreateUpdateModel = {content: values.name}
        if (Number(id))
            await updateNewItem({id: Number(id), data})
            .then(()=>{
                close();
            }).catch( (e) => {
                notificationService.error(`Ошибка ${e}`);
            });
    }
    const close = () => {onCancel();}

    if (isLoading) return <Spin />;
    if (!newsItem) return <div>новость не найдена</div>;
    return (
    <div className={styles.editContainer}>
        <Form onFinish={updateNewsItem} initialValues={newsItem} className={styles.editForm} >
            <Form.Item name="name" rules={[{required: true }]}>
                <Input.TextArea />
            </Form.Item>
            <Button type="link" htmlType="submit" loading={isUpdating}>Сохранить</Button>
            <Button type="link" onClick={close}>Отменить</Button>
        </Form>
    </div>
    )
}