import { Statistic } from '../models/statistic';
import { baseProtectedApi } from './baseProtectedApi';
import { ApiResponse } from './models/response';

const url = 'statistic';

const statisticApi = baseProtectedApi.injectEndpoints({
  endpoints: build => ({
    getStatistic: build.query<Statistic, void>({
        query: () => ({url: `${url}` }),
        transformResponse: (response: ApiResponse<Statistic>) => {
            return response.result;
        }
      }),
  }),
  overrideExisting: true
});

export const { 
    useGetStatisticQuery
 } = statisticApi;

