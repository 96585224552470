import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Burial } from '../../models/burial';


interface burialState  {
    selectedItem: Burial | undefined
};
const initialState: burialState = {
    selectedItem: undefined
};


  export const burialSlice = createSlice({
    name: 'burial',
    initialState,
    reducers:{
        updateSelected: (state, action: PayloadAction<Burial|undefined>) => {
          state.selectedItem = action.payload;
        },
    }
  })

  export const {updateSelected} = burialSlice.actions;
  export const selectBurialData = (state: RootState) => state.burial.selectedItem;

  export default burialSlice.reducer;