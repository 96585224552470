import { Button, Checkbox, Form, Input } from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MaskedInput from '../../app/base/formInput/maskedInput/MaskedInput';
import { useRegisterMutation } from '../../services/auth';
import { setCredentials } from './authSlice';
import notification from '../../app/toast/notificationService';
import styles from './Login.module.css';
import AppCard from '../../app/base/card';
import {  useEffect, useState } from 'react';
import { AgreementModal } from './Agreement';
import { FileUpload } from '../../app/base/upload/FileUpload';
import { UserViewModel } from '../../models/user';
import { AskSupport } from '../support/ContactUs';

export const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
    
  const [agreementOpen, setAgreementOpen] = useState(false);
  const [resiterResultOpen, setResiterResultOpen] = useState(false);
  const [registerEmail, setRegisterEmail] = useState<string>();
  const [form] = Form.useForm();
  const [registerAccount, { data, error, isLoading }] = useRegisterMutation();
  
  useEffect(()=>{
    setResiterResultOpen(false);
  }, [])

  const register = async (values: UserViewModel) => {
    const formData = new FormData();
    Object.entries(values).map(obj => {
      if (obj[1])
        formData.append(obj[0],obj[1])
    });
    try {
      if (values.photo && values.photo[0].originFileObj){
        formData.append('photo', values.photo[0].originFileObj);
      }
      await registerAccount(formData).unwrap();
      setRegisterEmail(values.email);
      setResiterResultOpen(true);
    }
    catch (err) {
      const error = err as  {data: {errors: {msg: string}[]}};
      notification.error(`Произошла ошибка, попробуйте зарегистрироваться еще раз. ${error.data.errors[0].msg}`);
    }
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      span: 24,
    },
  };

  const getFile = (e: any) => {
      if (Array.isArray(e)) {
      return e;
    }
   return e && e.fileList;
  };


  return (
    <div className={styles.loginWrapper}>
      <AppCard title='Регистрация'>
        {!resiterResultOpen ? <>
          <Form className={styles.registerForm} form={form} name="register" onFinish={register} scrollToFirstError 
            layout='vertical'>
          <Form.Item name="email" label="Адрес эл. почты"
            rules={[
              {type: 'email', message: 'Неверный формат E-mail!'},
              {required: true, message: 'Введите E-mail!'},
            ]}
          >
            <Input placeholder='email' />
          </Form.Item>
          <Form.Item name="password" label="Password"
            rules={[ { required: true, message: 'Введите пароль!' }, 
            // {pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/, message: 'Minimum eight characters, at least one letter and one number'}
          ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>
          <Form.Item name="passwordConfirm" label="Confirm Password" dependencies={['password']} hasFeedback
            rules={[
              { required: true, message: 'Введите подтверждение пароля!' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Пароли не совпадают!'));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item name="lastName" label="Фамилия" rules={[{ required: true, message: 'Введите фамилию!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="firstName" label="Имя" rules={[{ required: true, message: 'Введите имя!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="middleName" label="Отчество">
            <Input />
          </Form.Item>
          <Form.Item name="phoneNumber" label="Phone Number">
            <MaskedInput mask={'+7(000)000 00 00'} />
          </Form.Item>
          <Form.Item name="photo" label="Фото" valuePropName="fileList" getValueFromEvent={getFile}>
            <FileUpload />
          </Form.Item>


          <hr role="separator" aria-orientation="horizontal" />
          <Form.Item name="agreement" valuePropName="checked"
            rules={[ {validator: (_, value) => value ? Promise.resolve() : Promise.reject(new Error('Примите соглашение')) }]}
            
          >
            <Checkbox>
              Я прочитал <a onClick={()=>setAgreementOpen(true)}>соглашение</a>
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Button className={'commonButton'} type="primary" htmlType="submit" loading={isLoading}>
              Зарегистрироваться
            </Button>
          </Form.Item>
          </Form>
          <AgreementModal open={agreementOpen} onClose={()=>setAgreementOpen(false)}/>
        </>
        :<div>
          <div>
            На указанную при регистрации почту {registerEmail} выслано письмо. Для завершения регистрации проверьте электронную почту и перейдите по ссылке, указанной в письме.
          </div>
          <div>
            Если вы не видите письмо во входящих сообщениях, проверьте папку спам.
          </div>
          <div>Не пришло письмо? <AskSupport /></div>
        </div>}
      </AppCard>
    </div>
  );
};
