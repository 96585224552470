import { Form, Rate, Select } from "antd";
import AppList from "../../../app/base/list";
import { ListRequest, PagingInfo, buildListRequest } from "../../../models/base/list";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from './PersonList.module.css';
import { BurialFilter } from "../../burial/list/BurialFilter";
import { FormValuesToBurialFilterType } from "../../burial/list/utils";
import { FindPersonCard } from "./FindPersonCard";
import { useGetGeneralPersonListQuery } from "../../../services/person";
import { useAuth } from "../../../app/hooks";
import { useAddFavoritePersonMutation } from "../../../services/favoritePerson";

const pageSize = 16;
const sortOptions = [
  {value: 'lastName', label: 'По Фамилии'},
  {value: 'firstName', label: 'По Имени'},
  {value: 'middleName', label: 'По Отчеству'},
  {value: 'birth', label: 'По Дате рождения'},
  {value: 'death', label: 'По Дате смерти'},
  {value: 'cemeteryCity', label: 'По городу'},
  {value: 'cemeteryName', label: 'По кладбищу'}
];
const defaultSort = sortOptions[0]; 
const defSortKey = defaultSort.value;
const initialFilter: ListRequest = {  
  paging: {start: 0, limit: pageSize},
  filter: [],
  sort: [{property:defSortKey, value : 'asc'}]
}

export const PersonList = ()=>{
    const user = useAuth();
    const navigate = useNavigate();
    const viewBurial = (id: number)=> {
      navigate(`/burial/${id}`, {state:{from:'list'}});
    }
    const [skip, setSkip] = useState(true);
    const [sortValue, setSortValue] = useState<string>(defaultSort.value);
    const [searchParams, setSearchParams] = useState<ListRequest>(initialFilter);
    const {data: burials, isLoading, refetch } = useGetGeneralPersonListQuery(searchParams)
    const [addToFavorite] = useAddFavoritePersonMutation();

    const clearFilter = () => filterForm.resetFields();
    const [filterForm] = Form.useForm();
    const Filter = ()=>{
      return <Form form={filterForm} layout='vertical' className={styles.findFilter} onKeyUp={(e)=>{e.key==="Enter" && fetch({page:0, pageSize})}}><BurialFilter/></Form>
    }

   
    const Sort = ()=> (              
      <Select
        className={styles.sortSelect}
        onChange={sort}
        value={sortValue}
        options={sortOptions}
      >
      </Select>
    )
    const sort = (value: string) => {
      setSortValue(value);
      const params = {...searchParams, sort: [{property:value, value : 'asc'}]};

      setSearchParams(params);
    };  

    const fetch = ({page, pageSize}:PagingInfo)=>{
      const filter = FormValuesToBurialFilterType(filterForm.getFieldsValue());
      //if (skip) setSkip(false);
      setSearchParams(buildListRequest(page,pageSize,filter, {[sortValue]: 'asc'}));
    }

    const favorite = (id: number) =>{
      addToFavorite(id);
    }

    return (  
        <>   
            <AppList
                pageSize={pageSize} 
                isLoading={isLoading}
                filter={{filter: <Filter/>, onClearFilter: clearFilter, sort: <Sort/> }}
                data={burials?.items || [] }
                totalItems={burials?.total || 0}
                renderItem={(item, index) => (
                  <FindPersonCard key={index} item={item} viewItem={viewBurial} 
                    extraItemActions={user && !item.isFavorite ? [{action: favorite, title: 'Добавить в избранное'}] : undefined}
                    extraIcons={user && item.isFavorite ? [<Rate disabled count={1} value={1}/>] : undefined} 
                  />)}
                fetchData = {fetch}
                // cardView
                showTotal
            />
      </> 
    );
}