import { Button, Card, Col, Drawer, Form, Input, Row, Spin } from "antd"
import { getUserName } from "../../../models/userUtils";
import { UserAddOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import { IBurialGroupProps } from "./types";
import styles from './BurialGroup.module.css';
import { useAddMemberMutation, useGetBurialGroupQuery, useRemoveMemberMutation } from "../../../services/burialGroup";
import { useState } from "react";
import { buildListRequest, initialFilterParams, ListRequest } from "../../../models/base/list";
import { useGetUsersQuery } from "../../../services/user";

export const BurialGroup = (props: IBurialGroupProps) =>{
    const {groupId} = props;

    const {refetch, data: group, isLoading: isRefreshing} = useGetBurialGroupQuery(groupId);
    const [addMember, { isLoading: isAdding , isError:isAddMemberError, error: addError}] = useAddMemberMutation();
    const [removeMember, { isLoading: isRemoving }] = useRemoveMemberMutation();

    const owner = group && group?.members.find(i=>i.isOwner);
    const _members = group && group?.members.filter(i=>!i.isOwner) || [];

    const addGroupMember = async (userId: string)=>{
        await addMember({burialId: groupId, userId}).then(()=>{
            refetch();
            closeSearch();            
        });
    }
    const removeGroupMember = async (userId: string) => {
        await removeMember({burialId: groupId, userId});
        refetch();
    }
    const [openAddMember, setOpenAddMember] = useState(false);
    const [searchParams, setSearchParams] = useState<ListRequest>(initialFilterParams);
    const [skipSearchUser, setSkipSearchUser] = useState(true);
    const [filterUserForm] = Form.useForm();
    const {isLoading: isUserSearching, data: users, refetch: refetchUser} = useGetUsersQuery(searchParams, {skip: skipSearchUser})
    const searchUser = (values: any) => {
        setSearchParams(buildListRequest(1,10,values));
        setSkipSearchUser(false);
    }
    const showSearch = () => { setOpenAddMember(true); };
    const closeSearch = () => {setOpenAddMember(false); };

    return (<>
        {isRefreshing && <Spin />}
        {group && 
        <Card title={group.name} extra={<Button icon={<UserAddOutlined />} loading={isAdding} onClick={()=>setOpenAddMember(!openAddMember)}>Добавить {openAddMember ? <UpOutlined /> : <DownOutlined />}</Button>}>
            {openAddMember && <div className={`${styles.searchForm}`}> 
                <Row><Col>Поиск пользователей</Col></Row>
                <Form form={filterUserForm} layout="inline" onFinish={searchUser}>
                    <Form.Item requiredMark={"optional"} name='email' label='Email' rules={[{ required: true, message: 'Введите строку поиска' }]} >
                        <Input placeholder="email" />
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit">Найти</Button>
                    </Form.Item>
                </Form>
                <div>
                    {users && users.items.map(u =>
                        <Row key={u.email} gutter={16} className={styles.searchResultRow}>
                            <Col>{u.email}</Col>
                            <Col>{getUserName(u)}</Col>
                            <Col><Button type="link" onClick={()=>addGroupMember(u.id)}>Добавить в группу</Button></Col>
                        </Row>
                    )}
                </div>
            </div>    
            }
            <Row className={`${styles.groupTypeColored}`}>
                <Col span={24} className={`${styles.groupType}`}>Владелец</Col>
            </Row>
            <Row className={`${styles.groupTypeColored}`}>
                <Col span={8}>{getUserName(owner?.user)}</Col>
                <Col span={8}>{owner?.user.email}</Col>
                <Col span={8}></Col>
            </Row>
            
            <Row>
                <Col span={24} className={styles.groupType}>Участники ({_members.length})</Col>
            </Row>
            {isRefreshing && <Spin />}
            {_members.map(i=>
                <Row key={i.user.id}>
                    <Col span={8}>{getUserName(i.user)}</Col>
                    <Col span={8}>{i.user.email}</Col>
                    <Col span={8}><Button type="link" loading={isRemoving} onClick={()=> removeGroupMember(i.user.id)}>удалить</Button></Col>
                </Row>
            )}
        </Card>
        }
    </>)
}