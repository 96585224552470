import { User } from '../models/user';
import { baseProtectedApi } from './baseProtectedApi';
import { ApiResponse } from './models/response';
import { fullSitePath } from './models/util';

export interface UserResponse {
  user: User;
  token: string;
}

export interface LoginRequest {
  login: string;
  password: string;
}

export interface RestoreRequest { email: string}
export interface ResetPasswordRequest {
  email: string;
  token: string;
  password: string;
  confirmPassword: string;
}

export interface changePasswordRequest {
  currentPassword: string;
  newPassword: string;
}

const url = 'account';

export const authApi = baseProtectedApi.injectEndpoints({
  endpoints: builder => ({
    login: builder.mutation<UserResponse, LoginRequest>({
      query: credentials => ({
        url: `${url}/login`,
        method: 'POST',
        body: credentials
      }),
      transformResponse: (response: ApiResponse<UserResponse>) => {
        if (response.result.user.photo)
          response.result.user.photo = fullSitePath(response.result.user.photo);
        return response.result;
      },
      // invalidatesTags: ['User']
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: `${url}/logout`,
        method: 'POST',
      })
    }),
    register: builder.mutation<void, FormData>({
      query: (data) => ({
        url: `${url}/register`,
        method: 'POST',
        body: data
      }),
    }),
    updateProfile: builder.mutation<User, FormData>({
      query: (data) => ({
        url: `${url}/profile`,
        method: 'PUT',
        body: data
      }),
      transformResponse: (response: ApiResponse<User>) => {
        return response.result;
      }
      ,
      // invalidatesTags: ['User']
    }),
    confirmAccount: builder.query<void, {email: string, token: string}>({
      query: (data) => ({
        url: `${url}/confirmEmail`,
        method: 'POST',
        body: data
      }),
    }),
    requestEmailConfirm: builder.query<void, RestoreRequest>({
      query: (data) => ({
        url: `${url}/requestConfirm`,
        method: 'POST',
        body: data
      }),
    }),
    restorePassword: builder.query<void, RestoreRequest>({
      query: (data) => ({
        url: `${url}/restorePassword`,
        method: 'POST',
        body: data
      }),
    }),    
    resetPassword: builder.query<void, ResetPasswordRequest>({
      query: (data) => ({
        url: `${url}/resetPassword`,
        method: 'POST',
        body: data
      }),
    }),
    changePassword: builder.query<void, changePasswordRequest>({
      query: (data) => ({
        url: `${url}/changePassword`,
        method: 'POST',
        body: data
      }),
    }),

  })
});

export const { useLoginMutation, useLogoutMutation, useRegisterMutation, useUpdateProfileMutation, 
  useLazyConfirmAccountQuery,
  useLazyRequestEmailConfirmQuery,
  useLazyRestorePasswordQuery,
  useLazyResetPasswordQuery,
  useLazyChangePasswordQuery
 } = authApi;
