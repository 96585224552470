import { useLocation, useParams } from "react-router-dom";
import { useGetOwnPreBurialQuery } from "../../../services/ownedBurial";
import { BurialDetailContainer } from "../../burial/detail/BurialDetailContainer";

export const OwnedPreburialDetail = () =>{
    const { state } = useLocation();
    const { id } = useParams<{ id: any }>();
    const {data: burial, isLoading} = useGetOwnPreBurialQuery(id);

    return (
        <BurialDetailContainer burial={burial} isLoading={isLoading} stateFrom={state?state.from:undefined} isPreBurial={true} />
    )
}