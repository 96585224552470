import { Button, Form, Modal, Select } from "antd";
import { useEffect } from "react";
import { getUserName } from "../../../models/userUtils";
import { ICreateFamilyLinkProps } from "./types";

export const CreateFamilyLink = (props: ICreateFamilyLinkProps)=>{
    const {person, relativePerson, onSubmit, siblingSource} = props;
    const [form] = Form.useForm();

    const submit = ({sibling, relativeSibling}: {sibling: number, relativeSibling: number})=>{
        onSubmit(sibling, relativeSibling);
    }
    useEffect(()=>{
        form.resetFields();
    })

    return (
            <Form form={form} layout="vertical" onFinish={submit} requiredMark={false}>
                <Form.Item label={`${person} является`} name='sibling' rules={[{ required: true, message: 'Выберите степень родства!' }]}>
                    <Select options={siblingSource} placeholder="Выберите степень родства"/>
                </Form.Item>
                <Form.Item label={`${getUserName(relativePerson)} является`} name='relativeSibling' rules={[{ required: true, message: 'Выберите степень родства!' }]}>
                    <Select options={siblingSource} placeholder="Выберите степень родства"/>
                </Form.Item> 
                <Form.Item>           
                    <Button htmlType="submit" className="defaultActionButton">Добавить связь</Button>
                </Form.Item>
            </Form>
    )
}