import { ConfigProvider, Layout } from 'antd';
import { Outlet, Route, Routes } from 'react-router-dom';
import { AppHeader } from '../../features/header/AppHeader';
import locale from 'antd/locale/ru_RU';

const { Content, Footer } = Layout;

export const AppContentLayout = () => {
  return (
    <>
      <Layout className="root">
        <AppHeader />
        <Content className="site-layout" style={{ padding: '0 50px' }}>
          <ConfigProvider locale={locale}>
            {/* <div style={{ padding: 24, minHeight: 20 }}>Text of basic layout. all pages contains test text.</div> */}
              <Outlet/>
          </ConfigProvider>
        </Content>
        <Footer style={{ textAlign: 'center' }}>©2023 ООО "Неофит"</Footer>
      </Layout>

    </>
  );
};
