import { Col, DatePicker, Form, Input, InputNumber, Row, Tooltip } from 'antd';
import styles from './Find.module.css';
import { FormInstance } from 'rc-field-form';
import { InfoCircleOutlined} from '@ant-design/icons';
import { hashtagRegExp } from '../../../app/consts/tag';

    const colSpanLg = 6;
    const colSpanMd = 4;
    const colSpanSm = 6;
    const colSpanXs = 6;

export const BurialFilter = (props: {form?: FormInstance})=>{
    return (
        <>
          <Row>
              <Col xs={colSpanXs} sm={colSpanSm} md={colSpanMd} lg={colSpanLg} xl={colSpanLg}>
                  <Form.Item name='lastName' label='Фамилия'>
                      <Input/>
                  </Form.Item>
              </Col>
              <Col xs={colSpanXs} sm={colSpanSm} md={colSpanMd} lg={colSpanLg} xl={colSpanLg}>
                  <Form.Item name='firstName' label='Имя'>
                      <Input/>
                  </Form.Item>
              </Col>
              <Col xs={colSpanXs} sm={colSpanSm} md={colSpanMd} lg={colSpanLg} xl={colSpanLg}>
                  <Form.Item name='middleName' label='Отчество'>
                      <Input/>
                  </Form.Item>
              </Col>
              <Col xs={colSpanXs} sm={colSpanSm} md={colSpanMd} lg={colSpanLg} xl={colSpanLg}>
                <Form.Item name='lastNameReplaced' label='Фамилия, если менялась' >
                  <Input />
                </Form.Item>
              </Col>
          </Row>
          <Row>
            <Col xs={colSpanXs*2} sm={colSpanSm*2} md={colSpanMd*2} lg={colSpanLg*2} xl={colSpanLg*2}>
              <Form.Item name='deathPeriod' label='Дата смерти'>
                <DatePicker.RangePicker allowEmpty={[true,true]} showTime={false} format="DD.MM.YYYY" className={styles.findDatePeriod} />
              </Form.Item>
            </Col>
            <Col xs={colSpanXs*2} sm={colSpanSm*2} md={colSpanMd*2} lg={colSpanLg*2} xl={colSpanLg*2}>
              <Form.Item name='birthPeriod' label='Дата рождения'>
                <DatePicker.RangePicker allowEmpty={[true,true]} showTime={false} format="DD.MM.YYYY" className={styles.findDatePeriod} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
              <Col xs={colSpanXs} sm={colSpanSm} md={colSpanMd} lg={colSpanLg} xl={colSpanLg}>
                  <Form.Item name='cityName' label='Населенный пункт'>
                      <Input/>
                  </Form.Item>
              </Col>
              <Col xs={colSpanXs} sm={colSpanSm} md={colSpanMd} lg={colSpanLg} xl={colSpanLg}>
                  <Form.Item name='cemeteryName' label='Кладбище'>
                      <Input/>
                  </Form.Item>
              </Col>
              <Col xs={colSpanXs} sm={colSpanSm} md={colSpanMd} lg={colSpanLg} xl={colSpanLg}>
                  <Form.Item name='burialNumber' label='Номер участка'>
                      <Input/>
                  </Form.Item>
              </Col>
              <Col xs={colSpanXs} sm={colSpanSm} md={colSpanMd} lg={colSpanLg} xl={colSpanLg}>
                <Form.Item name='tags' label='Тэг' rules={[ {pattern: hashtagRegExp, message: 'строка тегов формата #tag через пробел'} ]} >
                  <Input
                      suffix={
                        <Tooltip title="один тег или теги через пробел">
                          <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
                </Form.Item>
              </Col>
          </Row>
          <Row>
            <Col xs={colSpanXs} sm={colSpanSm} md={colSpanMd} lg={colSpanLg} xl={colSpanLg}>
              <Form.Item name='ageFrom' label='Возраст от'>
                <InputNumber min={0} max={120} style={{width: '100%'}} />
              </Form.Item>
            </Col>
            <Col xs={colSpanXs} sm={colSpanSm} md={colSpanMd} lg={colSpanLg} xl={colSpanLg}>
              <Form.Item name='ageTo' label='Возраст до'>
                <InputNumber min={0} max={120} style={{width: '100%'}} />
              </Form.Item>
            </Col>
          </Row>
        </>
    );
  }