import { redirect, useNavigate } from "react-router-dom";
import { useCreateOwnBurialMutation } from "../../../services/ownedBurial";
import { BurialForm } from "./BurialForm";
import notification from '../../../app/toast/notificationService';
import { BurialCreateModel } from "../../../models/burial";
import notificationService from "../../../app/toast/notificationService";

export const BurialCreate = () => {
    const navigate = useNavigate();
    const [createBurial, {isLoading}] = useCreateOwnBurialMutation();
    const createNew = async(data: FormData) =>{
        try {
            const res = await createBurial(data).unwrap();
            notificationService.info('Изменения будут доступны после проверки модератором');
            navigate('/ownedburial');
        }
        catch (err){
            // notification.error("Ой-ёй");
        }
    }
    return (
        <BurialForm formTitle="Новое захоронение" onSubmit={createNew} isLoading={isLoading} />
    );
}