import { Tooltip } from "antd";
import { InfoCircleOutlined } from '@ant-design/icons';
import styles from './AppTooltip.module.css';
import { TooltipPlacement } from "antd/es/tooltip";

export const AppTooltip = ({title, placement}: {title: string, placement?:TooltipPlacement}) => {
    return (
        <Tooltip overlayClassName={styles.groupTooltip} trigger="click" placement={placement}
        color={'#585656'}
        title={title}>
        <InfoCircleOutlined style={{ color: 'rgba(0,0,0)' }} />
    </Tooltip>
    );
}