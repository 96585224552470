import { useState } from "react"
import AppCard from "../../../app/base/card"
import { CreateNewsItem } from "./CreateNews"
import { NewsDetail } from "./NewsDetail"
import { NewsList } from "./NewsList"

export const AdminNewsManager = () => {

    return (
        <AppCard title="Управление новостями">
            <CreateNewsItem />
            <hr />
            <NewsList/>
        </AppCard>
    )
}