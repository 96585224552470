import { useEffect, useState } from "react";
import { redirect, useNavigate, useSearchParams } from "react-router-dom";
import { useLazyResetPasswordQuery } from "../../services/auth";
import AppCard from "../../app/base/card";
import { Button, Form, Input } from "antd";
import styles from './RestorePassword.module.css';
import notificationService from "../../app/toast/notificationService";
import { apiErrorData } from "../../models/base/apiErrorData";
import { waitforme } from "../../app/base/util";

export const ResetPassword = ()=>{
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const email = searchParams.get('email');
    const token = searchParams.get('token');
    const [linkBroken, setLinkBroken] = useState(false);
    const [tokenFailed, setTokenFailed] = useState(false);
    const [result, setResult] = useState<string|undefined>(undefined);

    const [reset, {isLoading}] = useLazyResetPasswordQuery();

    useEffect(()=>{
        if (!email || !token) setLinkBroken(true);
    },[])

    const resetPassword = ({password, passwordConfirm:confirmPassword}:{password: string,passwordConfirm: string} )=>{
        if (!email || !token) return;
        reset({email,token,password,confirmPassword}).then((e)=>{
            if (e.hasOwnProperty('error') && e.isError){
                const err = e.error as apiErrorData;
                setResult("Не удалось сбросить пароль. " + err.data.errors[0].msg);
                //return setTokenFailed(true);
            }
            setResult("ok");
            notificationService.info('Новый пароль установлен');
            waitforme(1000).then(()=> navigate('/login'));
        })
        .finally(()=>
            {  }
        );
    }

    return (
        <AppCard title="Сброс пароля">
            {linkBroken ? <div>Неверная ссылка для сброса пароля</div>:
            result ?
                result.length>2 ? 
                <div>{result}</div>
                :<div>Новый пароль успешно установлен.</div>
            :
            <>
                <div>Введите новый пароль. После подтверждения сброса он будет установлен для Вашей учетной записи.</div>
                <Form className={styles.restoreForm} onFinish={resetPassword} layout='vertical'>
                    <Form.Item name="password" label="Новый пароль"
                        rules={[ { required: true, message: 'Введите пароль!' }, ]}
                        hasFeedback
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item name="passwordConfirm" label="Подтверждение нового пароля" dependencies={['password']} hasFeedback
                        rules={[
                        { required: true, message: 'Введите подтверждение пароля!' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('Пароли не совпадают!'));
                            },
                        }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item>
                        <Button className={'commonButton'} type="primary" htmlType="submit" loading={isLoading}>
                            Сбросить
                        </Button>
                    </Form.Item>
                </Form>
            </>
            }
        </AppCard>
    )
}