import { Button, Col, Form, Input, Modal, Row } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AppList from "../../../app/base/list/ListNew";
import { ListFilter } from "../../../app/base/list/types";
import { FormValuesToFilterType } from "../../../app/base/list/util";
import { buildListRequest, initialFilterParams, ListRequest, PagingInfo } from "../../../models/base/list";
import { PersonSearchResult } from "../../../models/personSearchResult";
import { getUserName } from "../../../models/userUtils";
import { useGetPersonListQuery } from "../../../services/person";
import styles from './FindPerson.module.css';
import { IFindPersonProps } from "./types";
import { defaultListPageSize } from "../../../app/consts/list";

const pageSize = defaultListPageSize;

export const FindPerson = (props: IFindPersonProps) => {
    const {onPersonSelected, connectedPersonId} = props;
    const { id } = useParams();

    const addExculdeId = (filterParams: ListRequest): ListRequest=>{
        var f = {... filterParams};
        f.filter = [{property:"excludePersonId",value: id||''}];
        return f;
    }

    const [filter] = Form.useForm();
    const [searchParams, setSearchParams] = useState<ListRequest>(addExculdeId(initialFilterParams));
    const {data, isLoading} = useGetPersonListQuery(searchParams);
    // const {data:siblings} = useGetSiblingsQuery();
    // const [siblingSource, setSiblingSource] = useState<{ label: string, value: number }[]>();

    
    // useEffect(()=>{
    //     setSiblingSource(siblings?.map(i=>{return {label: i.name, value: i.id}}));
    // }, [siblings])
    
    const clearFilter = () => filter.resetFields();

    const find = ({page, pageSize}:PagingInfo)=>{
        const f = FormValuesToFilterType(filter.getFieldsValue());
        f["excludePersonId"] = connectedPersonId+'';
        setSearchParams(buildListRequest(page,pageSize,f));
    }
    // const add = (id: number, sibling: number)=>{
    //     props.onPersonSelected(id, sibling);
    // }

    const Filter = ()=>{
        return (<>
            <Form form={filter} layout='vertical' className={styles.findFilter} onKeyUp={(e)=>{e.key==="Enter" && find({page:0, pageSize})}}>
            <Row>
                    <Col span={6}>
                        <Form.Item name='lastName' label='Фамилия'>
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name='firstName' label='Имя'>
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name='middleName' label='Отчество'>
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name='lastNameReplaced' label='Фамилия, если менялась' >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item name='cityName' label='Населенный пункт'>
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name='cemeteryName' label='Кладбище'>
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name='burialNumber' label='Номер участка'>
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                {/* <Row>
                    <Col span={24} className={styles.filterAction}>
                        <Button onClick={() => filter.resetFields()}>Очистить2</Button>
                        <Button className="defaultActionButton" htmlType="submit">Найти2</Button>
                    </Col>
                </Row> */}
            </Form>
        </>)
    }
    const searchResult = (item: PersonSearchResult) => {
        return (
            <Row className={styles.resultRow}>
                <Col className={styles.resultCol} span={5}>{getUserName(item)}</Col>
                <Col className={styles.resultCol} span={5}>{item.cemeteryName}</Col>
                <Col className={styles.resultCol} span={5}>{item.cemeteryCity}</Col>
                <Col className={styles.resultCol} span={5}>{item.locationNumber}</Col>
                <Col span={4}></Col>
            </Row>
        )
    }
    const actions = (item: PersonSearchResult) =>{
        return [
            // <Form layout="inline" className={styles.findFilter} onFinish={(v) => add(item.id, v.sibling)}>
            //     <Form.Item name='sibling' rules={[{ required: true, message: 'Выберите степень родства!' }]}>
            //         <Select options={siblingSource} placeholder="Выберите степень родства"/>
            //     </Form.Item>
            
            // <Button type='link' htmlType="submit">добавить</Button>
            // </Form>
            <Button type='link' onClick={()=>onPersonSelected(item)}>добавить</Button>
        ]
    }
    const listFilter: ListFilter =  {
        filter: <Filter/>,
        onClearFilter: clearFilter
    }


    return (
        <div className={styles.findContainer}>

            <AppList
                pageSize={pageSize} 
                data={data?.items || []} 
                totalItems={data?.total || 0} 
                fetchData={find} 
                isLoading={isLoading} 
                filter={listFilter} 
                renderItem={(item)=>searchResult(item)} 
                renderActions={actions}
            />
        </div>
    );
}