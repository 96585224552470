import { RcFile, UploadFile } from "antd/es/upload";
import { IFio, User } from "./user";
import dayjs from 'dayjs';
import { fileNameFromPath, formatDateTime, fullSitePath } from "../services/models/util";
import { noInfoString } from "../app/consts/tooltip";

export interface Person extends IFio {
    id: number;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    genderId?: number;
    birth?: string;
    death?: string;
    birthPlace?: string;
    image?: string;
    attachedFile?: string;
    createdByUser?: User;
    canEdit?:boolean;
    note?: string;
    tags?: string[];
    lastNameReplaced?: string;
    age?: number;
}

export interface PersonViewModel extends IFio {
    id: number;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    gender?: number;
    note?: string;
    birth?: string;
    death?: string;
    birthPlace?: string;
    image?: string;
    attachedFile?: string;
    createdByUser?: User;
    tags?: string[];
    lastNameReplaced?: string;
    age?: number;
}

export interface PersonCreateModel extends IFio {
    id: number;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    gender?: number;
    // birth?: string;
    // death?: string;
    birthPlace?: string;
    // image?: RcFile;
    // attachedFile?: RcFile;
    image?: UploadFile[];
    attachedFile?: UploadFile[];
    createdByUser?: User;
    birth?: dayjs.Dayjs | undefined;
    death?: dayjs.Dayjs | undefined;
    note?: string;
    tags?: string[];
    tagsAsString?: string;
    lastNameReplaced?: string;
    age?: number;
}

export const personToViewModel = (p: Person): PersonViewModel =>{
    const person: PersonViewModel = {
        id: p.id,
        firstName: p.firstName,
        middleName: p.middleName,
        lastName: p.lastName,
        birth:  p.birth ? formatDateTime(p.birth) : noInfoString,
        death: p.death ? formatDateTime(p.death) : noInfoString,
        birthPlace: p.birthPlace || noInfoString,
        image: p.image?fullSitePath(p.image):undefined,
        attachedFile:p.attachedFile?fullSitePath(p.attachedFile):undefined,
        createdByUser: p.createdByUser,
        gender: p.genderId,
        note: p.note,
        lastNameReplaced: p.lastNameReplaced,
        tags: p.tags,
        age: p.age
    };
    return person;
}

export const personToCreateModel = (p: Person): PersonCreateModel =>{
    const person: PersonCreateModel = {
        id: p.id,
        birth: p.birth ? dayjs(p.birth): undefined,
        death: p.death ? dayjs(p.death): undefined,
        firstName: p.firstName,
        middleName: p.middleName,
        lastName: p.lastName,
        birthPlace: p.birthPlace,
        image:  p.image? [{uid:'-1', url: fullSitePath(p.image), name: p.image, status: 'done'}]: undefined,
        attachedFile: p.attachedFile ? [{uid:'-1', url: fullSitePath(p.attachedFile), name: fileNameFromPath(p.attachedFile), status: 'done'}]: undefined,
        createdByUser: p.createdByUser,
        gender: p.genderId,
        note: p.note,
        lastNameReplaced: p.lastNameReplaced,
        tagsAsString: p.tags?.join(' '),
        tags: p.tags,
        age: p.age
    };
    return person;
}