import { Button, Col, Form, Row, Select, Space } from "antd";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppCard from "../../app/base/card";
import AppList from "../../app/base/list";
import { buildListRequest, initialFilterParams, ListRequest, PagingInfo } from "../../models/base/list";
import { useAddBurialServiceMutation, useGetBurialServicesQuery, useRemoveBurialServiceMutation } from "../../services/burialService";
import styles from './BurialServiceList.module.css';
import stylesCard from '../../app/base/card/Card.module.css';
import { ServiceListItem } from "./ServiceListItem";
import { useGetBurialQuery } from "../../services/burial";
import { Service } from "../../models/service";
import { useGetServiceStatusTypeListQuery, usePayServiceMutation } from "../../services/burialServiceStatus";
import { BurialServiceFilter } from "./BurialServiceFilter";
import { useGetServiceTypesAllQuery } from "../../services/serviceType";
import AppTooltip from "../../app/base/tooltip";
import { FormValuesToServiceFilterType } from "./util";
import { ToolTip_Service_Description } from "../../app/consts/tooltip";

export const BurialServiceList = ()=>{
    const { id } = useParams();
    const navigate = useNavigate();
    // const selectedBurial = useAppSelector(selectBurialData);
    
    const [searchParams, setSearchParams] = useState<ListRequest>(initialFilterParams);
    const {data: services, isLoading} = useGetBurialServicesQuery({burialId:Number(id),params:searchParams});
    const [addService, {isLoading: isAdding}] = useAddBurialServiceMutation();
    const {data: serviceTypes, isLoading: isServiceTypesLoading} = useGetServiceTypesAllQuery();
    const {data: selectedBurial, isLoading: isBurialLoading} = useGetBurialQuery(Number(id));
    const {data: statusTypes} = useGetServiceStatusTypeListQuery();

    const title = isBurialLoading? '' : `${selectedBurial?.name || ''} ${selectedBurial?.cityName || ''} ${selectedBurial?.cemeteryName || ''}`;
    const [filterForm] = Form.useForm();

    const fetch = ({page, pageSize}:PagingInfo)=>{
        const filter = FormValuesToServiceFilterType(filterForm.getFieldsValue());
        setSearchParams(buildListRequest(page,pageSize,filter));
    }
    const add = async({serviceTypeId}:{serviceTypeId: number}) => {
        await addService({burialId: Number(id),serviceTypeId});
    }
    const [deleteService, {isLoading: isDeleting}] = useRemoveBurialServiceMutation();
    const [makePayment] = usePayServiceMutation();

    const actions = (item: Service) =>{
        return [
            // <Button type='link' onClick={()=>makePayment(item.id)}>оплатить</Button>,
            <Button disabled={!item.canDelete} type='link' onClick={()=>deleteService(item.id)}>удалить</Button>
        ]
    }
    const Filter = ()=>{
        return <BurialServiceFilter filterForm={filterForm} statusTypeSource={statusTypes} typeSource={serviceTypes} />
    }
    const clearFilter = () => filterForm.resetFields();

    const pagetitle =
    (<Space>
       <span className={stylesCard.pageHeader}>{`Услуги захоронения ${title}`}</span>
       <AppTooltip title={ToolTip_Service_Description}/>
   </Space>)

    return (
        <AppCard title={pagetitle}>
            <div className={styles.container}>
                <Button onClick={()=>navigate(-1)}>Назад</Button>
            </div>
            <div className={styles.addContainer}>
                <div className={styles.addHeader}>Добавить услугу</div>
                <Form layout="horizontal" onFinish={add} className={styles.addForm} requiredMark={false}>
                    <Form.Item name='serviceTypeId' label='Услуга' rules={[{ required: true, message:'Выберите услугу' }]}>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label ?? '').includes(input)}
                            placeholder = 'Выберите тип услуги'
                            options={serviceTypes?.map(i=>{return {value: i.id, label: `${i.name} (${i.price} руб.)`}})} 
                            loading={isServiceTypesLoading}></Select>                        
                    </Form.Item>
                    <Form.Item>
                    <Button loading={isAdding} htmlType='submit'>
                        Добавить
                    </Button>
                    </Form.Item>
                </Form>
            </div>
            <div className={styles.addHeader}>Список услуг</div>
            <AppList 
                data={services?.items || []} 
                totalItems={services?.total || 0}
                isLoading={isLoading}
                fetchData={fetch}
                filter={{filter: <Filter/>, onClearFilter: clearFilter}}
                renderItem={ServiceListItem}
                renderActions={actions}
            />
        </AppCard>
    );
}