import { IPersonLifeFrameStyle } from "../../../models/userUtils";
import { StyledField } from "./PersonFio";

export const PersonLifeTimeFrame = (props: {birth: string | undefined, death: string|undefined, styled?: IPersonLifeFrameStyle })=>{
    const {birth,death,styled}=props;
    return (
        <>
        {styled ?
            <><StyledField val={birth} className={styled.birth} /> - <StyledField val={death} className={styled.death}/></>
        : `${birth} - ${death}`
        }
        </>
    )
}