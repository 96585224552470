import { baseProtectedApi } from './baseProtectedApi';
import { buildListRequest, ListData, ListRequest, ListResponse } from '../models/base/list';
import { formatDateTime } from './models/util';
import { CreateFamilyLinkModel, FamilyLink, Sibling } from '../models/familyLink';
import { FamilyLinkClaim } from '../models/familyLinkClaim';
import { noInfoString } from '../app/consts/tooltip';

const url = 'familylink';

export interface FamilyLinkRequest {
    personId: number
}
export interface AddFamilyLinkRequestRequest{
  personId: number;
  relativePersonId: number;
  personSiblingId: number;
  relativePersonSiblingId: number;
}

const familyLinkApi = baseProtectedApi.injectEndpoints({
  endpoints: build => ({
    getFamilyList: build.query<ListData<FamilyLink>, FamilyLinkRequest>({
        query: (params) => ({
            method: 'POST', 
            url: `${url}/list/`, 
            body: buildListRequest(0,0,{anyPersonId: params.personId+''}) 
        }),
        transformResponse: (response:ListResponse<FamilyLink>, meta, arg) =>{
            return   {
                items: response.result.items,
                total: response.result.total
            }
        },
        providesTags: (result) => result?.items
        ? [
            ...result.items.map(({ id }) => ({ type: 'FamilyLinks' as const, id })),
            { type: 'FamilyLinks', id: 'LIST' },
          ]
        : [{ type: 'FamilyLinks', id: 'LIST' }],
      }),
    getSiblings: build.query<Sibling[], void>({
        query: (id) => `${url}/sibling/list`,
        transformResponse: (response: ListResponse<Sibling>) => {
            return response.result.items;
        }
    }), 

    deleteFamilyLink: build.mutation<{ success: boolean; id: number }, number>({
        query(id) {
          return {
            url: `${url}/${id}`,
            method: 'DELETE',
          }
        },
        invalidatesTags: ['FamilyLinks']
    }),
    createFamilyLink: build.mutation<FamilyLink, CreateFamilyLinkModel>({
        query(data) {
          return {
            url: `${url}`,
            method: 'POST',
            body: data
          }
        },
        invalidatesTags: ['FamilyLinks']
    }),
    getFamilyLinkRequestList: build.query<ListData<FamilyLinkClaim>, ListRequest>({
      query: (params) => ({
          method: 'POST', 
          url: `${url}/request/list/`, 
          body: params 
      }),
      transformResponse: (response:ListResponse<FamilyLinkClaim>, meta, arg) =>{
        response.result.items.forEach(i=>{
          i.createdTimestamp = i.createdTimestamp?formatDateTime(i.createdTimestamp): noInfoString;
          if (i.updatedTimestamp) i.updatedTimestamp = formatDateTime(i.updatedTimestamp);
        });  
        return   {
              items: response.result.items,
              total: response.result.total
        }
      },
      providesTags: (result) => result?.items
      ? [
          ...result.items.map(({ id }) => ({ type: 'FamilyLinkClaims' as const, id })),
          { type: 'FamilyLinkClaims', id: 'LIST' },
        ]
      : [{ type: 'FamilyLinkClaims', id: 'LIST' }],
    }),
    // createFamilyLinkRequest: build.query<{ success: boolean }, AddFamilyLinkRequestRequest>({
    //   query(params) {
    //     return {
    //       url: `${url}/request/add`,
    //       method: 'POST',
    //       body: params
    //     }
    //   },
    // }),
    confirmFamilyLinkRequest: build.mutation<{ success: boolean }, number>({
      query(params) {
        return {
          url: `${url}/request/confirm/${params}`,
          method: 'POST',
        }
      },
      invalidatesTags: (result, error, arg) => [{type:'FamilyLinkClaims', id: arg}]
    }),
    rejectFamilyLinkRequest: build.mutation<{ success: boolean }, number>({
      query(params) {
        return {
          url: `${url}/request/reject/${params}`,
          method: 'POST',
        }
      },
      invalidatesTags: (result, error, arg) => ['FamilyLinkClaims']
    }),


  }),

  overrideExisting: true
});

export const { 
    useGetSiblingsQuery,
    useGetFamilyListQuery,
    useCreateFamilyLinkMutation,
    useDeleteFamilyLinkMutation,

    useGetFamilyLinkRequestListQuery,
    useConfirmFamilyLinkRequestMutation,
    useRejectFamilyLinkRequestMutation
 } = familyLinkApi;
