import { Button, List, Space, Pagination, Skeleton, Row, Col, Spin, Select } from 'antd';

import { useEffect, useState } from 'react';
import { defaultListPageSize } from '../../consts/list';
import styles from './List.module.css';
import { IAppListPropsNew } from './types';

function AppList<T>(props: IAppListPropsNew<T>) {
  const {pageSize=defaultListPageSize, 
    fetchData, renderItem, renderMeta, renderActions, filter, data, totalItems, 
    isLoading, showFilter=true, primaryAction,
    showTotal=false,
    cardView=false} = props;

  const [currentPage, setCurrentPage] = useState<number>(1);

  const search = () => {
    changePage(1);
  };

  const changePage = (page: number) => {
    setCurrentPage(page);
    fetchData({page, pageSize});
  };

  const renderListItem = (item: T, index: number)=>(
    <List.Item key={index}  className={`${styles.listItem} ${primaryAction?styles.listItemColored:''}`}
        actions={renderActions? renderActions(item) : undefined}
        onClick={()=> {primaryAction && primaryAction(item)}}
    >
      <Skeleton loading={isLoading}>
        {renderMeta && renderMeta(item, index)}
        {renderItem && renderItem(item, index)}
      </Skeleton>
    </List.Item>
  )


  return (
    <div style={{width: '100%'}}>
      <Space direction="vertical" style={{width: '100%'}} size={24}>
        {showFilter && filter ?
        <>
          <Row>
            <Col>
              {filter.filter}
            </Col>
          </Row>
          
          <Row>
            <Col className={styles.filterButtons}>
              {filter.onClearFilter && <Button className={styles.findButton} onClick={filter.onClearFilter}>Очистить фильтр</Button>}
              <Button className={`{styles.findButton} defaultActionButton`} onClick={search}>Найти</Button>
            </Col>
          </Row>
        </>
        :<></>
        }
        {filter && filter.sort && 
        <Row>
          <Col>
            <Row style={{alignItems: 'flex-end'}}>
              <div className={styles.SortField}>Сортировка</div>{filter.sort}
            </Row>
          </Col>
        </Row>
        }
        {showTotal && 
        <Row>
          <Col span={24}>
            <div className={styles.foundLabel}>Найдено {totalItems}</div>
          </Col>
        </Row>}

        <Row>
          <Col span={24}>
          <List 
            loading={isLoading}
            className={cardView? styles.appList: undefined}
                grid={cardView?{
                  gutter: 16,
                  xs: 1,
                  sm: 1,
                  md: 1,
                  lg: 2,
                  xl: 2,
                  xxl: 2,
                }: undefined}
            dataSource={data || []} 
            renderItem={renderListItem} />
          <Pagination pageSize={pageSize} current={currentPage} total={totalItems} onChange={changePage} hideOnSinglePage />
          </Col>
        </Row>

      </Space>
    </div>
  );
}

export default AppList;
