import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../app/store';

export const baseProtectedApi = createApi({
  tagTypes: ['News', 'OwnBurials','FamilyLinks','BurialServices','Services','ServiceStatusTypeList','ServiceType','BurialGroupClaims','FamilyLinkClaims', 'FavoritePersons','Cities'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/',
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState).auth.token || sessionStorage.getItem('token');
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  refetchOnMountOrArgChange: 1,
  endpoints: () => ({})
});
