import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import { Service } from "../../models/service";
import styles from './BurialServiceList.module.css';

export const ServiceListItem = (item: Service) => {
    return (
        <Row className={styles.resultRow}>
            <Col className={styles.resultCol} span={3}>{item.serviceType}</Col>
            <Col className={styles.resultCol} span={2}>от {item.createdTimeStamp}</Col>
            <Col className={styles.resultCol} span={2}>{item.price} руб.</Col>
            <Col className={styles.resultCol} span={3}>{item.serviceStatus}</Col>
            <Col className={styles.resultCol} span={3}><Link to={`/burial/${item.burial.id}`} state={{from:'list'}}>{item.burial.name}</Link></Col>
            <Col className={styles.resultCol} span={3}>{item.burial.city}</Col>
            <Col className={styles.resultCol} span={3}>{item.burial.cemetery}</Col>
            <Col span={5}></Col>
        </Row>
    )
}