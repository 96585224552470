import { baseProtectedApi } from './baseProtectedApi';
import { ListData, ListRequest, ListResponse } from '../models/base/list';
import { ApiResponse } from './models/response';
import { City } from '../models/city';

const url = 'burialcity';

const cityApi = baseProtectedApi.injectEndpoints({
  endpoints: build => ({
    getCityList: build.query<ListData<City>, ListRequest>({
        query: (params) => ({method: 'POST', url: `${url}/list/`, body: params }),
        transformResponse: (response:ListResponse<City>, meta, arg) =>{
            return   {
                items: response.result.items,
                total: response.result.total
            }
        },
        providesTags: (result) => result?.items
        ? [
            ...result.items.map(({ id }) => ({ type: 'Cities' as const, id })),
            { type: 'Cities', id: 'LIST' },
          ]
        : [{ type: 'Cities', id: 'LIST' }],
      }),
    getCity: build.query<City, number>({
        query: (id) => `${url}/${id}`,
        transformResponse: (response: ApiResponse<City>) => {
            return response.result;
        }
    }), 
    updateCity: build.mutation<City, {id: number, data: string}>({
        query(data) {
          const { id, data:body } = data
          return {
            url: `${url}/${id}`,
            method: 'PUT',
            body: {name: body},
          }
        },
        invalidatesTags: (result, error, arg) => [{type:'Cities', id: arg.id}]
    }),
    deleteCity: build.mutation<{ success: boolean; id: number }, number>({
        query(id) {
          return {
            url: `${url}/${id}`,
            method: 'DELETE',
          }
        },
        invalidatesTags: ['Cities']
    }),
    createCity: build.mutation<City, string>({
        query(data) {
          return {
            url: `${url}`,
            method: 'POST',
            body: {name:data},
            headers: {'content-type': 'application/json'}
          }
        },
        invalidatesTags: ['Cities']
    }),
  }),
  overrideExisting: true
});

export const { 
    useGetCityListQuery,
    useGetCityQuery,
    useCreateCityMutation,
    useUpdateCityMutation,
    useDeleteCityMutation
 } = cityApi;
