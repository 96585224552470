import { Button, Form, Input, InputNumber, Spin } from "antd";
import { ServiceType, ServiceTypeCreateUpdateModel } from "../../../models/service";
import { useUpdateServiceTypeItemMutation } from "../../../services/serviceType";
import styles from './ServiceTypeManager.module.css';

export const FormListItem = (props: {item: ServiceType, onCancel: ()=>void})=>{
    const {item, onCancel} = props;

    const [updateServiceTypeItem, {isLoading: isUpdating}] = useUpdateServiceTypeItemMutation();
    

    const updateServiceType = async(values: ServiceType)=>{
        const data:ServiceTypeCreateUpdateModel = {name: values.name, price:values.price};

            await updateServiceTypeItem({id: item.id, data})
            .then(()=>{
                close();
            });
    }
    const close = () => {onCancel();}
    
    if (isUpdating) return <Spin />;

    return (
    <div className={styles.editContainer}>
            <Form onFinish={updateServiceType} initialValues={item} className={styles.w100} layout='inline' requiredMark={false} >
                <Form.Item name="name" label='Наименование' rules={[{required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="price" label="Цена, руб." rules={[{required: true }]} >
                    <InputNumber precision={2} min="0" className={styles.priceInput} />
                </Form.Item>
                <Button type="link" htmlType="submit" loading={isUpdating}>Сохранить</Button>
                <Button type="link" onClick={close}>Отменить</Button>
        </Form>
    </div>
        )
}