import { Space } from "antd";
import { PersonViewModel } from "../../../models/person";
import { IPersonFIOStyle, getUserName } from "../../../models/userUtils";

/** Value in Span with bg */
export const StyledField = (props: {val:string|undefined, className: string|undefined})=>{
        return <span className={`${props.className}`}>{props.className? props.val || '' : props.val||''}</span>
};

export const PersonFio = (props: {data: PersonViewModel | undefined, styled?: IPersonFIOStyle })=>{
    const {data, styled} = props;
    

    if (!data) return <></>;    
    return (<>
        
        {styled ?
        <>
            <StyledField val={data.lastName} className={styled.last} /> 
            <StyledField val={data.firstName} className={styled.first}/>
            <StyledField val={data.middleName} className={styled.middle}/>
            {data.lastNameReplaced && (<StyledField val={data.lastNameReplaced} className={styled.lastreplaced}/>)}
        </>
        :
        `${data.lastName || ''} ${data.firstName || ''} ${data.middleName || ''} ${data.lastNameReplaced?'('+data.lastNameReplaced+')':''}`
        }
    </>)
}