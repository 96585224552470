import { Button, Form, Select } from "antd";
import { useState } from "react";
import AppCard from "../../../app/base/card"
import AppList from "../../../app/base/list";
import { buildListRequest, initialFilterParams, ListRequest, PagingInfo } from "../../../models/base/list";
import { Service } from "../../../models/service";
import { useGetServicesQuery, useRemoveBurialServiceMutation } from "../../../services/burialService";
import { useAcceptServiceMutation, useCompleteServiceMutation, useGetServiceStatusTypeListQuery, usePayServiceMutation, useProcessServiceMutation, useRejectServiceMutation } from "../../../services/burialServiceStatus";
import { useRejectBurialMutation } from "../../../services/moderation";
import { useGetServiceTypesAllQuery } from "../../../services/serviceType";
import { BurialServiceFilter } from "../../burialService/BurialServiceFilter";
import { ServiceListItem } from "../../burialService/ServiceListItem";
import { FormValuesToServiceFilterType } from "../../burialService/util";
import styles from './ServiceList.module.css';

export const AdminServiceList = ()=>{
    const [searchParams, setSearchParams] = useState<ListRequest>(initialFilterParams);
    const {data: services, isLoading} = useGetServicesQuery(searchParams);
    const fetch = ({page, pageSize}:PagingInfo)=>{
        const filter = FormValuesToServiceFilterType(filterForm.getFieldsValue());
        setSearchParams(buildListRequest(page,pageSize,filter));
    }
    const [deleteService, {isLoading: isDeleting}] = useRemoveBurialServiceMutation();
    const [acceptService] = useAcceptServiceMutation();
    const [makePayment] = usePayServiceMutation();
    const [rejectService] = useRejectServiceMutation();
    const [processService] = useProcessServiceMutation();
    const [completeService] = useCompleteServiceMutation();
    const {data: statusTypes} = useGetServiceStatusTypeListQuery();
    const {data: serviceTypes, isLoading: isServiceTypesLoading} = useGetServiceTypesAllQuery();
    
    const del = (id: number)=>{
        deleteService(id);
    }
    const serviceActions = [
        {value: 1, label: 'принять'},
        {value: 5, label: 'оплачено'},
        {value: 2, label: 'в обработку'},
        {value: 3, label: 'завершить'},
        {value: 4, label: 'отказать'}]
    
    const changeStatus = async(statusTypeId: number, id: number) =>{
        switch (statusTypeId){
            case 1: await acceptService(id); break;
            case 5: await makePayment(id); break;
            case 2: await processService(id); break;
            case 3: await completeService(id); break;
            case 4: await rejectService(id); break;
        }
    }
    const [filterForm] = Form.useForm();
    const Filter = ()=>{
        return <BurialServiceFilter filterForm={filterForm} statusTypeSource={statusTypes} typeSource={serviceTypes} />
      }
    const clearFilter = () => filterForm.resetFields();

    const actions = (item: Service) =>{
        return [
            <Form layout="inline" className={styles.actionFormFilter} onFinish={v=>changeStatus(v.statusTypeId, item.id)} requiredMark={false}>
                <Form.Item name='statusTypeId' rules={[{ required: true, message: 'Выберите статус услуги!' }]}>
                    <Select options={serviceActions} className={styles.actionSelect} />
                </Form.Item>
                <Button type='link' htmlType="submit">установить статус</Button>
            </Form>,
            <Button type='link' disabled={!item.canDelete} onClick={()=>del(item.id)}>удалить</Button>
        ]
    }

    return (
        <AppCard title="Услуги">
            <AppList 
                data={services?.items || []} 
                totalItems={services?.total || 0}
                isLoading={isLoading}
                fetchData={fetch}
                filter={{filter: <Filter/>, onClearFilter: clearFilter}}
                renderItem={ServiceListItem}
                renderActions={actions}
            />
        </AppCard>);
}