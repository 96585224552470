import { Form, Spin } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AppCard from "../../app/base/card";
import { ItemAction } from "../../app/base/list/ListItemAction";
import AppList from "../../app/base/list/ListNew";
import { buildListRequest, initialFilterParams, ListRequest, PagingInfo } from "../../models/base/list";
import { useGetPreBurialsQuery, usePublishBurialMutation } from "../../services/moderation";
import { FindResultItem } from "../burial/list/FindResultItem";
import { FormValuesToBurialFilterType } from "../burial/list/utils";
import styles from '../burial/list/Find.module.css';
import { BurialFilter } from "../burial/list/BurialFilter";
import { defaultListPageSize } from "../../app/consts/list";

const pageSize = defaultListPageSize;

export const PreBurialList = () => {
    const navigate = useNavigate();
    const [filterForm] = Form.useForm();

    const viewBurial = (id: number)=> {
      navigate(`/preburial/${id}`, {state:{from:'list'}});
    }
    const publish = (id: number) => {
        publishBurial(id).then(()=> refetch());
    }

    const [searchParams, setSearchParams] = useState<ListRequest>(initialFilterParams);
    const {data: burials, isLoading,  refetch,  } = useGetPreBurialsQuery(searchParams);
    const [publishBurial, { isLoading: isPublishing }] = usePublishBurialMutation();

    const clearFilter = () => filterForm.resetFields();

    const Filter = ()=>{
      return <Form form={filterForm} layout='vertical' className={styles.findFilter} onKeyUp={(e)=>{e.key==="Enter" && fetch({page:0, pageSize})}}><BurialFilter/></Form>
    }
    const fetch = ({page, pageSize}:PagingInfo)=>{
      const filter = FormValuesToBurialFilterType(filterForm.getFieldsValue());
      setSearchParams(buildListRequest(page,pageSize,filter));
    }
    
    return (  
        <AppCard title="Неопубликованные захоронения">
            {isPublishing && <Spin />}   
            <AppList 
                isLoading={isLoading}
                filter={{filter:<Filter />, onClearFilter: clearFilter }}
                data={burials?.items || [] }
                totalItems={burials?.total || 0}
                renderItem={(item, index) => (<FindResultItem key={index} item={item} viewItem={viewBurial} extraItemActions={[{action:publish, title:'опубликовать'}]} />)}
                fetchData = {fetch}
                renderActions={(item)=>  
                [ 
                <ItemAction item={item} title={'посмотреть запись'} onClick={viewBurial} />,
                <ItemAction item={item} title={'опубликовать'} onClick={publish} />,
                ] 
                }
            />
      </AppCard> 
    );
}