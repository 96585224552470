import { baseProtectedApi } from './baseProtectedApi';
import { ListData, ListRequest, ListResponse } from '../models/base/list';
import { ApiResponse } from './models/response';
import { IDictionary } from '../models/base/dictionary';

const url = 'servicestatus';

const burialServiceStatusApi = baseProtectedApi.injectEndpoints({
  endpoints: build => ({
    payService: build.mutation<{ success: boolean }, number>({
        query(param) {
          return {
            url: `${url}/pay?id=${param}`,
            method: 'POST',
          }
        },
        invalidatesTags: ['BurialServices','Services']
    }),
    acceptService: build.mutation<{ success: boolean }, number>({
        query(param) {
          return {
            url: `${url}/accept?id=${param}`,
            method: 'POST',
          }
        },
        invalidatesTags: ['BurialServices','Services']
    }),
    processService: build.mutation<{ success: boolean }, number>({
        query(param) {
          return {
            url: `${url}/process?id=${param}`,
            method: 'POST',
          }
        },
        invalidatesTags: ['BurialServices','Services']
    }),
    completeService: build.mutation<{ success: boolean }, number>({
        query(param) {
          return {
            url: `${url}/complete?id=${param}`,
            method: 'POST',
          }
        },
        invalidatesTags: ['BurialServices','Services']
    }),
    rejectService: build.mutation<{ success: boolean }, number>({
        query(param) {
          return {
            url: `${url}/reject?id=${param}`,
            method: 'POST',
          }
        },
        invalidatesTags: ['BurialServices','Services']
    }),
    getServiceStatusTypeList: build.query<IDictionary[], void>({
        query: ()=>({url: `${url}/type/list`}),
        transformResponse:(response:ListResponse<IDictionary>, meta, arg) =>{
            return   response.result.items;
        },
        providesTags: (result) => ['ServiceStatusTypeList'],
    })
  }),
  overrideExisting: true
});

export const { 
    useAcceptServiceMutation,
    usePayServiceMutation,
    useProcessServiceMutation,
    useCompleteServiceMutation,
    useRejectServiceMutation,
    useGetServiceStatusTypeListQuery
 } = burialServiceStatusApi;

