import { Drawer } from "antd";
import { BurialDetail } from "../detail/BurialDetail";
import { IBurialChangesPreviewProps } from "./types";
import styles from './BurialChangesPreview.module.css';

export const BurialChangesPreview = (props: IBurialChangesPreviewProps)=>{

    return (
        <Drawer height='90%' title='Просмотр изменений' placement="bottom" open={props.open} onClose={props.onClose}>
            <BurialDetail burial={props.burial} isLoading={false} isPreBurial={true} preview={true} />
        </Drawer>
    )
}