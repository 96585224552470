import { Button, Form, Input, Modal } from "antd";
import { useEffect, useState } from "react";
import { askSupportRequest, useLazyAskSupportQuery } from "../../services/support";
import notificationService from "../../app/toast/notificationService";

export const AskSupport = (props: {cssClassName?: string})=>{
    const [open, setOpen] = useState(false);
    const [sendMessage, {isLoading}] = useLazyAskSupportQuery();


    const send = async(values: askSupportRequest)=>{
        const result = await sendMessage(values);
        if (!result.isError) {
            setOpen(false);
            notificationService.info('Ваше сообщение отправлено');
        }
    }
  
    return (
        <>
        <Button type="link" className={props.cssClassName} onClick={()=>setOpen(true)}>Связаться с нами</Button>
        <Modal title="Связаться с нами" open={open} footer={[]} closable={true} onCancel={()=>setOpen(false)}>
            <div>
                <Form name="asksupportform" onFinish={send}>
                    <Form.Item name="userName" label="Ваше имя" rules={[{ required: true, message: 'Введите Ваше имя!' }]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name="userEmail" label="Ваш email"            
                    rules={[
                        {type: 'email', message: 'Неверный формат E-mail!'},
                        {required: true, message: 'Введите E-mail!'},
                    ]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name="message" label="Сообщение" rules={[{ required: true, message: 'Введите Сообщение!' }]}>
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item>
                        <Button className={'commonButton'} type="primary" htmlType="submit" loading={isLoading}>
                        отправить
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
        </>        
        

    )
}