import { Button } from "antd";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppCard from "../../../../app/base/card"
import AppList from "../../../../app/base/list";
import { buildListRequest, initialFilterParams, ListRequest, PagingInfo } from "../../../../models/base/list";
import { getUserName } from "../../../../models/userUtils";
import { useDeleteBurialMutation } from "../../../../services/ownedBurial";
import { useGetUserBurialListQuery, useGetUserQuery } from "../../../../services/user";
import { OwnedBurialListItem } from "../../../ownedBurial/OwnedBurialListItem";

export const UserBurialList = () => {
    const { id } = useParams();
    const userId = id || '';
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useState<ListRequest>(initialFilterParams);
    
    const {data: user} = useGetUserQuery(userId);
    const {data: burials, isLoading} =  useGetUserBurialListQuery({userId, info: searchParams});
    const [deleteBurial, {isLoading: isDeleting}] = useDeleteBurialMutation();

    const fetch = ({page, pageSize}:PagingInfo)=>{
        // const filter = FormValuesToBurialFilterType(filterForm.getFieldsValue());
        setSearchParams(buildListRequest(page,pageSize));
    }
    const deleteItem = (id: number) => {
        deleteBurial(id);
      }
    return (<>
        <AppCard title={`Захоронения пользователя ${getUserName(user)}`}>
            <div className="backcontainer">
                <Button onClick={()=>navigate(-1)}>Назад</Button>
            </div>
        <AppList 
          isLoading={isLoading}
          data={burials?.items || [] }
          totalItems={burials?.total || 0}
          renderItem={(item, index) => (<OwnedBurialListItem key={index} {...item} />)}
          fetchData = {fetch}
          renderActions={(item)=>  
            [ <Link to={`/burial/${item.id}`} state={{from:'list'}}>посмотреть</Link>,
            //   <Button type="link" onClick={()=>deleteItem(item.id)}>удалить</Button>
            ] 
            }
        />
        </AppCard>
    </>)
}