import { Col, Row } from "antd";
import { BurialGroupClaim } from "../../models/burialGroupRequest";
import { getUserName } from "../../models/userUtils";
import styles from './OwnedGroupRequest.module.css';

const colProps = { xs: 1,sm: 1, md: 1, lg: 1, xl: 1, xxl: 1};
const col5 = {...colProps, lg: 5, xl: 5, xxl: 5}  ;
const col4 = {...colProps, lg: 4, xl: 4, xxl: 4}  ;
const col3 = {...colProps, lg: 3, xl: 3, xxl: 3}  ;
const col2 = {...colProps, lg: 2, xl: 2, xxl: 2}  ;

export const OwnedGroupRequestListItem = (item:BurialGroupClaim)=>{
    return (<>
        <Row className={styles.resultRow} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className={styles.resultCol} span={5}>
                Заявитель: {getUserName(item.user)}
            </Col>
            <Col className={styles.resultCol} span={4}>
                {item.user.email}
            </Col>
            <Col className={styles.resultCol} span={5}>
                Захоронение: {item.burial.name}
            </Col>
            <Col className={styles.resultCol} span={4}>
            Место: <div className={styles.resultCemetery}>
                    <div>{item.burial.cemeteryName}</div>
                    <div>{item.burial.cityName?`(${item.burial.cityName})`:""}</div>
                </div>
            </Col>
            <Col className={styles.resultCol} span={3}>
                Создан {item.createdTimestamp}
            </Col>
            <Col className={styles.resultCol}  span={3}>
                {typeof item.granted !== 'undefined' && 
                    <div>{item.granted?'Одобрено': 'Отклонено'} {item.updatedTimestamp}</div>
                }
            </Col>
        </Row>
    </>)
}