import { Button, Col, Form, Row, Select } from "antd";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AppCard from "../../app/base/card";
import { ItemAction } from "../../app/base/list/ListItemAction";
import AppList from "../../app/base/list/ListNew";
import { buildListRequest, initialFilterParams, ListRequest, PagingInfo } from "../../models/base/list";
import { useDeleteBurialMutation, useDeletePreBurialMutation, useGetOwnedBurialsQuery } from "../../services/ownedBurial";
import { PlusOutlined } from '@ant-design/icons';
import { OwnedBurialListItem } from "./OwnedBurialListItem";
import styles from '../burial/list/Find.module.css';
import listStyles from '../../app/base/list/List.module.css';
import { FormValuesToBurialFilterType } from "../burial/list/utils";
import { BurialFilter } from "../burial/list/BurialFilter";
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { defaultListPageSize } from "../../app/consts/list";

const pageSize = defaultListPageSize;
const sortOptions = [
  {value: 'lastName', label: 'По Фамилии'},
  {value: 'firstName', label: 'По Имени'},
  {value: 'middleName', label: 'По Отчеству'},
  {value: 'birth', label: 'По Дате рождения'},
  {value: 'death', label: 'По Дате смерти'},
  {value: 'cemeteryCity', label: 'По городу'},
  {value: 'cemeteryName', label: 'По кладбищу'}
];
const defaultSort = sortOptions[0]; 
const defSortKey = defaultSort.value;

export const OwnedBurialList = () => {
    const navigate = useNavigate();
    const viewBurial = (id: number)=> {
      navigate(`/burial/${id}`,  {state:{from:'ownedlist'}});
    }
    const viewPreburial = (id: number)=> {
      navigate(`preburial/${id}`,  {state:{from:'ownedlist'}});
    }
    const [openSearch,setOpenSearch] = useState(false);
    const [sortValue, setSortValue] = useState<string>(defaultSort.value);
    const [searchParams, setSearchParams] = useState<ListRequest>(initialFilterParams);
    const {data: burials, isLoading} = useGetOwnedBurialsQuery(searchParams);
    const [deleteBurial, {isLoading: isDeleting}] = useDeleteBurialMutation();
    const [deletePrepublishBurial, {isLoading: isDeletingPrepublished}] = useDeletePreBurialMutation();
    
    const clearFilter = () => filterForm.resetFields();
    const [filterForm] = Form.useForm();
    const Filter = ()=>{
      return <Form form={filterForm} layout='vertical' className={styles.findFilter} onKeyUp={(e)=>{e.key==="Enter" && fetch({page:0, pageSize})}}><BurialFilter/></Form>
    }
    const fetch = ({page, pageSize}:PagingInfo)=>{
      const filter = FormValuesToBurialFilterType(filterForm.getFieldsValue());
      setSearchParams(buildListRequest(page,pageSize,filter));
    }

    const deleteItem = (id: number, isPreBurial: boolean) => {
      if (isPreBurial) deletePrepublishBurial(id)
      else  deleteBurial(id);
    }

    const Sort = ()=> (              
      <Select
        className={listStyles.sortSelect}
        onChange={sort}
        value={sortValue}
        options={sortOptions}
      >
      </Select>
    )
    const sort = (value: string) => {
      setSortValue(value);
      const params = {...searchParams, sort: [{property:value, value : 'asc'}]};
      setSearchParams(params);
    }; 
    
    return (
      <AppCard title="Мои места">
        <Row gutter={[16, 8]} className={styles.ownedBurialActions} style={{paddingBottom: '24px'}}>
          <Col>
            <Button icon={<PlusOutlined />} className="defaultActionButton"><Link to="burial/create">Добавить</Link></Button>
          </Col>
          <Col>
            <Button onClick={()=>setOpenSearch(!openSearch)}>Поиск {openSearch ? <UpOutlined /> : <DownOutlined />} </Button>
          </Col>
        </Row>
        <AppList
          isLoading={isLoading || isDeleting || isDeletingPrepublished}
          showFilter = {openSearch}
          filter={{filter: <Filter />, onClearFilter: clearFilter }}
          data={burials?.items || [] }
          totalItems={burials?.total || 0}
          renderItem={(item, index) => (<OwnedBurialListItem key={index} {...item} />)}
          fetchData = {fetch}
          renderActions={(item)=>  
          [ //<ItemAction item={item} title={'посмотреть запись'} onClick={viewBurial} />,
            <a key="list-loadmore-edit" onClick={()=>{item.hasUncommitedChanges?viewPreburial(item.id):viewBurial(item.id)}}>посмотреть запись</a>,
            <Button type="link" disabled={!item.own} onClick={()=>deleteItem(item.id, item.hasUncommitedChanges)}>удалить</Button>
          ]
          }
        />
    </AppCard>
    )
}