import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetBurialQuery } from "../../services/burial";
import { BurialDetailContainer } from "../burial/detail/BurialDetailContainer";

export const FindBurialDetail = () =>{
    const navigate = useNavigate();
    const { state } = useLocation();
    const { id } = useParams<{ id: any }>();
    const {data: burial, isLoading} = useGetBurialQuery(id);
    
    return (
        <BurialDetailContainer burial={burial} isLoading={isLoading} stateFrom={state?state.from:undefined} isPreBurial={false} />
    )
}