import { Button, Form } from "antd";
import { useState } from "react";
import AppCard from "../../app/base/card"
import AppList from "../../app/base/list";
import { buildListRequest, initialFilterParams, ListRequest, PagingInfo } from "../../models/base/list";
import { Service } from "../../models/service";
import { useGetServicesQuery, useRemoveBurialServiceMutation } from "../../services/burialService";
import { useGetServiceStatusTypeListQuery, usePayServiceMutation } from "../../services/burialServiceStatus";
import { useGetServiceTypesAllQuery } from "../../services/serviceType";
import { BurialServiceFilter } from "../burialService/BurialServiceFilter";
import { ServiceListItem } from "../burialService/ServiceListItem";
import { FormValuesToServiceFilterType } from "../burialService/util";
import styles from './OwnedServiceList.module.css';

export const OwnedServiceList = ()=>{
    const [searchParams, setSearchParams] = useState<ListRequest>(initialFilterParams);
    const {data: services, isLoading} = useGetServicesQuery(searchParams);
    const fetch = ({page, pageSize}:PagingInfo)=>{
        const filter = FormValuesToServiceFilterType(filterForm.getFieldsValue());
        setSearchParams(buildListRequest(page,pageSize,filter));
    }
    const [deleteService, {isLoading: isDeleting}] = useRemoveBurialServiceMutation();
    const [makePayment] = usePayServiceMutation();
    const {data: statusTypes} = useGetServiceStatusTypeListQuery();
    const {data: serviceTypes, isLoading: isServiceTypesLoading} = useGetServiceTypesAllQuery();
    
    const [filterForm] = Form.useForm();

    const actions = (item: Service) =>{
        return [
            // <Button type='link' onClick={()=>makePayment(item.id)}>оплатить</Button>,
            <Button disabled={!item.canDelete} type='link' onClick={async()=>await deleteService(item.id)}>удалить</Button>
        ]
    }
    const clearFilter = () => filterForm.resetFields();

    return (
        <AppCard title="Мои услуги">
            <AppList 
                data={services?.items || []} 
                totalItems={services?.total || 0}
                isLoading={isLoading}
                fetchData={fetch}
                filter={{filter:<BurialServiceFilter filterForm={filterForm} statusTypeSource={statusTypes} typeSource={serviceTypes} />, onClearFilter:clearFilter}}
                renderItem={ServiceListItem}
                renderActions={actions}
            />
        </AppCard>);
}