import { UploadFile } from "antd";

export interface User extends IFio {
  id: string;
  login: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  createdTimestamp: string;
  email: string;
  phoneNumber?: string;
  photo?: string;
  hasModerateRight?: boolean;
  hasAdminRight?: boolean;
}

export const EmptyUser: User = {
  id: '',
  login:'',
  email: '',
  createdTimestamp: ''
}

export interface IFio {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  lastNameReplaced?: string;
}


export interface UserViewModel {
  email: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  phoneNumber?: string;
  photo?: UploadFile[];
}

export const toUserViewModel = (data: User):UserViewModel => {
  return {
    email: data.email,
    firstName: data.firstName,
    lastName: data.lastName,
    middleName: data.middleName,
    phoneNumber: data.phoneNumber,
    photo: data.photo? [{uid:'-1', url: data.photo, name: data.photo, status: 'done'}]: undefined,
  }
}