import { Col, Row } from "antd";
import { FamilyLinkClaim } from "../../models/familyLinkClaim";
import { getUserName } from "../../models/userUtils";
import styles from './OwnedLinkRequest.module.css';

export const OwnedLinkRequestListItem = (item: FamilyLinkClaim)=> {
    return <>
        <Row className={styles.resultRow}>
            <Col span={5}>
                Заявитель: {getUserName(item.user)}</Col>
            <Col span={13}>
            Устанавливаемая связь: {getUserName(item.linkInfo.person)} ({item.linkInfo.personSiblingName}) - {getUserName(item.linkInfo.relativePerson)} ({item.linkInfo.relativePersonSiblingName}) 
            </Col>
            <Col span={3}>
            Дата запроса: {item.createdTimestamp}
            </Col>
            <Col span={3}>
                {typeof item.granted !== 'undefined' && 
                    <div>{item.granted?'Одобрено': 'Отклонено'} {item.updatedTimestamp}</div>
                }
            </Col>
        </Row>
    </>
}