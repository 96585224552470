import { useState } from "react";
import AppCard from "../../../app/base/card";
import AppList from "../../../app/base/list/ListNew";
import { buildListRequest, initialFilterParams, ListRequest, PagingInfo } from "../../../models/base/list";
import { News } from "../../../models/news";

import { useDeleteServiceTypeItemMutation, useGetServiceTypeListQuery } from "../../../services/serviceType";
import { FormListItem } from "./FormListItem";
import { ViewListItem } from "./ViewListItem";


export const ServiceTypeList = () => {
    const [editingKey,setEditingKey] = useState<number>();
    const [searchParams, setSearchParams] = useState<ListRequest>(initialFilterParams);
    const { data: serviceTypes, isLoading } = useGetServiceTypeListQuery(searchParams);

    const fetch = ({page, pageSize}:PagingInfo)=>{
        setSearchParams(buildListRequest(page,pageSize));
    }

    const [deleteServiceType, {isLoading: isDeleting}] = useDeleteServiceTypeItemMutation();
    
    const deleteItem = async(id: number)=>{
        await deleteServiceType(id);
    }

    const edit = (id: number)=>{
        setEditingKey(id);
    }

    return( 
        <AppCard title="Список типов услуг">
            <AppList 
                isLoading={isLoading}
                data={serviceTypes?.items|| []}
                totalItems={serviceTypes?.total || 0}
                fetchData = {fetch}
                renderItem={ (item, i)=> item.id==editingKey?<FormListItem item={item} onCancel={()=>setEditingKey(undefined)}/>:<ViewListItem data={item} key={i}/>}
                renderActions={(item)=> item.id==editingKey? []: [
                    <a key="list-loadmore-edit" onClick={()=> edit(item.id)}>редактировать</a>,
                    <a key="list-loadmore-edit" onClick={()=> deleteItem(item.id)}>удалить</a>
                ]
                }
            />
        </AppCard>
    )
}