import { Card, Col, Tag } from "antd";
import { Burial, BurialViewModel } from "../../../models/burial";
import { getUserAge, getUserGenderString, getUserName } from "../../../models/userUtils";
import styles from './FindPersonCard.module.css';
import { PersonViewModel } from "../../../models/person";
import { UserOutlined } from '@ant-design/icons';
import personpng from '../../../assets/person.png';
import { IFindPersonResultItem } from "./types";
import { PersonFindViewModel } from "../../../models/personSearchResult";


export const FindPersonCard = (props: IFindPersonResultItem) => {
    const {item, viewItem, extraItemActions, extraIcons} = props;

    const actions = ()=> {
        let result: React.ReactNode[] = [<a onClick={()=>viewItem(item.id)}>Посмотреть захоронение</a>];
        if (extraItemActions)
            extraItemActions.forEach(i=>result.push([<a onClick={(e)=>{e.stopPropagation(); i.action(item.id)}}>{i.title}</a>]))
        if (extraIcons)
            extraIcons.forEach(i=>result.push(i));            
        return result;
    }

    return (
        <>
        <Card 
            bordered={false} 
            hoverable
            onClick={()=>viewItem(item.burialId)}
            className={styles.findCard}
            // cover={<img src={item.image || tombpng} className={styles.findCardImg}/>}
            actions={actions()}
        >
            <Card.Meta
                title={<CardTilte person={item} />}
                description={<CardDescription person={item} />}
                avatar={<img src={item.image || personpng} className={styles.findCardImg}/>}
            />
            {/* <Card.Grid className={styles.cardCell}>1</Card.Grid>
            <Card.Grid className={styles.cardCell}>2</Card.Grid> */}
        </Card>
        </>
    )
}

const CardTilte = ({person}:{person: PersonFindViewModel})=> (
    <>
        <div className={styles.person}>{getUserName(person)} </div>
        <div>Годы жизни: {person.birth} - {person.death} {getUserAge(person.age)}</div>
        <div>
                {person.tags?.map((i,idx)=><Tag key={`${idx}${i}`}>{i}</Tag>)}
        </div>
    </>
)

const CardDescription = ({person: item}:{person: PersonFindViewModel})=>(
    <>
    <div style={{display: "flex", flexDirection: 'column'}}>
        <BurialTitle cemetery={item.cemeteryName} city={item.cemeteryCity} section={item.locationNumber} />
        <PersonExtra person={item} />
    </div>
        <PersonsDescription persons={item.burialPersons} />
    </>
)

const BurialTitle = (props:{city: string|undefined, cemetery: string|undefined, section:string|undefined})=>(
    <div>
        <div className={styles.findCardSection}>
            <div className={styles.findCardSectionTitle}>Кладбище: </div>
            <div className={styles.findCardSectionContent}> 
                <div>{props.cemetery}</div>
                <div>{props.city?`(${props.city})`:""}</div>
            </div>
        </div>
        <div className={styles.findCardSection}>
            <div className={styles.findCardSectionTitle}>Участок: </div>
            <div className={styles.findCardSectionContent}>{props.section}</div>
        </div>
    </div>
)

const PersonsDescription = ({persons}:{persons: PersonViewModel[] | undefined})=> (
    <>
    {persons && persons.length>0 &&
    <div>
        <div className={styles.findCardSectionTitle}>Захоронены вместе:</div> 
        <div>
            {persons.map((i,idx)=> <PersonInfo  key={`${i.id}_person${idx}`} person={i}/> )}
        </div>
    </div>     
    }
    </>
)

const PersonInfo = ({person}:{person: PersonViewModel})=>(  
        <div key={`${person.id}_${"person"}`} className={styles.findCardPerson}>
            <div><UserOutlined /></div>
            <div>
                {getUserName(person)} {person.birth} - {person.death}
            </div>
        </div>

)

const PersonExtra = ({person}:{person: PersonViewModel})=>(
    <div>
        <div className={styles.findCardSection}>
            <div className={styles.findCardSectionTitle}>Пол: </div>
            <div className={styles.findCardSectionContent}>{getUserGenderString(person.gender)}</div>
        </div>
        <div className={styles.findCardSection}>
            <div className={styles.findCardSectionTitle}>Место рождения: </div>
            <div className={styles.findCardSectionContent}>{person.birthPlace}</div>
        </div>
    </div>
)