import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, MenuProps } from 'antd';
import { Dropdown, Space } from 'antd';
import { Link, redirect, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAuth } from '../../app/hooks';
import { logOut } from '../auth/authSlice';
import styles from './Account.module.css';

export const Account = () => {
  const items: MenuProps['items'] = [
    {
      key: 60,
      label: <Link to={'/profile'}>Профиль</Link>
    },
    {
      key: 61,
      label: <Link to={'/changePassword'}>Сменить пароль</Link>
    },
    // {
    //   key: 62,
    //   label: <Link to={'/ownedburial'}>Захоронения</Link>
    // },
    // {
    //   key: 63,
    //   label: <Link to={'/ownedPerson'}>Персоны</Link>
    // },
    // {
    //   key: 64,
    //   label: <Link to={'/ownedservices'}>Услуги</Link>
    // },
    {
      key: 65,
      label: (
        <Button
          style={{width: '100%'}}
          type="link"
          onClick={async () => {
            dispatch(logOut());
            navigate('/');
          }}
        >
          Выйти
        </Button>
      )
    }
  ];

  const user = useAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <>
      {user && (
        <Dropdown className={styles.accountMenu} menu={{ items }}>
          <a onClick={e => e.preventDefault()}>
            <Space className={styles.accountSpaceItem}>
              <Avatar src={user.photo} icon={ <UserOutlined />}/>
              <div>
                {user.firstName} {user.lastName}
              </div>
              <DownOutlined />
            </Space>
          </a>
        </Dropdown>
      )}
    </>
  );
};
