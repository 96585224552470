import { useNavigate, useParams } from "react-router-dom";
import { useGetOwnPreBurialQuery, useUpdateOwnPreBurialMutation } from "../../../services/ownedBurial";
import { Spin } from "antd";
import { BurialForm } from "../../burial/burialForm/BurialForm";
import notificationService from "../../../app/toast/notificationService";

export const OwnedPreBurialEdit = ()=>{
    const navigate = useNavigate();
    const { id } = useParams();
    const {data, isLoading, error} = useGetOwnPreBurialQuery(Number(id));
    const [updateBurial, {isLoading: isUpdating}] = useUpdateOwnPreBurialMutation();
    if (isLoading) {
        return <div><Spin size="large" /></div>
    }
    if (!data) {
        return <div>Запрошенное Захоронение не найдено</div>
    }
    const update = async(data: FormData) => {
        if (id){
            await updateBurial({id: Number(id), data}).then((val)=>{
                if (!val.hasOwnProperty('error'))
                notificationService.info('Изменения будут доступны после проверки модератором')
            });
            navigate(-1);
        }
    }

    return (
        <BurialForm formTitle="Редактировать захоронение" data={data} isLoading={isLoading || isUpdating} onSubmit={update}/>
    )
}