import { isRejectedWithValue } from '@reduxjs/toolkit'
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit'
import notificationService from '../app/toast/notificationService';
import { logOut } from '../features/auth/authSlice';

/**
 * Log a warning and show a toast!
 */
const rtkQueryErrorLogger: Middleware =
  (api) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      if (action.payload.status == 401){
        notificationService.error('Необходимо авторизоваться');
        api.dispatch(logOut());
      }
      else if (action.payload.data && action.payload.data.errors && action.payload.data.errors[0] && action.payload.data.errors[0].msg)
        notificationService.warning(action.payload.data.errors[0].msg)
      else notificationService.error('Произошла ошибка');
    }

    return next(action)
}

export default rtkQueryErrorLogger;