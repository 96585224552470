import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { authApi } from '../services/auth';
import authReducer from '../features/auth/authSlice';
import { baseProtectedApi } from '../services/baseProtectedApi';
import ownedBurialsSlice from '../features/ownedBurial/ownedBurialsSlice';
import preBurialsSlice  from '../features/preBurial/preBurialSlice';
import  rtkQueryErrorLogger  from '../services/rtkQueryErrorLogger';
import burialSlice from '../features/burial/burialSlice';

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    auth: authReducer,
    [baseProtectedApi.reducerPath]: baseProtectedApi.reducer,
    burial: burialSlice,
    ownedBurials: ownedBurialsSlice,
    preBurials: preBurialsSlice,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(authApi.middleware).concat(baseProtectedApi.middleware).concat(rtkQueryErrorLogger)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
