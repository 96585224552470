import { Col, Form, Input, Row } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import AppCard from "../../../app/base/card";
import AppList from "../../../app/base/list";
import { buildListRequest, initialFilterParams, ListRequest, PagingInfo } from "../../../models/base/list";
import { User } from "../../../models/user";
import { useGetUsersQuery } from "../../../services/user";
import styles from './UserList.module.css';

const noDataString = 'не задано';

export const AdminUserList = ()=>{
    const [searchParams, setSearchParams] = useState<ListRequest>(initialFilterParams);
    const {data, isLoading} = useGetUsersQuery(searchParams);

    const fetch = ({page, pageSize}:PagingInfo)=>{
        const filter = filterForm.getFieldsValue();
        setSearchParams(buildListRequest(page,pageSize,filter));
    }
    
    const ListItem = (item: User) => {
        return (
            <Row className={styles.resultRow}>
                <Col className={styles.resultCol} span={3}>{item.email}</Col>
                <Col className={styles.resultCol} span={3}>{item.firstName}</Col>
                <Col className={styles.resultCol} span={3}>{item.middleName || noDataString}</Col>
                <Col className={styles.resultCol} span={3}>{item.lastName || noDataString}</Col>
                <Col className={styles.resultCol} span={3}>{item.phoneNumber || noDataString}</Col>
                <Col className={styles.resultCol} span={3}>{item.createdTimestamp}</Col>
                <Col span={9}></Col>
            </Row>
    )}
    
    const [filterForm] = Form.useForm();
    const Filter = ()=>{
        return <Form form={filterForm} layout='vertical' className={styles.findFilter}>
                <Row gutter={6}>
                    <Col span={6}>
                        <Form.Item name='lastName' label='Фамилия'>
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name='firstName' label='Имя'>
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name='middleName' label='Отчество'>
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name='email' label='Email'>
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
        </Form>
      }
    const clearFilter = () => filterForm.resetFields();

    const actions = (item: User) =>{
        return [
            <Link to={`burials/${item.id}`}>Захоронения пользователя</Link>
        ]
    }
    
    return (        
    <AppCard title="Пользователи сайта">
        <AppList 
            data={data?.items || []} 
            totalItems={data?.total || 0}
            isLoading={isLoading}
            fetchData={fetch}
            renderItem={ListItem}
            filter={{filter: <Filter/> , onClearFilter:clearFilter}}
            renderActions={actions}
        />
    </AppCard>
    );
}