import { Link, redirect, useNavigate, useSearchParams } from "react-router-dom";
import { RestoreRequest, useLazyConfirmAccountQuery, useLazyRequestEmailConfirmQuery} from "../../services/auth";
import { MailOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import notificationService from "../../app/toast/notificationService";
import { Button, Form, Input, Spin } from "antd";
import AppCard from "../../app/base/card";
import styles from './RestorePassword.module.css';
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { waitforme } from "../../app/base/util";
import { apiErrorData } from "../../models/base/apiErrorData";

export const ConfirmAccount = ()=>{
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const email = searchParams.get('email');
    const token = searchParams.get('token');
    const [linkBroken, setLinkBroken] = useState(false);
    const [tokenFailed, setTokenFailed] = useState(false);
    const [result, setResult] = useState<string|undefined>(undefined);

    const [confirm, {isLoading: isConfirming}] = useLazyConfirmAccountQuery();
    const [requestConfirmation, {isLoading}] = useLazyRequestEmailConfirmQuery();

    useEffect(()=>{
        if (!email || !token) setLinkBroken(true);
        else confirm({email,token}).then((e)=>{
            if (e.hasOwnProperty('error')){
                const err = e.error as apiErrorData;
                setResult(`${err.data.errors[0].msg}`);
                return setTokenFailed(true);
            }
            notificationService.info('Ваш аккаунт подтвержден');
            setResult("ok");
            waitforme(7000).then(()=> navigate('/login'));
        });
    },[])

    const getConfirmation = async(value:RestoreRequest)=>{
        await requestConfirmation(value).then(e=>{
            if (e.hasOwnProperty('error'))
                notificationService.error('Ошибка повторного подтверждения');
        });
    }

    
    return <>
        {linkBroken && <div>Неверная ссылка для подтверждения регистрации</div>}
        {isConfirming && <div className={styles.confirmSpin}><Spin /></div>}
        {result && 
            <div>{result=="ok" ?<>Регистрация завершена. Теперь Вы можете  <Link to='login'>войти</Link></>:<>{result}</>}
            </div>}
        {tokenFailed && 
        <div>
            <AppCard title="Не удалось выполнить подтверждение аккаунта"></AppCard>
                <div>Вы можете повторно запросить код подтверждения.</div>
                <div>
                    Введите адрес электронной почты, указаной при регистрации - на него Вам придет письмо со ссылкой для завершения регистрации.
                </div>
                <div className={styles.restoreForm}>
                <Form  name="resetForm" onFinish={getConfirmation}>
                    <Form.Item name="email" rules={[{ required: true, message: 'Введите email!' }]}>
                        <Input 
                            prefix={<MailOutlined className="site-form-item-icon" />}
                            placeholder="email"/>
                    </Form.Item>
                    <Form.Item>
                        <Button className={'defaultActionButton commonButton'} loading={isLoading} htmlType='submit'>Запросить подтверждение</Button>
                    </Form.Item>
                </Form>
                </div>

        </div>}
    </>;
  
}