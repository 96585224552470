import './App.css';
import NotificationContainer from './app/toast/NotificationContainer';
import { AppContentLayout } from './app/contentLayout/ContentLayout';
import { Route, Routes } from 'react-router-dom';
import { Home } from './features/home';
import ErrorPage from './features/error/ErrorPage';
import { FindBurialDetail } from './features/find/FindBurialDetail';
import { BurialEdit } from './features/burial/burialForm/BurialEdit';
import { BurialCreate } from './features/burial/burialForm/BurialCreate';
import PersonDetail from './features/person/personDetail';
import Find from './features/find';
import OwnedBurialList from './features/ownedBurial';
import Login from './features/auth/Login';
import { Register } from './features/auth/Register';
import Profile from './features/account/profile';
import FamilyGroup from './features/account/familyGroup';
import News from './features/news';
import PreBurialList from './features/preBurial';
import { PreBurialDetail } from './features/preBurial/PreBurialDetail';
import AdminNewsList from './features/admin/news';
import BurialServiceList from './features/burialService';
import OwnedServiceList from './features/ownedServices';
import { AdminServiceList } from './features/admin/service/ServiceList';
import { AdminUserList } from './features/admin/users/UserList';
import ServiceTypeManager from './features/admin/serviceType';
import UserBurialList from './features/admin/users/userBurials';
import { RestorePassword } from './features/auth/RestorePassword';
import { ConfirmAccount } from './features/auth/ConfirmAccount';
import { ResetPassword } from './features/auth/ResetPassword';
import { ChangePassword } from './features/account/profile/ChangePassword';
import { OwnedPreburialDetail } from './features/ownedBurial/preBurial/OwnedPreBurialDetail';
import { OwnedPreBurialEdit } from './features/ownedBurial/preBurial/OwnedPreBurialEdit';
import OwnedGroupRequestList from './features/ownedGroupRequest';
import OwnedLinkRequestList from './features/ownerLinkRequest';
import FavoritePersonList from './features/favorite';
import AdminCityManager from './features/admin/city';

function App() {
  return (
    <>
      <NotificationContainer />
      <Routes>
        <Route  path="/" element={<AppContentLayout />} errorElement={<ErrorPage />}>
          <Route index element={<Home />} />
          <Route path="burial/:id" element={<FindBurialDetail />} />
          <Route path="burial/:id/edit" element={<BurialEdit />} />          
          <Route path="ownedburial/burial/:id/edit" element={<BurialEdit />} />
          <Route path="ownedburial/burial/create" element={<BurialCreate />} />
          <Route path="ownedburial/preburial/:id" element={<OwnedPreburialDetail />} />
          <Route path="ownedburial/preburial/:id/edit" element={<OwnedPreBurialEdit />} />
          <Route path="person/:id" element={<PersonDetail />} />
          <Route path="/burial" element={<Find />} />
          <Route path="/ownedburial" element={<OwnedBurialList />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/confirmAccount" element={<ConfirmAccount />} />
          <Route path="/restorePassword" element={<RestorePassword />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
          <Route path="/changePassword" element={<ChangePassword />} />
          <Route path="/profile" element={<Profile />} />
          {/* <Route path="/mygroup" element={<FamilyGroup />} />           */}
          <Route path="/news" element={<News />} />
          <Route path='/preburials' element={<PreBurialList />} />  
          <Route path="preburial/:id" element={<PreBurialDetail />} />   
          <Route path='/admin/news/*' element={<AdminNewsList />} >
            {/* <Route path=':id' element={<CreateNewsItem/>} /> */}
          </Route>
          <Route path='admin/services' element={<AdminServiceList />} />
          <Route path='admin/users' element={<AdminUserList />} />
          <Route path='admin/servicetypes' element={<ServiceTypeManager />} />     
          <Route path='admin/users/burials/:id' element={<UserBurialList />} />  
          <Route path='admin/cities/*' element={<AdminCityManager />} />
           
          <Route path="burial/:id/servicelist" element={<BurialServiceList />} />  
          <Route path="/ownedservices" element={<OwnedServiceList />} />
          <Route path="/mygroup" element={<OwnedGroupRequestList />} />
          <Route path="/myLinkInquery" element={<OwnedLinkRequestList />} />
          <Route path="/favoritePersons" element={<FavoritePersonList />} />
          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
