import { strict } from "assert";
import { FilterType } from "../../../models/base/filter";

export function FormValuesToFilterType(values: { [s: string]: unknown; } | ArrayLike<unknown>): FilterType  {
    let res: FilterType = {};
    for (const [key, value] of Object.entries(values)){
        if (value && value.toString().trim() != '')
        res[key] = value.toString();
    }
    return res;
}