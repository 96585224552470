import { YMaps, Map as ReactMap, Placemark, ObjectManager, ZoomControl, SearchControl } from '@pbe/react-yandex-maps';
import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { IMapProps } from './types';
import { noInfoString } from '../../../app/consts/tooltip';

export const Map = (props : IMapProps) => {
    const {location, burialName, onLocationChanged, onClose} = props;
    const [position, setPosition] = useState<[number,number]>();
    const canEdit = onLocationChanged != undefined;

  useEffect(() => {
    if (location) setPosition([location.lat, location.lon]);
    else
      navigator.geolocation.getCurrentPosition((pos) => {
        setPosition([pos.coords.latitude,pos.coords.longitude])
      },
      (err) => {
        setPosition([57.043999, 41.036999]);
      });
  }, [location]);
  
  const mapContent = {
    hintContent: burialName?'<b> Кладбище: </b>' + burialName: undefined,
    balloonContent: `<div>${burialName?'Кладбище: '+burialName:''}</div><div>Координаты: ${ position? position[0].toFixed(3) +', '+position[1].toFixed(3) : noInfoString}</div>`
  }
  const onMapClick = (e: any) => {
    if (canEdit){
      const pos:[number,number] = e.get("coords");
      setPosition(pos);
    }
  };
  const onMarkDraged = (e: any) => {
    const pos:[number,number] = e.get('target').geometry.getCoordinates();
    if (pos) setPosition(pos);
  }

  const setLocation = () => {
    if (position){
      onLocationChanged && onLocationChanged({lat:Number(position[0].toFixed(6)), lon:Number(position[1].toFixed(6))});
      onClose();
    }
  }  
  
return(
  position?
  <>
    
    <YMaps query={{apikey:'99acdd2f-3fd4-4d6f-a61c-bf9fec577629'}}>
        <ReactMap
          modules={ [ 'geoObject.addon.balloon', 'geoObject.addon.hint' ] }
          onClick={onMapClick} 
          // options ={{copyrightProvidersVisible: false}}
          defaultState={{ center: position, zoom: 14, controls:[] }}>
          <Placemark  
              options={{draggable: canEdit}} 
              geometry={position} 
              properties={mapContent}
              onDragEnd={onMarkDraged}
          />
          {/* <SearchControl options={{ float: "right" }} /> */}
          <ZoomControl options={{"size": 'small'}} />
        </ReactMap>
    </YMaps>
    {canEdit &&  <Button onClick={setLocation}>Сохранить</Button>}
    <Button onClick={onClose}>Закрыть</Button>
  </>
  :<></>
  );
}
