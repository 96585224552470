import { Burial, BurialViewModel, toBurialViewModel } from '../models/burial';
import { personToViewModel } from '../models/person';
import { baseProtectedApi } from './baseProtectedApi';
import { ListData, ListRequest, ListResponse } from '../models/base/list';
import { ApiResponse } from './models/response';
import { formatDateTime, fullSitePath } from './models/util';

const url = 'ownedburial';

const ownedBurialApi = baseProtectedApi.injectEndpoints({
  endpoints: build => ({
    getOwnedBurials: build.query<ListData<BurialViewModel>, ListRequest>({
        query: (params) => ({method: 'POST', url: `${url}/list/`, body: params }),
        transformResponse: (response:ListResponse<Burial>, meta, arg) =>{
            const items = response.result.items.map(i=>toBurialViewModel(i));
            items.forEach(i => {
                i.persons?.forEach(p=> personToViewModel(p))
            });
            return   {
                items,
                total: response.result.total
            }
        },
        providesTags: (result) => result?.items
        ? [
            ...result.items.map(({ id }) => ({ type: 'OwnBurials' as const, id })),
            { type: 'OwnBurials', id: 'LIST' },
          ]
        : [{ type: 'OwnBurials', id: 'LIST' }],
      }),
    getOwnBurial: build.query<Burial, number>({
        query: (id) => `${url}/${id}`,
        transformResponse: (response: ApiResponse<Burial>) => {
            response.result.image = fullSitePath(response.result.image);
            response.result.persons?.forEach(p=> personToViewModel(p));
            response.result.attachedFile = fullSitePath( response.result.attachedFile);
            return response.result;
        }
    }), 
    updateBurial: build.mutation<Burial, Partial<{id: number, data:FormData}>>({
      query(data) {
        const { id, data:body } = data
        return {
          url: `${url}/${id}`,
          method: 'PUT',
          body,
        }
      },
      transformResponse: (response: ApiResponse<Burial>)=>{
        return response.result;
      },
      invalidatesTags: (result, error, arg) => [{type:'OwnBurials', id: arg.id}]
    }),
    deleteBurial: build.mutation<{ success: boolean; id: number }, number>({
      query(id) {
        return {
          url: `${url}/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, arg) => [{type:'OwnBurials', id: arg}]
    }),
    deletePreBurial: build.mutation<{ success: boolean; id: number }, number>({
      query(id) {
        return {
          url: `${url}/prepublished/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, arg) => [{type:'OwnBurials', id: arg}]
    }),
    createOwnBurial: build.mutation<Burial, FormData>({
      query(data) {
        return {
          url: `${url}`,
          method: 'POST',
          body: data
        }
      },
      invalidatesTags: [{ type: 'OwnBurials', id: 'LIST' }]
    }),
    getOwnPreBurial: build.query<Burial, number>({
      query: (id) => `${url}/prepublished/${id}`,
      transformResponse: (response: ApiResponse<Burial>) => {
          // response.result.persons?.forEach(p=> personToViewModel(p));
          // response.result.attachedFile = fullSitePath( response.result.attachedFile);
          return response.result;
      }
    }),
    updateOwnPreBurial: build.mutation<Burial, Partial<{id: number, data:FormData}>>({
      query(data) {
        const { id, data:body } = data
        return {
          url: `${url}/prepublished/${id}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: (result, error, arg) => [{type:'OwnBurials', id: arg.id}]
    }),
  }),
  overrideExisting: true
});

export const { 
    useGetOwnedBurialsQuery,
    useGetOwnBurialQuery,
    useCreateOwnBurialMutation,
    useUpdateBurialMutation,
    useDeleteBurialMutation,
    useGetOwnPreBurialQuery,
    useLazyGetOwnPreBurialQuery,
    useUpdateOwnPreBurialMutation,
    useDeletePreBurialMutation
 } = ownedBurialApi;

export const {
    endpoints: { getOwnedBurials, getOwnBurial}
} = ownedBurialApi;