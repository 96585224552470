export interface IItemEntity {
    type: 'entity';
    id: number;
}

export interface IListItemActionProps {
    title: string;
    onClick?: (id: number)=>void;
}
// export const ListItemAction = (props: IListItemActionProps)=> (
// <a key="list-loadmore-edit" onClick={()=>props.onClick}>{props.title}</a>
// );


export interface IListItemActionProps1<T> {
    item: T;
    title: string;
    onClick: (id: number)=>void;
}

export function ItemAction<T extends IItemEntity>(props: IListItemActionProps1<T>)  {
    const {title, onClick} = props;
    const {id} = props.item;
    return <a key="list-loadmore-edit" onClick={()=>onClick && onClick(id)}>{title}</a>;
}