import { Button, Space } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppCard from "../../app/base/card";
import { useGetPreBurialModificationsQuery, useGetPreBurialQuery, useLazyGetPreBurialModificationsQuery, usePublishBurialMutation, useRejectBurialMutation } from "../../services/moderation";
import { BurialDetail } from "../burial/detail/BurialDetail";
import styles from './PreBurialDetail.module.css';
import { useAuth } from "../../app/hooks";
import { useEffect } from "react";
import { toBurialViewModel } from "../../models/burial";

export const PreBurialDetail = () => {
    const user = useAuth();
    const navigate = useNavigate();
    const { id } = useParams<{ id: any }>();
    const {data: burial, isLoading} = useGetPreBurialQuery(id);
    const [publishBurial, { isLoading: isPublishing }] = usePublishBurialMutation();    
    const [rejectBurial, { isLoading: isRejecting }] = useRejectBurialMutation();
    const {data: modifications} = useGetPreBurialModificationsQuery(id, {skip: !user?.hasModerateRight});
    const data = burial && toBurialViewModel(burial);

    const publish = ()=>{
        publishBurial(id).then(()=>navigate(-1));
    }
    const reject = ()=>{
        rejectBurial(id).then(()=>navigate(-1));
    }

    if (!user) return (<></>);
    return (<>
        <AppCard title={`Захоронение ${burial?.name || ''}`} className={modifications && modifications.name?styles.modified:undefined}>
                <div className={styles.actions}>
                    <Space>
                    <Button className="defaultActionButton" onClick={publish} loading={isPublishing}>Опубликовать</Button>
                    <Button onClick={reject} loading={isRejecting}>Отклонить</Button>
                    <Button onClick={()=>navigate(-1)}>Назад</Button>
                    {/* не реализовано */}
                    {/* {burial && burial.canEdit &&
                            <Button><Link to={`edit`}>Редактировать</Link></Button>
                    } */}
                    </Space>
                </div>
                <BurialDetail burial={data} isLoading={isLoading} isPreBurial={true} preBurialModifiedFields={modifications} />
        </AppCard>
    </>);
}