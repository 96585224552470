import AppCard from '../../app/base/card';
import { PersonList } from '../person/list/PersonList';


export const Find = () => {
  
  return (
    <AppCard title="Поиск">
      <PersonList />
    </AppCard>
  );
};
