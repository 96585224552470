import { noInfoString } from "../app/consts/tooltip";
import { formatDateTime, fullSitePath } from "../services/models/util";
import { Person, PersonViewModel, personToViewModel } from "./person";

export interface BurialDescription {
    burialId: number;
    locationNumber: string;
    cemeteryCity: string;
    cemeteryName: string;
}

/** Поиск персон для установки родственных/иных связей */
export interface PersonSearchResult extends Person{
    burialId: number;
    locationNumber: string;
    cemeteryCity: string;
    cemeteryName: string;
}

/** общий Поиск персон*/
export interface PersonFind  extends Person, BurialDescription {
    burialPersons?: Person[];
    isFavorite?: boolean;
}

/** персона страницы общего поиска */
export interface PersonFindViewModel extends PersonViewModel, BurialDescription {
    burialPersons?: PersonViewModel[];
    isFavorite?: boolean;
}

export const personFindToViewModel = (p: PersonFind): PersonFindViewModel =>{
    const person: PersonFindViewModel = {
        id: p.id,
        firstName: p.firstName,
        middleName: p.middleName,
        lastName: p.lastName,
        birth:  p.birth ? formatDateTime(p.birth) : noInfoString,
        death: p.death ? formatDateTime(p.death) : noInfoString,
        birthPlace: p.birthPlace || noInfoString,
        image: p.image?fullSitePath(p.image):undefined,
        attachedFile:p.attachedFile?fullSitePath(p.attachedFile):undefined,
        createdByUser: p.createdByUser,
        gender: p.genderId,
        note: p.note,
        lastNameReplaced: p.lastNameReplaced,
        tags: p.tags,
        burialId: p.burialId,
        locationNumber: p.locationNumber || noInfoString,
        cemeteryCity: p.cemeteryCity || noInfoString,
        cemeteryName: p.cemeteryName || noInfoString,
        burialPersons: p.burialPersons? p.burialPersons.map(i=> personToViewModel(i)): undefined,
        isFavorite: p.isFavorite,
        age: p.age
    };
    return person;
}