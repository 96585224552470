import { Button } from "antd";
import { Link, useNavigate } from "react-router-dom";
import styles from '../../find/Find.module.css';
import { Burial, toBurialViewModel } from "../../../models/burial";
import AppCard from "../../../app/base/card";
import { BurialDetail } from "./BurialDetail";
import { UncommitedChangesIcon } from "../../ownedBurial/UncommitedChangesIcon";

export const BurialDetailContainer = (props: {burial: Burial|undefined, stateFrom: string, isLoading: boolean, isPreBurial: boolean}) =>{
    const {burial, stateFrom, isLoading, isPreBurial} = props;
    const navigate = useNavigate();
    const data = burial && toBurialViewModel(burial);
    return (
        <AppCard title={`Захоронение ${burial?.name ||''}`}>
            {burial && 
            <>
                <div className={styles.actionContainer}>
                    {                                
                    burial.canEdit && 
                    <Button disabled={burial.hasUncommitedChanges} className="defaultActionButton">{burial.hasUncommitedChanges ? <UncommitedChangesIcon text="Редактировать " />: <Link to={`edit`} state={{from:stateFrom}}>Редактировать </Link>}
                    </Button>
                    }
                    
                    {stateFrom && <Button onClick={()=>navigate(-1)}>Назад</Button>}
                </div>
            </>
            }
            <BurialDetail burial={data} isLoading={isLoading} isPreBurial={isPreBurial}/>
        </AppCard>
    )
}