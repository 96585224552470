import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { FilterType, FindState, IFilter } from "../../models/base/filter";
import { useGetBurialsQuery } from '../../services/burial';
  
const initialState: FindState = {
    filter: {}
};



  export const preBurialsSlice = createSlice({
    name: 'preBurial',
    initialState,
    reducers:{
        updateFilter: (state, action: PayloadAction<IFilter>) => {
          const filterCopy = {...state.filter};
          if (action.payload.fieldValue.trim() == '')
            delete filterCopy[action.payload.fieldName];
          else
            filterCopy[action.payload.fieldName] = action.payload.fieldValue;
          state.filter = filterCopy;
        },
        clearFilter : state => {state.filter = {}}
    }
  })

  export const {updateFilter, clearFilter} = preBurialsSlice.actions;
  export const selectPreBurialFilter = (state: RootState) => state.preBurials.filter;

  export default preBurialsSlice.reducer;