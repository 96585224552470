export type NotificationType = 'success' | 'error' | 'warning' | 'info';

export interface INotificationItem {
  type: NotificationType;
  message: string;
  title?: NotificationTitle;
  timeOut?: number;
}

export enum NotificationTitle {
  ERROR = 'Ошибка',
  INFO = 'Информация',
  SUCCESS = 'Успех',
  WARNING = 'Предупреждение'
}
