import { Burial, BurialViewModel, toBurialCreateModel, toBurialViewModel } from '../models/burial';
import { personToViewModel } from '../models/person';
import { baseProtectedApi } from './baseProtectedApi';
import { ListData, ListRequest, ListResponse } from '../models/base/list';
import { ApiResponse } from './models/response';
import { User } from '../models/user';
import { formatDateTime } from './models/util';

const url = 'user';

export interface UserBurialListRequest {
  userId: string;
  info: ListRequest;
}

const userApi = baseProtectedApi.injectEndpoints({
  endpoints: build => ({
    getUsers: build.query<ListData<User>, ListRequest>({
        query: (params) => ({method: 'POST', url: `${url}/list/`, body: params }),
        transformResponse: (response:ListResponse<User>, meta, arg) =>{
            response.result.items.forEach(i=>i.createdTimestamp = formatDateTime(i.createdTimestamp));
            return   {
                items: response.result.items,
                total: response.result.total
            }
        }
      }),
    getUserBurialList: build.query<ListData<BurialViewModel>, UserBurialListRequest>({
        query: (params) => ({method: 'POST', url: `${url}/burials`, body: params }),
        transformResponse: (response:ListResponse<Burial>, meta, arg) =>{
          const items = response.result.items.map(i=>toBurialViewModel(i));
          items.forEach(i => {
              i.persons?.forEach(p=> personToViewModel(p))
          });
          return   {
              items,
              total: response.result.total
          }
        }
    }), 
    getUser: build.query<User, string>({
      query: (id) => `${url}/${id}`,
      transformResponse: (response: ApiResponse<User>) => {
          return response.result;
      }
  }),  
  }),
  overrideExisting: true
});

export const { 
   useGetUsersQuery ,
   useGetUserBurialListQuery,
   useGetUserQuery
 } = userApi;

