import { Button, Col, Form, Input, InputNumber, Row } from "antd";
import { ServiceType } from "../../../models/service";
import styles from './ServiceTypeManager.module.css';

export const ViewListItem = ({data}: {data: ServiceType})=>{
    return (
        <Row className={styles.w100}>
            <Col span="8">{data.name}</Col>
            <Col span="16"> {data.price} руб. </Col>
        </Row>
    );
}