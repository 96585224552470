import { baseProtectedApi } from './baseProtectedApi';
import { ListData, ListRequest, ListResponse } from '../models/base/list';
import { ApiResponse } from './models/response';
import { BurialGroup } from '../models/burialGroup';
import { BurialGroupClaim } from '../models/burialGroupRequest';
import { formatDateTime } from './models/util';
import { noInfoString } from '../app/consts/tooltip';

const url = 'burialGroup';

export interface AddMemberRequest {
    burialId: number,
    userId: string
}
export interface AddGroupRequestRequest {
  burialId: number,
}

const burialGroupApi = baseProtectedApi.injectEndpoints({
  endpoints: build => ({
    getBurialGroups: build.query<ListData<BurialGroup>, ListRequest>({
        query: (params) => ({method: 'POST', url: `${url}/list/`, body: params }),
        transformResponse: (response:ListResponse<BurialGroup>, meta, arg) =>{
            return   {
                items: response.result.items,
                total: response.result.total
            }
        }
      }),
    getBurialGroup: build.query<BurialGroup, number>({
        query: (id) => `${url}/${id}`,
        transformResponse: (response: ApiResponse<BurialGroup>) => {
            return response.result;
        }
    }), 
    addMember: build.mutation<{ success: boolean }, AddMemberRequest>({
        query(params) {
          return {
            url: `${url}/add/${params.burialId}`,
            method: 'POST',
            body: {userId: params.userId}
          }
        },
    }),
    removeMember: build.mutation<{ success: boolean }, AddMemberRequest>({
        query(params) {
          return {
            url: `${url}/remove/${params.burialId}`,
            method: 'POST',
            body: {userId: params.userId}
          }
        },
    }),
    getBurialGroupRequests: build.query<ListData<BurialGroupClaim>, ListRequest>({
      query: (params) => ({method: 'POST', url: `${url}/request/list/`, body: params }),
      transformResponse: (response:ListResponse<BurialGroupClaim>, meta, arg) =>{
          response.result.items.forEach(i=>{
            i.createdTimestamp = i.createdTimestamp?formatDateTime(i.createdTimestamp): noInfoString;
            if (i.updatedTimestamp) i.updatedTimestamp = formatDateTime(i.updatedTimestamp);
          });
          return   {
              items: response.result.items,
              total: response.result.total
          }
      },
      providesTags: (result) => result?.items
      ? [
          ...result.items.map(({ id }) => ({ type: 'BurialGroupClaims' as const, id })),
          { type: 'BurialGroupClaims', id: 'LIST' },
        ]
      : [{ type: 'BurialGroupClaims', id: 'LIST' }],
    }),
    createBurialGroupRequest: build.query<{ success: boolean }, AddGroupRequestRequest>({
      query(params) {
        return {
          url: `${url}/request/add`,
          method: 'POST',
          body: params
        }
      },
    }),
    confirmGroupRequest: build.mutation<{ success: boolean }, number>({
      query(params) {
        return {
          url: `${url}/request/confirm/${params}`,
          method: 'POST',
        }
      },
      invalidatesTags: (result, error, arg) => [{type:'BurialGroupClaims', id: arg}]
    }),
    rejectGroupRequest: build.mutation<{ success: boolean }, number>({
      query(params) {
        return {
          url: `${url}/request/reject/${params}`,
          method: 'POST',
        }
      },
      invalidatesTags: (result, error, arg) => [{type:'BurialGroupClaims', id: arg}]
    }),

  }),
  overrideExisting: true
});

export const { 
    useGetBurialGroupsQuery,
    useGetBurialGroupQuery,
    useAddMemberMutation,
    useRemoveMemberMutation,

    useGetBurialGroupRequestsQuery,
    useConfirmGroupRequestMutation,
    useRejectGroupRequestMutation,
    useLazyCreateBurialGroupRequestQuery
 } = burialGroupApi;

