import { useState } from "react"
import { useNavigate } from "react-router-dom"
import AppCard from "../../../app/base/card"
import AppList from "../../../app/base/list/ListNew"
import { FilterType } from "../../../models/base/filter"
import { buildListRequest, initialFilterParams, ListRequest, PagingInfo } from "../../../models/base/list"
import { News } from "../../../models/news"
import { useDeleteNewsItemMutation, useGetNewsListQuery } from "../../../services/news"
import { NewsDetail } from "./NewsDetail"

const NewsListItem = (props: News)=>{
    return <div>{props.createdTimeStamp} {props.name} </div>
}

export const NewsList = () => {
    const [editingKey,setEditingKey] = useState<number>();
    const [searchParams, setSearchParams] = useState<ListRequest>(initialFilterParams);
    const { data: news, isLoading } = useGetNewsListQuery(searchParams);

    const fetch = ({page, pageSize}:PagingInfo)=>{
        setSearchParams(buildListRequest(page,pageSize));
    }

    const [deleteNewsItem, {isLoading: isDeleting}] = useDeleteNewsItemMutation();
    const deleteNews = async(id: number)=>{
        await deleteNewsItem(id);
    }

    const edit = (id: number)=>{
        setEditingKey(id);
    }

    return( 
        <AppCard title="Список новостей">
            <AppList 
                isLoading={isLoading}
                data={news?.items || []}
                totalItems={news?.total || 0}
                fetchData = {fetch}
                renderItem={ (item, i)=> item.id==editingKey?<NewsDetail id={item.id} onCancel={()=>setEditingKey(undefined)}/>:<NewsListItem {...item} key={i}/>}
                renderActions={(item)=> item.id==editingKey? []: [
                    <a key="list-loadmore-edit" onClick={()=> edit(item.id)}>редактировать</a>,
                    <a key="list-loadmore-edit" onClick={()=> deleteNews(item.id)}>удалить</a>
                ]
                }
            />
        </AppCard>
    )
}