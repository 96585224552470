import { useState } from "react";
import AppCard from "../../app/base/card";
import AppList from "../../app/base/list";
import { useGetFavoritePersonsQuery, useRemoveFavoritePersonMutation } from "../../services/favoritePerson";
import { ListRequest, PagingInfo, buildListRequest } from "../../models/base/list";
import { defaultListPageSize } from "../../app/consts/list";
import { FindPersonCard } from "../person/list/FindPersonCard";
import { useNavigate } from "react-router-dom";

const pageSize = defaultListPageSize;

const initialFilter: ListRequest = {  
  paging: {start: 0, limit: pageSize},
  filter: [],
  sort: []
}

export const FavoritePersonList = ()=>{
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useState<ListRequest>(initialFilter);
    const {data, isLoading} = useGetFavoritePersonsQuery(searchParams);
    const [remove, {isLoading: isRemoving}] = useRemoveFavoritePersonMutation();

    const fetch = ({page, pageSize}:PagingInfo)=>{
        // const filter = FormValuesToBurialFilterType(filterForm.getFieldsValue());
        setSearchParams(buildListRequest(page,pageSize));
    }
    const viewBurial = (id: number)=> {
        navigate(`/burial/${id}`, {state:{from:'list'}});
    }
    const removeFromFavorite = (id: number)=>{
        remove(id);
    }

    return (
        <>
            <AppCard title="Избранные персоны">
                <AppList 
                    data={data?.items || []}
                    totalItems={data?.total || 0}
                    fetchData={fetch}
                    isLoading = {isLoading}
                    renderItem={(item, index) => (
                        <FindPersonCard key={index} item={item} 
                            viewItem={viewBurial}  
                            extraItemActions={[{action:removeFromFavorite, title:'Удалить из избранного'}]}
                       />)}
                />
            </AppCard>
        </>
    );
}