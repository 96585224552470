import { defaultListPageSize } from "../../app/consts/list";
import { FilterType } from "./filter";

export interface ListData<T> {
  items: T[];
  total: number;
}

export interface ListRequest{
    paging:{
      start: number;
      limit: number;
    }
    filter?: FilterInfo[],
    sort?: FilterInfo[]
}
export interface ListResponse<T> {
  result : {
    items: T[];
    total: number;
  }
}

/** Параметры пагинации при запросе данных */
export interface PagingInfo {
    page: number;
    pageSize: number;
}
/** Параметры фильтра при запросе данных*/
export interface FilterInfo {
    property: string;
    value: string | string[];
}
export const buildListRequest = (page: number, pageSize: number, filter?: FilterType, sort?: FilterType): ListRequest =>{
    const correctFilterParams: FilterInfo[] = [];
    if (filter) {
      Object.keys(filter).forEach((filterKey: string) => {
        if (filter[filterKey]) {
          let paramVal: string | string[] = filter[filterKey];
          if (Array.isArray(paramVal) && paramVal.length == 0 ) return;
          if (Array.isArray(paramVal) && paramVal.length != 0 && Object.keys(paramVal[0]).includes('key')) {
            paramVal = paramVal.map((item) => item);
          }
          const correctFormatParamValue = Array.isArray(paramVal) ? JSON.stringify(paramVal) : paramVal;
          correctFilterParams.push({ property: filterKey, value: correctFormatParamValue });
        }
      });
    }
    const sortParams: FilterInfo[] = [];
    if (sort){
      Object.keys(sort).forEach((sortKey: string) => {
        if (sort[sortKey]) sortParams.push( {property: sortKey, value: sort[sortKey]});
      })
    }
    return {
        paging:{
          start: page>0? (page-1)*pageSize : 0, 
          limit: pageSize
        },
        filter: filter? correctFilterParams : [] ,
        sort: sort?  sortParams  : []
    }
}

export const initialFilterParams: ListRequest = {
  paging: {start: 0, limit: defaultListPageSize},
  filter: [],
  sort: []
}