import { baseProtectedApi } from './baseProtectedApi';
import { ListData, ListRequest, ListResponse } from '../models/base/list';
import { ApiResponse } from './models/response';
import { BurialServiceTimeLine, Service, ServiceType } from '../models/service';
import { formatDateTime } from './models/util';

const url = 'service';

export interface GetBurialServiceListRequest {
    burialId: number,
    params:ListRequest
}
export interface AddServiceRequest {
    burialId: number,
    serviceTypeId: number
  }

const burialServiceApi = baseProtectedApi.injectEndpoints({
  endpoints: build => ({
    getBurialServices: build.query<ListData<Service>, GetBurialServiceListRequest>({
        query: ({params, burialId}) => ({method: 'POST', url: `${url}/list/${burialId}`, body: params }),
        transformResponse: (response:ListResponse<Service>, meta, arg) =>{
            response.result.items.forEach(i=>i.createdTimeStamp = formatDateTime(i.createdTimeStamp));
            return   {
                items: response.result.items,
                total: response.result.total
            }
        },        
        providesTags: (result) => result?.items
        ? [
            ...result.items.map(({ id }) => ({ type: 'Services' as const, id })),
            { type: 'BurialServices', id: 'LIST' },
          ]
        : [{ type: 'BurialServices', id: 'LIST' }],
      }),
    getServices: build.query<ListData<Service>, ListRequest>({
        query: (params) => ({method: 'POST', url: `${url}/list/`, body: params }),
        transformResponse: (response:ListResponse<Service>, meta, arg) =>{
            response.result.items.forEach(i=>i.createdTimeStamp = formatDateTime(i.createdTimeStamp));
            return   {
                items: response.result.items,
                total: response.result.total
            }
        },
        providesTags: (result) => result?.items
        ? [
            ...result.items.map(({ id }) => ({ type: 'Services' as const, id })),
            { type: 'Services', id: 'LIST' },
          ]
        : [{ type: 'Services', id: 'LIST' }],
    }),  
    // getServiceTypes: build.query<ServiceType[], void>({
    //     query: (id) => `${url}/servicetype/list`,
    //     transformResponse: (response: ListResponse<ServiceType>) => {
    //         return response.result.items;
    //     }
    // }),   
    getBurialService: build.query<BurialServiceTimeLine, number>({
        query: (id) => `${url}/${id}`,
        transformResponse: (response: ApiResponse<BurialServiceTimeLine>) => {
            return response.result;
        }
    }), 
    addBurialService: build.mutation<{ success: boolean }, AddServiceRequest>({
        query(params) {
          return {
            url: `${url}`,
            method: 'POST',
            body: params
          }
        },
        invalidatesTags: ['BurialServices','Services']
    }),
    removeBurialService: build.mutation<{ success: boolean }, number>({
        query(param) {
          return {
            url: `${url}/${param}`,
            method: 'DELETE',
          }
        },
        invalidatesTags: ['BurialServices','Services']
    }),
    
  }),
  overrideExisting: true
});

export const { 
    useGetBurialServicesQuery,
    useGetServicesQuery,
    // useGetServiceTypesQuery,
    useAddBurialServiceMutation,
    useGetBurialServiceQuery,
    useRemoveBurialServiceMutation
 } = burialServiceApi;

