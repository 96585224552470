import { Button, Layout, Menu, MenuProps, Space } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAuth } from '../../app/hooks';
import styles from './AppHeader.module.css';
import Account from '../account';
import logoImg from '../../assets/trh_memory.jpg';
import { AskSupport } from '../support/ContactUs';
import { useState } from 'react';

const { Header } = Layout;
const items: MenuProps['items'] = [
  {
    label: <Link to={'/'}>Главная</Link>,
    key: '1'
  },
  {
    label: <Link to={'/news'}>Новости</Link>,
    key: '3'
  },
  {
    label: <Link to={'/burial'}>Поиск</Link>,
    key: '4'
  },
];
const userItems: MenuProps['items'] = [
  // {
  //   label: <Link to={"/ownedBurial"}>Мои места</Link>,
  //   key: "5",
  // },
  {
    label: 'Личный кабинет',
    key: 6,
    children: [
      {
        key: 62,
        label: <Link to={'/ownedBurial'}>Мои места</Link>
      },
      {
        key: 61,
        label: <Link to={'/mygroup'}>Запросы в группы</Link>
      },
      {
        key: 63,
        label: <Link to={'/myLinkInquery'}>Запросы на создание связи</Link>
      },
      {
        key: 65,
        label: <Link to={'/favoritePersons'}>Избранные Персоны</Link>
      },
      {
        key: 64,
        label: <Link to={'/ownedservices'}>Услуги</Link>
      }
    ]
  },
];
const moderItems: MenuProps['items'] = [
  {
    label: 'Администрирование',
    key: 7,
    children: [
      {
        key: 72,
        label: <Link to={'/preburials'}>Публикация захоронений</Link>
      },
      {
        key: 71,
        label: <Link to={'/admin/users'}>Пользователи</Link>
      },
      {
        key: 73,
        label: <Link to={'/admin/news'}>Новости</Link>
      },
      {
        key: 74,
        label: <Link to={'/admin/services'}>Услуги</Link>
      },
      {
        key: 75,
        label: <Link to={'/admin/servicetypes'}>Типы услуг</Link>
      },
      {
        key: 76,
        label: <Link to={'/admin/cities'}>Справочник местонахождений</Link>
      }
    ]
  }  
]

export const AppHeader = () => {
  const u = useAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const menu = u ? u.hasModerateRight? items.concat(moderItems) : items.concat(userItems) : items;


  return (
    <Header className="header">
      <div className={styles.headerLogo}>
        <img className={styles.logoimg} src={logoImg}/>
      </div>
      <Menu theme="dark" mode="horizontal" items={menu} className="menu" />
      <div className={styles.authContainer}>
        <Space>
          <AskSupport cssClassName={styles.supportLink}/>
          {!u ? (
            <>
            <Button
              onClick={() => {
                navigate('/login');
              }}
            >
              Войти
            </Button>
            <Button>
              <Link to={'/register'}>Регистрация</Link>
            </Button>  
            </>          
          ) : (
            <>
              {/* <div className={styles.userName}>{`Welcome, ${u.login}`}</div> */}
              <Account />
            </>
          )}

        </Space>
      </div>
    </Header>
  );
};
