import { noInfoString } from '../app/consts/tooltip';
import { ListData, ListRequest, ListResponse } from '../models/base/list';
import { Person } from '../models/person';
import { PersonFind, PersonFindViewModel, PersonSearchResult, personFindToViewModel } from '../models/personSearchResult';
import { baseProtectedApi } from './baseProtectedApi';
import { ApiResponse } from './models/response';
import { formatDateTime, fullSitePath } from './models/util';

const url = 'person';

const personApi = baseProtectedApi.injectEndpoints({
  endpoints: build => ({
    getPersonList: build.query<ListData<PersonSearchResult>, ListRequest>({
        query: (params) => ({method: 'POST', url: `${url}/list/`, body: params }),
        transformResponse: (response:ListResponse<PersonSearchResult>, meta, arg) =>{
            response.result.items.forEach(i => {
                i.birth = i.birth? formatDateTime(i.birth): noInfoString;
                i.death = i.death? formatDateTime(i.death): noInfoString;
                return i;
            })
            return   {
                items: response.result.items,
                total: response.result.total
            }
        },
    }),
    getPerson :  build.query<Person, number>({
        query: (id) => `${url}/${id}`,
        transformResponse: (response: ApiResponse<Person>) => {
            if (!response.result.canEdit) response.result.canEdit = false;
            if (response.result.image) response.result.image = fullSitePath(response.result.image);
            if (response.result.attachedFile) response.result.attachedFile = fullSitePath(response.result.attachedFile);
            if (response.result.birth) response.result.birth = formatDateTime(response.result.birth) 
                else response.result.birth = noInfoString;
            if (response.result.death) response.result.death = formatDateTime(response.result.death)
                else response.result.death = noInfoString;
            return response.result;
        }
    }), 
    getGeneralPersonList: build.query<ListData<PersonFindViewModel>, ListRequest>({
        query: (params) => ({method: 'POST', url: `${url}/generallist/`, body: params }),
        transformResponse: (response:ListResponse<PersonFind>, meta, arg) =>{
            const items = response.result.items.map(i => personFindToViewModel(i));
            return   {
                items: items,
                total: response.result.total
            }
        },
        providesTags: (result) => result?.items
        ? [
            ...result.items.map(({ id }) => ({ type: 'FavoritePersons' as const, id })),
            { type: 'FavoritePersons', id: 'LIST' },
          ]
        : [{ type: 'FavoritePersons', id: 'LIST' }],
        
    }),
  }),
  overrideExisting: true
});

export const { 
    useGetPersonListQuery, 
    useGetPersonQuery ,
    useGetGeneralPersonListQuery
} = personApi;
