import { Button, Form, Input, Spin } from "antd";
import notificationService from "../../../app/toast/notificationService";
import styles from '../Admin.module.css';
import { useGetCityQuery, useUpdateCityMutation } from "../../../services/city";

export const UpdateCity = (props: {id: number, onCancel: ()=>void}) => {
    const {id, onCancel} = props;
    const {data, isLoading} = useGetCityQuery(Number(id));
    const [updateCity, {isLoading: isUpdating}] = useUpdateCityMutation();

    const updateItem = async({name}: {name: string})=>{
        if (Number(id))
            await updateCity({id: Number(id), data:name})
            .then(()=>{
                close();
            }).catch( (e) => {
                notificationService.error(`Ошибка ${e}`);
            });
    }
    const close = () => {onCancel();}

    if (isLoading) return <Spin />;
    if (!data) return <div>Местонахождение не найдено</div>;
    return (
    <div className={styles.editContainer}>
        <Form onFinish={updateItem} initialValues={data} className={styles.editForm} >
            <Form.Item name="name" rules={[{required: true }]}>
                <Input />
            </Form.Item>
            <Button type="link" htmlType="submit" loading={isUpdating}>Сохранить</Button>
            <Button type="link" onClick={close}>Отменить</Button>
        </Form>
    </div>
    )
}