import { useState } from "react";
import AppCard from "../../../app/base/card";
import AppList from "../../../app/base/list";
import { ListRequest, PagingInfo, buildListRequest, initialFilterParams } from "../../../models/base/list";
import { useDeleteCityMutation, useGetCityListQuery } from "../../../services/city";
import { UpdateCity } from "./UpdateCity";
import { City } from "../../../models/city";

export const CityList = ()=>{
    const [editingKey,setEditingKey] = useState<number>();
    const [searchParams, setSearchParams] = useState<ListRequest>(initialFilterParams);
    const { data, isLoading } = useGetCityListQuery(searchParams);

    const fetch = ({page, pageSize}:PagingInfo)=>{
        setSearchParams(buildListRequest(page,pageSize));
    }

    const [deleteCity, {isLoading: isDeleting}] = useDeleteCityMutation();
    const deleteItem = async(id: number)=>{
        await deleteCity(id);
    }

    const edit = (id: number)=>{
        setEditingKey(id);
    }

    const ListItem = (props: City)=>{
        return <div>{props.name} </div>
    }

    return( 
        <AppCard title="Список местонахождений кладбищ">
            <AppList 
                isLoading={isLoading}
                data={data?.items || []}
                totalItems={data?.total || 0}
                fetchData = {fetch}
                renderItem={ (item, i)=> item.id==editingKey?<UpdateCity id={item.id} onCancel={()=>setEditingKey(undefined)}/>:<ListItem {...item} key={i}/>}
                renderActions={(item)=> item.id==editingKey? []: [
                    <a key="list-loadmore-edit" onClick={()=> edit(item.id)}>редактировать</a>,
                    <a key="list-loadmore-edit" onClick={()=> deleteItem(item.id)}>удалить</a>
                ]
                }
            />
        </AppCard>
    )
}