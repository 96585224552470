import { noInfoString } from "../app/consts/tooltip";
import { Gender } from "./gender";
import { IFio } from "./user";


export interface IPersonFIOStyle {first: string|undefined, middle:string|undefined, last: string|undefined, lastreplaced: string|undefined};
export interface IPersonLifeFrameStyle {birth: string|undefined, death:string|undefined};

/** строка - ФИО */
export function getUserName<T extends IFio> (data: T | undefined):string {
  if (!data) return '';
  return `${data.lastName || ''} ${data.firstName || ''} ${data.middleName || ''} ${data.lastNameReplaced?'('+data.lastNameReplaced+')':''}`;
}

/** строка - ФИО пользователя в скрытом виде */
export function getUserAsHidden <T extends IFio> (data: T | undefined):string {
  if (!data) return '';
  return `${data.firstName || ''} ${data.lastName?.substring(0,1).toUpperCase() || ''} `;
}

/** строка - пол пользователя */
export function getUserGenderString(gender: number|undefined): string {
  return !gender?noInfoString:gender===Gender.Male?'муж.':'жен.'
}

/** строка - возраст */
export function getUserAge (age: number | undefined): string {
  if (!age) return '';
  let str = 'лет';
  if (age > 0){
    if (age == 1) { str = 'год'}
    else {
        if (age >= 2 && age <=4) 
            str = 'года'
        else {
            if (age>20){
                switch((age+'').slice(-1)) {
                    case '1': str = 'год'; break;
                    case '2': 
                    case '3':
                    case '4': str = 'года'; break;  
                }  
            }
        }
    }
  }

  return `(${age} ${str})`;
}
