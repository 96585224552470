import AppCard from "../../../app/base/card"
import { CityList } from "./CityList"
import { CreateCity } from "./CreateCity"

export const AdminCityManager = ()=>{
    return (<>
        <AppCard title='Управление справочником метонахождений'>
            <CreateCity/>
            <hr />
            <CityList />
        </AppCard>
    </>)
}