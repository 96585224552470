import { baseProtectedApi } from './baseProtectedApi';
import { ListData, ListRequest, ListResponse } from '../models/base/list';
import { ApiResponse } from './models/response';
import { formatDateTime } from './models/util';
import { FavoritePerson } from '../models/favoritePerson';
import { PersonFind, personFindToViewModel } from '../models/personSearchResult';

const url = 'favoritePerson';


const favoritePersonApi = baseProtectedApi.injectEndpoints({
  endpoints: build => ({
    getFavoritePersons: build.query<ListData<FavoritePerson>, ListRequest>({
        query: (params) => ({method: 'POST', url: `${url}/list/`, body: params }),
        transformResponse: (response:ListResponse<PersonFind>, meta, arg) =>{
            const items = response.result.items.map(i => personFindToViewModel(i));
            return   {
                items: items,
                total: response.result.total
            }
        },       
        providesTags: (result) => result?.items
        ? [
            ...result.items.map(({ id }) => ({ type: 'FavoritePersons' as const, id })),
            { type: 'FavoritePersons', id: 'LIST' },
          ]
        : [{ type: 'FavoritePersons', id: 'LIST' }],
      }),
    addFavoritePerson: build.mutation<{ success: boolean }, number>({
        query(param) {
          return {
            url: `${url}/add/${param}`,
            method: 'POST'
          }
        },
        invalidatesTags: ['FavoritePersons']
    }),
    removeFavoritePerson: build.mutation<{ success: boolean }, number>({
        query(param) {
          return {
            url: `${url}/remove/${param}`,
            method: 'DELETE',
          }
        },
        invalidatesTags: ['FavoritePersons']
    }),
    
  }),
  overrideExisting: true
});

export const { 
    useAddFavoritePersonMutation,
    useRemoveFavoritePersonMutation,
    useGetFavoritePersonsQuery
 } = favoritePersonApi;

