import { Button, Form, Input } from "antd";
import AppCard from "../../../app/base/card";
import { NewsCreateUpdateModel } from "../../../models/news"
import { useCreateNewsItemMutation } from "../../../services/news"
import styles from './CreateNews.module.css';

export const CreateNewsItem = () => {
    const [form] = Form.useForm();
    
    const [createNews, { isLoading }] = useCreateNewsItemMutation()
  

    const handleSubmit = async (values: NewsCreateUpdateModel) => {
      await createNews(values);
      form.resetFields();
    }
  
    return (
        <AppCard title="Добавить новость" className={styles.newItemContainer}>
            <Form form={form} onFinish={handleSubmit}>
                <Form.Item label='Текст' name="content" rules={[{required: true, message: 'Введите текст новости.'}]}>
                    <Input.TextArea />
                </Form.Item>
                <Form.Item>
                    <Button htmlType="submit" loading={isLoading}>Сохранить</Button>
                </Form.Item>
            </Form>
      </AppCard>
    )
  }
  