import { RcFile, UploadFile } from "antd/es/upload";
import { IItemEntity } from "../app/base/list/ListItemAction";
import { LocationRegExp } from "../app/consts/location";
import { hashtagRegExp } from "../app/consts/tag";
import { fileNameFromPath, fullSitePath } from "../services/models/util";
import { UserRights } from "./base/userRights";
import { Person, PersonCreateModel, personToCreateModel, personToViewModel, PersonViewModel } from "./person";
import { Service } from "./service";
import { EmptyUser, User } from "./user";
import { noInfoString } from "../app/consts/tooltip";

export interface Location {
    lat: number;
    lon: number;
}

export interface BurialUser {
    isOwner: boolean,
    user: User;
}

export interface Burial extends IItemEntity, UserRights {
    name?: string;
    locationNumber?: string;
    cemeteryName?: string;
    cityName?: string;
    // cemetery?: Cemetery;
    location?: Location;
    image?: string;
    note?: string;
    attachedFile?: string;
    persons?: Person[];
    services? : Service[];
    createdByUser: User;
    createdTime: string;
    updatedTime?: string;
    tags?: string[];
    burialUsers? : BurialUser[];
    own?: boolean;
    hasUncommitedChanges: boolean;
    published?: boolean;
}

export const EmptyBurial: Burial = {
    id: 0,
    type: 'entity',
    createdByUser: EmptyUser,
    createdTime: Date.now.toString(),
    canEdit: false,
    hasUncommitedChanges: false,
}

export interface BurialViewModel extends IItemEntity, UserRights{
    name?: string;
    locationNumber?: string;
    cemeteryName?: string;
    cityName?: string;
    location?: Location;
    locationAsString?: string;
    image?: string;
    note?: string;
    attachedFile?: string;
    persons?: PersonViewModel[];
    services? : Service[];
    createdByUser: User;
    createdTime: string;
    updatedTime?: string;
    tags?: string[];
    burialUsers? : BurialUser[];
    own?: boolean;
    hasUncommitedChanges: boolean;
    published?: boolean;
}

export interface BurialCreateModel {
    name?: string;
    locationNumber?: string;
    cemeteryName?: string;
    cityName?: string;
    location?: Location;
    note?: string;
    // image?: RcFile;
    // attachedFile?: RcFile;
    tags?: string[];
    locationAsString?: string;
    tagsAsString?: string;
    persons?: PersonCreateModel[];
    image?: UploadFile[];
    attachedFile?: UploadFile[];
}

export const StrToLocation = (input: string): Location | undefined => {
    if (!input) return undefined;
    const res = input.match(LocationRegExp);
    if (!res) return undefined;
    return {
        lat: Number(res[1]),
        lon: Number(res[4])
    }
}
export const LocationToStr = (location: Location | undefined):string => {
    return location?`${location.lat}, ${location.lon}`: noInfoString;
}

export const StrToTags = (input: string|undefined): string[] | undefined => {
    if (!input) return undefined;
    const res = hashtagRegExp.test(input);
    return input.split(' ');
}
export const toBurialCreateModel = (data: Burial): BurialCreateModel => {
    return {
        name : data.name,
        locationNumber: data.locationNumber,
        cemeteryName : data.cemeteryName,
        cityName: data.cityName,
        location: data.location,
        locationAsString: data.location? LocationToStr(data.location): undefined,
        note: data.note,
        tagsAsString: data.tags?.join(' '),
        tags: data.tags,
        persons: data.persons?.map(i=> personToCreateModel(i)),
        image: data.image? [{uid:'-1', url: fullSitePath(data.image), name: data.image, status: 'done'}]: undefined,
        attachedFile: data.attachedFile? [{uid: '-2', url: fullSitePath(data.attachedFile), name: fileNameFromPath(data.attachedFile), status: 'done'}] : undefined
    }
}
export const toBurialViewModel = (data: Burial): BurialViewModel => {
    const res:BurialViewModel = {
        type: 'entity',
        id: data.id,
        name: data.name,
        locationNumber: data.locationNumber || noInfoString,
        tags: data.tags,
        cityName: data.cityName|| noInfoString,
        cemeteryName: data.cemeteryName|| noInfoString,
        location: data.location,
        locationAsString: LocationToStr(data.location),
        note: data.note,
        image: fullSitePath(data.image),
        attachedFile: fullSitePath( data.attachedFile),
        createdByUser: data.createdByUser,
        createdTime: data.createdTime,
        persons: data.persons?.map(p=> personToViewModel(p)),
        canEdit: data.canEdit,
        services: data.services,
        burialUsers: data.burialUsers,
        own: data.own,
        hasUncommitedChanges: data.hasUncommitedChanges,
        published: data.published,
    };
    return res;
}