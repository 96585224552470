import AppList from "../../app/base/list/ListNew";
import { IItemEntity, ItemAction } from "../../app/base/list/ListItemAction";
import { FilterType } from "../../models/base/filter";
import { buildListRequest, initialFilterParams, ListRequest, PagingInfo } from "../../models/base/list";
import { getPageData } from "../mock/mockData";
import { useState } from "react";
import { News } from "../../models/news";
import AppCard from "../../app/base/card";
import { useGetNewsListQuery } from "../../services/news";
import styles from './News.module.css';

const params: ListRequest = {
    paging: {start: 0, limit: 10},
    filter: []
}

export const NewsList = ()=>{
    const [searchParams, setSearchParams] = useState<ListRequest>(params);
    const {data: news, isLoading } = useGetNewsListQuery(searchParams);
    
    const fetch = ({page, pageSize}:PagingInfo)=>{
        setSearchParams(buildListRequest(page,pageSize));
      }
    const NewsItem = (item: News, index: number) => {
        return <div className={styles.newsItem}>
            <div>{item.createdTimeStamp}</div>
            <div>{item.name}</div>
        </div>
    } 

    return (
        <AppCard title="Новости">
            <AppList
                pageSize={10}
                isLoading = {isLoading}
                fetchData={fetch}
                data = {news?.items || []}
                totalItems={news?.total || 0}
                // renderActions={(item)=>[ <ItemAction item={item} title={'посмотреть запись'} onClick={()=>{}} />]}
                renderItem={NewsItem}
            />
        </AppCard>
    )
}