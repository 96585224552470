import { useState } from "react";
import { ListRequest, PagingInfo, buildListRequest, initialFilterParams } from "../../models/base/list";
import { useConfirmGroupRequestMutation, useGetBurialGroupRequestsQuery, useRejectGroupRequestMutation } from "../../services/burialGroup";
import AppCard from "../../app/base/card";
import AppList from "../../app/base/list";
import { OwnedGroupRequestListItem } from "./OwnedGroupRequestListItem";
import { Button } from "antd";

export const OwnedGroupRequestList = ()=>{
    const [searchParams, setSearchParams] = useState<ListRequest>(initialFilterParams);
    const {data:requests, isLoading} = useGetBurialGroupRequestsQuery(searchParams);
    const [confirmRequest, {isLoading: isConfirming}] = useConfirmGroupRequestMutation();
    const [rejectRequest, {isLoading: isRejecting}] = useRejectGroupRequestMutation();
    const fetch = ({page, pageSize}:PagingInfo)=>{
        setSearchParams(buildListRequest(page,pageSize));
    }
    const confirm = (id: number)=>{
        confirmRequest(id);
    }
    const reject = (id: number)=>{
        rejectRequest(id);
    }
    
    return (<>
        <AppCard title="Запросы в мои группы">
            <AppList
                isLoading={isLoading}
                showFilter = {false}
                data={requests?.items || [] }
                totalItems={requests?.total || 0}
                renderItem={(item, index) => (<OwnedGroupRequestListItem key={index} {...item} />)}
                fetchData = {fetch}
                renderActions={(item)=>  
                   
                [ 
                    <Button disabled={ typeof item.granted !=='undefined' } type="link" loading={isConfirming} onClick={()=>confirm(item.id)}>принять</Button>,
                    <Button disabled={ typeof item.granted !=='undefined' } type="link" loading={isRejecting} onClick={()=>reject(item.id)}>отклонить</Button>
                ]
                }
                showTotal = {true}
            ></AppList>
        </AppCard>
    </>)
}