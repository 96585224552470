import { Spin } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import notificationService from "../../../app/toast/notificationService";
import { useGetBurialQuery } from "../../../services/burial";
import { useUpdateBurialMutation } from "../../../services/ownedBurial";
import { BurialForm } from "./BurialForm";
import { Burial } from "../../../models/burial";

export const BurialEdit = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { state } = useLocation();
    const {data, isLoading, error} = useGetBurialQuery(Number(id));
    const [updateBurial, {isLoading: isUpdating}] = useUpdateBurialMutation();

    if (isLoading) {
        return <div><Spin size="large" /></div>
    }
    if (!data) {
        return <div>Запрошенное Захоронение не найдено</div>
    }
    const update = async(data: FormData) => {
        if (id){
            let navId: number|undefined = undefined;
            const saveResult = await updateBurial({id: Number(id), data}).unwrap();
                if (state && state.from && state.from)
                    {
                        navId = saveResult.id;
                    }
                notificationService.info('Изменения будут доступны после проверки модератором')
            if (navId) navigate(`/ownedburial/preburial/${navId}`);
            else navigate(-1);
        }
    }

    return (
        <BurialForm formTitle="Редактировать захоронение" data={data} isLoading={isLoading || isUpdating} onSubmit={update}/>
    )
}