import { Button, Col, DatePicker, Form, Input, InputRef, Popover, Radio, Row, Select, Space, Spin, Tag, Tooltip, Upload } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import stylesForm from './BurialForm.module.css';
import AppCard from "../../../app/base/card";
import { FileUpload } from "../../../app/base/upload/FileUpload";
import { Gender } from "../../../models/gender";
import { IBurialFormProps } from "./types";
import { PlusOutlined, DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Burial, BurialCreateModel, LocationToStr, StrToLocation, StrToTags, toBurialCreateModel, Location, EmptyBurial, BurialViewModel, toBurialViewModel } from "../../../models/burial";
import { LocationRegExp } from "../../../app/consts/location";
import { useEffect, useRef, useState } from "react";
import { RcFile } from "antd/es/upload";
import { BurialCreateModelToBurialPreview, BurialModelToFormData } from "./util";
import 'dayjs/locale/ru';
import { dateFormat } from "../../../models/base/format";
import Map from '../map';
import { hashtagRegExp } from "../../../app/consts/tag";
import { useGetCityListQuery } from "../../../services/city";
import { ListRequest } from "../../../models/base/list";
import type { SelectProps } from 'antd';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { BurialChangesPreview } from "../changesPreview/BurialChangesPreview";
import { useAuth } from "../../../app/hooks";


const params: ListRequest = {
    paging: {start: 0, limit: 100},
    filter: []
}

export const BurialForm = (props : IBurialFormProps) =>{
    const navigate = useNavigate();
    const user = useAuth();
    const { id } = useParams();

    const {data, formTitle = 'Захоронение', onSubmit, isLoading} = props;
    const [form] = Form.useForm();
    const [mapIsOpen, setMapIsOpen] = useState(false);
    const [dataModel, setDataModel] = useState<BurialCreateModel>();
    const [hasChanges, setHasChanges] = useState(false);
    const [ages, setAges] = useState<(number|undefined)[]>([]);
    
    const [searchParams, setSearchParams] = useState<ListRequest>(params);
    const {data: cities, isSuccess: isCitiesFetchSuccess} = useGetCityListQuery(searchParams);
    const [options, setOptions] = useState<SelectProps['options']>([]);
    const [isPreview, setIsPreview] = useState<boolean>(false);
    const [previewBurial, setPreviewBurial] = useState<BurialViewModel>();

    useEffect(()=>{
        cities?.items && setOptions(cities?.items.map(i=> {return {value:i.name, label: i.name}}));
    },[isCitiesFetchSuccess]);
    
    const save = (values: BurialCreateModel)=>{
        const formData = BurialModelToFormData(values);
        onSubmit(formData);
    }
    const formItemLayout = {
        labelCol: { span: 12 },
        wrapperCol: { span: 14 },
    };

    const normFile = (e: any) => {if (Array.isArray(e)) return e; return e && e.fileList;};
    // const dataModel = toBurialCreateModel(data || EmptyBurial);
    useEffect(()=>{
        if (data){
            setDataModel( toBurialCreateModel(data));
            data && setPreviewBurial(toBurialViewModel(data));
            if (data.persons)
                setAges( data.persons?.map(i=>i.age));
        }
        else {
            setDataModel({}as BurialCreateModel) ;
            setAges([]);
        }        
    }, [data])
    
    const showMap = () => {
        setMapIsOpen(true);
    }
    const hideMap = () =>{
        setMapIsOpen(false);
    }
    const setLocationOfMap = (l: Location) => {
        form.setFieldsValue({ locationAsString: LocationToStr(l) });
        checkForChanges();
    }
    const checkForChanges = () => {
        const currentValue_JSON = JSON.stringify(form.getFieldsValue());
        const formData_JSON = JSON.stringify(dataModel);
        setHasChanges(currentValue_JSON != formData_JSON);
    };
    const onFormValuesChange = (changedValues: any, allValues: BurialCreateModel) => {
        if (changedValues.hasOwnProperty('persons') && changedValues.persons.constructor == Array){
            const persons = Array.from(changedValues.persons);
            persons.forEach((i:any, index)=>{
                if (i) if (i.hasOwnProperty('birth') || i.hasOwnProperty('death')){
                    if (allValues.persons && allValues.persons[index].death && allValues.persons[index].birth){
                        const age = allValues.persons[index].death?.diff(allValues.persons[index].birth, 'y');
                        const a = [...ages];
                        a[index] = age;
                        setAges(a);                    
                    }
                }
            })
        }
        if (changedValues.hasOwnProperty('locationAsString')){
            const l = StrToLocation(changedValues.locationAsString);
            const data = {...dataModel};
            data.location = l;
            setDataModel(data);
        }
        checkForChanges();
    }

    const tagRender = (props: CustomTagProps) => {
        const { label } = props;
        return (<Input ref={inputRef} bordered={false} value={label?.toString()} style={{paddingLeft: '6px'}}></Input>);
    };

    const openPreview = ()=>{
        if (!user) return;
        setIsPreview(true);
        const burialData: BurialCreateModel = { ...form.getFieldsValue()};
        burialData.persons?.forEach((i,idx)=>i.age = ages[idx]);
        setPreviewBurial(BurialCreateModelToBurialPreview(burialData, data, user));
    }
    const inputRef = useRef<InputRef>(null);

    if (props.isLoading)
        return <Spin size="large"/>
    return (
        dataModel?
        <AppCard title={formTitle}>
            <Form id='burialForm' onFinish={save} form={form} initialValues={dataModel} {...formItemLayout} onValuesChange={onFormValuesChange}>
                <Row gutter={[16, 16]}>
                    <Col span={8}>                        
                        <Form.Item name="name" label="Наименование" rules={[{required: true, message: 'Введите название. (Оно будет являться названием группы)'}]}>
                            <Input suffix={<Tooltip title="Название захоронения"><InfoCircleOutlined className={stylesForm.toolTipInfo} /></Tooltip> } />
                        </Form.Item>
                        <Form.Item name="locationNumber" label="Номер участка">
                            <Input />
                        </Form.Item>
                        {/* <Form.Item name="tagsAsString" label="Теги" rules={[
                            {pattern: hashtagRegExp, message: 'строка тегов формата #tag через пробел'}
                        ]}>
                            <Input 
                                placeholder="Например,  #1990 #школа№4"
                                suffix={<Tooltip title="Представляет собой слово или объединение слов (без пробелов), которому предшествует символ #, для облегчения поиска данных по теме"><InfoCircleOutlined className={stylesForm.toolTipInfo} /></Tooltip> }
                                />
                        </Form.Item> */}
                        <Form.Item name="image" label="Фотография" valuePropName="fileList" getValueFromEvent={normFile}>
                            <FileUpload />
                        </Form.Item>
                        <Form.Item name="cityName" label="Населенный пункт">
                            <Select mode="tags" showSearch options={options}     
                                filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase().includes(input.toLowerCase())}
                                onChange={value => {
                                    if (value?.length > 1) {
                                      // if you want only one element :).
                                      value.shift();
                                    }
                                  }}
                                // tagRender={tagRender}
                             />
                        </Form.Item>
                        <Form.Item name="cemeteryName" label="Кладбище">
                            <Input />
                        </Form.Item>
                        <Form.Item name="locationAsString" label="Координаты:"
                            rules={[
                            {pattern: LocationRegExp, message: 'Два числа через запятую'}
                        ]}>
                            <Input               
                            suffix={
                                <Tooltip title="Например, 57.043999, 41.036999">
                                <InfoCircleOutlined className={stylesForm.toolTipInfo} />
                                </Tooltip>
                                }/>
                        </Form.Item>
                        <Form.Item className={stylesForm.tempFormItem}>
                            <Popover 
                                open={mapIsOpen}
                                onOpenChange={showMap}
                                content={<Map location={dataModel.location} burialName={dataModel.cemeteryName} onLocationChanged={setLocationOfMap} onClose={hideMap} />} 
                                trigger="click">
                                <Button type="link">указать на карте</Button>
                            </Popover>
                        </Form.Item>
                        <Form.Item name="note" label="Примечание:"> 
                            <Input.TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
                        </Form.Item>
                        <Form.Item name="attachedFile" label="Файл:" valuePropName="fileList" getValueFromEvent={normFile}>
                            <FileUpload description="Добавить файл" />
                        </Form.Item>
                    </Col>
                    <Col span={16} >
                        <Row className={stylesForm.colTitle}>
                            <div className={`${stylesForm.section}`}>Персоны</div>
                        </Row>
                            <Form.List name="persons">
                                {(fields, { add, remove }) => (
                                    <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Row key={key} className={stylesForm.personCard}>
                                            <Col span={12}>

                                                           <Form.Item name={[name, 'lastName']} label="Фамилия">
                                                                <Input />
                                                            </Form.Item> 
                                                            <Form.Item name={[name, 'lastNameReplaced']} label="Фамилия, если менялась">
                                                                <Input />
                                                            </Form.Item> 
                                                            <Form.Item name={[name, 'firstName']} label="Имя">
                                                                <Input />
                                                            </Form.Item>        
                                                            <Form.Item name={[name, 'middleName']} label="Отчество">
                                                                <Input />
                                                            </Form.Item>    
                                                             <Form.Item name={[name, 'gender']} label="Пол">
                                                                <Radio.Group optionType="button" options={[{label: 'Муж', value:Gender.Male},{label:'Жен', value:Gender.Female}]}>
                                                                </Radio.Group>
                                                            </Form.Item>
                                                            <Form.Item name={[name, 'note']} label="Примечание">
                                                                <Input.TextArea maxLength={50} showCount/>
                                                            </Form.Item>
                                                    <Form.Item name={[name, 'id']} hidden><Input /></Form.Item>
                                
                                            </Col>
                                            <Col span={12}>

                                                            <Form.Item name={[name, 'birthPlace']} label="Место рождения">
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item name={[name, 'birth']} label="Дата рождения">
                                                                <DatePicker format={dateFormat} />
                                                            </Form.Item>                                        
                                                            <Form.Item name={[name, 'death']} label="Дата смерти">
                                                                <DatePicker format={dateFormat} />
                                                            </Form.Item>
                                                            <Form.Item label="Возраст">
                                                                <Input value={ages && ages[name]} disabled/>
                                                            </Form.Item>
                                                       <Form.Item name={[name, "tagsAsString"]} label="Теги" rules={[
                            {pattern: hashtagRegExp, message: 'строка тегов формата #tag через пробел'}
                        ]}>
                            <Input 
                                placeholder="Например,  #1990 #школа№4"
                                suffix={<Tooltip title="Представляет собой слово или объединение слов (без пробелов), которому предшествует символ #, для облегчения поиска данных по теме"><InfoCircleOutlined className={stylesForm.toolTipInfo} /></Tooltip> }
                                />
                        </Form.Item>
                                                        <div className={stylesForm.attachment}>
                                                            <Form.Item name={[name, 'image']} label="Фото" valuePropName="fileList" getValueFromEvent={normFile}>
                                                                <FileUpload />
                                                            </Form.Item>
                                                            <Form.Item name={[name, 'attachedFile']} label="Файл" valuePropName="fileList" getValueFromEvent={normFile}>
                                                                <FileUpload description="Добавить файл" />
                                                            </Form.Item>
                                                        </div>

                                            </Col>
                                            <Button icon={<DeleteOutlined />} onClick={() => remove(name)}>Удалить</Button>
                                        </Row>
                                    ))}
                                    <Row className={stylesForm.colTitle}>

                                        
                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>Добавить персону</Button>
                                        
                                    </Row>
                                    </>
                                )}
                            </Form.List>
                            
                        
                    </Col>
                </Row>
                <Row className={stylesForm.buttons}>
                    <Col>
                        <Button className="commonButton" htmlType="submit" disabled={!hasChanges}>Сохранить</Button>
                    </Col>
                    <Col>
                        <Button onClick={()=>navigate(-1)}>Отмена</Button>
                    </Col>
                    <Col>
                        <Button onClick={openPreview}>Предпросмотр</Button>
                    </Col>
                </Row>
            </Form>
            <BurialChangesPreview burial={previewBurial} open={isPreview} onClose={()=>setIsPreview(false)}/>
        </AppCard>
        : isLoading? <Spin></Spin>: <></>
    )
}