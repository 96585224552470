import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';
import { User } from '../../models/user';
import { getUserFromSessionStorage } from './useUserSessionStorage';

type AuthState = {
  user: User | null;
  token: string | null;
};
const initialState: AuthState = {
  user: getUserFromSessionStorage(),
  token: sessionStorage.getItem('token')
}

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, { payload: { user, token } }: PayloadAction<{ user: User; token: string }>) => {
      state.user = user;
      state.token = token;
      sessionStorage.setItem('user', JSON.stringify(user));
      sessionStorage.setItem('token', token);
    },
    logOut: state => {
      state.user = null;
      state.token = null;
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('token');
    },
    updateProfile: (state, { payload }: PayloadAction<User>) => {
      state.user = payload;
      sessionStorage.setItem('user', JSON.stringify(payload));
    }
  }
});

export const { setCredentials, logOut, updateProfile } = slice.actions;

export default slice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth.user;
