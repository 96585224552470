import { baseProtectedApi } from './baseProtectedApi';

export interface askSupportRequest {
  userName: string;
  userEmail: string;
  message: string;
}

const url = 'support';

export const supportApi = baseProtectedApi.injectEndpoints({
  endpoints: builder => ({
    askSupport: builder.query<void, askSupportRequest>({
      query: (data) => ({
        url: `${url}/message`,
        method: 'POST',
        body: data
      }),
    }),

  })
});

export const { 
  useLazyAskSupportQuery
 } = supportApi;
