import { Burial, BurialViewModel, LocationToStr, toBurialViewModel } from '../models/burial';
import { baseProtectedApi } from './baseProtectedApi';
import { ListData, ListRequest, ListResponse } from '../models/base/list';
import { ApiResponse } from './models/response';

const url = 'burial';

const burialApi = baseProtectedApi.injectEndpoints({
  endpoints: build => ({
    getBurials: build.query<ListData<BurialViewModel>, ListRequest>({
        query: (params) => ({method: 'POST', url: `${url}/list/`, body: params }),
        transformResponse: (response:ListResponse<Burial>, meta, arg) =>{
            const items = response.result.items.map(i=>toBurialViewModel(i));
            return   {
                items,
                total: response.result.total
            }
        }
      }),
    getBurial: build.query<Burial, number>({
        query: (id) => `${url}/${id}`,
        transformResponse: (response: ApiResponse<Burial>) => {
            // response.result.image = fullSitePath(response.result.image);
            // response.result.persons?.forEach(p=> transformPerson(p));
            // response.result.attachedFile = fullSitePath( response.result.attachedFile);
            // response.result.cityName = response.result.cityName || noInfoString;
            // response.result.cemeteryName = response.result.cemeteryName || noInfoString;
            // response.result.locationAsString = LocationToStr(response.result.location);

            return response.result;
        }
    }), 
    // updateBurial: build.mutation<Burial, Partial<Burial>>({
    //     query(data) {
    //       const { id, ...body } = data
    //       return {
    //         url: `${url}/${id}`,
    //         method: 'PUT',
    //         body,
    //       }
    //     },
    //   }),
    // deleteBurial: build.mutation<{ success: boolean; id: number }, number>({
    //     query(id) {
    //       return {
    //         url: `${url}/${id}`,
    //         method: 'DELETE',
    //       }
    //     },
    //   }),
    // createBurial: build.mutation<Burial, Partial<Burial>>({
    //     query(data) {
    //       return {
    //         url: `${url}`,
    //         method: 'POST',
    //       }
    //     },
    //   }),
  }),
  overrideExisting: true
});

export const { 
    useGetBurialQuery,
    useGetBurialsQuery,
    useLazyGetBurialQuery
 } = burialApi;

export const {
    endpoints: { getBurials, getBurial}
} = burialApi;