import { Burial, BurialViewModel, toBurialCreateModel, toBurialViewModel } from '../models/burial';
import { personToViewModel } from '../models/person';
import { baseProtectedApi } from './baseProtectedApi';
import { ListData, ListRequest, ListResponse } from '../models/base/list';
import { ApiResponse } from './models/response';
import { BurialModifiedFields } from '../models/burialModifiedFields';

const url = 'moderation';

const burialApi = baseProtectedApi.injectEndpoints({
  endpoints: build => ({
    getPreBurials: build.query<ListData<BurialViewModel>, ListRequest>({
        query: (params) => ({method: 'POST', url: `${url}/list/`, body: params }),
        transformResponse: (response:ListResponse<Burial>, meta, arg) =>{
            const items = response.result.items.map(i=>toBurialViewModel(i));
            items.forEach(i => {
                i.persons?.forEach(p=> personToViewModel(p))
            });
            return   {
                items,
                total: response.result.total
            }
        }
      }),
    getPreBurial: build.query<Burial, number>({
        query: (id) => `${url}/${id}`,
        transformResponse: (response: ApiResponse<Burial>) => {
            return response.result;
        }
    }),   
    publishBurial: build.mutation<{ success: boolean }, number>({
        query(id) {
          return {
            url: `${url}/publish/${id}`,
            method: 'POST',
          }
        },
    }),
    rejectBurial: build.mutation<{ success: boolean }, number>({
        query(id) {
          return {
            url: `${url}/reject/${id}`,
            method: 'POST',
          }
        },
    }),
    getPreBurialModifications: build.query<BurialModifiedFields, number>({
      query: (id) => `${url}/compare/${id}`,
      transformResponse: (response: ApiResponse<BurialModifiedFields>) => {
          return response.result;
      }
    }),   
  }),
  overrideExisting: true
});

export const { 
    useGetPreBurialsQuery, usePublishBurialMutation, useGetPreBurialQuery, useRejectBurialMutation,
    useLazyGetPreBurialModificationsQuery, useGetPreBurialModificationsQuery
 } = burialApi;

