import { FilterType } from "../../models/base/filter";

export const FormValuesToServiceFilterType = (formValues: any): FilterType => {
    const values = {...formValues};
    if (values.createDatePeriod) {
        if (values.createDatePeriod[0])
          values.creationDateFrom = values.createDatePeriod[0].format("DD.MM.YYYY");
        if (values.createDatePeriod[1])
          values.creationDateTo = values.createDatePeriod[1].format("DD.MM.YYYY");
      }
    delete values['createDatePeriod'];

    return values;
}