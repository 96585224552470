import { User } from "../../models/user";

export const getUserFromSessionStorage = ():User|null => {
    const ssu = sessionStorage.getItem('user');
    if (ssu && typeof ssu === 'string') 
    try {
        return JSON.parse(ssu) as User;
    }
    catch {return null;}
    return null;
  }
