import { useEffect } from 'react';

import { INotificationItem, NotificationType } from './notificationModel';
import notificationService from './notificationService';
import { notification } from 'antd';
import { NotificationPlacement } from 'antd/es/notification/interface';

const NotificationContainer = () => {
  const [api, contextHolder] = notification.useNotification();

  /** Показать уведомление */
  const openNotificationWithIcon = (type: NotificationType, message: string, placement?: NotificationPlacement) => {
    api[type]({
      duration: 3,
      placement: placement || 'top',
      message: message
    });
  };

  const notify = (notification: INotificationItem) => {
    openNotificationWithIcon(notification.type, notification.message);
  };

  useEffect(() => {
    notificationService.addNotificationCreatedListener(notify);
    return () => notificationService.removeNotificationCreatedListener(notify);
  }, []);

  return <>{contextHolder}</>;
};

export default NotificationContainer;
