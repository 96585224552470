import { Button, Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AppCard from '../../../app/base/card';
import MaskedInput from '../../../app/base/formInput/maskedInput/MaskedInput';
import { FileUpload } from '../../../app/base/upload/FileUpload';
import { useAuth } from '../../../app/hooks';
import { toUserViewModel, User, UserViewModel } from '../../../models/user';
import { useUpdateProfileMutation } from '../../../services/auth';
import { normFile } from '../../../services/models/util';
import { updateProfile } from '../../auth/authSlice';
import styles from './Profile.module.css';

export const Profile = () => {
  const user = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [updateUserProfile, {isLoading: isUpdating}] = useUpdateProfileMutation();
  const [userModel, setUserModel] = useState<UserViewModel>()

  const update = async(values: UserViewModel) => {
    const formData = new FormData();
    Object.entries(values).map(obj => {
      if (obj[0]=='photo' && values.photo && Array.isArray(values.photo) && values.photo.length>0){
        if (obj[1][0].originFileObj) 
            formData.append(`photo`, obj[1][0].originFileObj);
        else
          if (obj[1][0].name) formData.append(`prevPhoto`, obj[1][0].name);     
      }            
      else if ((obj[1] && obj[0]!='phoneNumber') || (obj[0]=='phoneNumber' && obj[1]!="+7(___)___ __ __")){
          formData.append(obj[0],obj[1]);
      }
    });
    const user = await updateUserProfile(formData).unwrap();
    dispatch(updateProfile(user));
    navigate('/');
  }

  useEffect(()=>{
    if (user){
        setUserModel(toUserViewModel(user));
    }         
}, [user])

  return (
    <AppCard title='Профиль'>
      {userModel && (
        <div className={styles.formWrapper}>
          <Form form={form} initialValues={userModel} onFinish={update} layout={'vertical'}>
            <Form.Item name="email" label="Логин">
              <Input disabled />
            </Form.Item>
            <Form.Item name="lastName" label="Фамилия" rules={[{required: true}]}>
              <Input />
            </Form.Item>
            <Form.Item name="firstName" label="Имя" rules={[{required: true}]}>
              <Input />
            </Form.Item>
            <Form.Item name="middleName" label="Отчество">
              <Input />
            </Form.Item>
            <Form.Item name="phoneNumber" label="Телефон">
              <MaskedInput mask={'+7(000)000 00 00'} />
            </Form.Item>
            <Form.Item name="photo" label="Фото" valuePropName="fileList" getValueFromEvent={normFile}>
              <FileUpload />
            </Form.Item>
            <div className="formRow">
              <Button htmlType='submit' className="commonButton" loading={isUpdating}>Изменить</Button>
            </div>
          </Form>
        </div>
      )}
    </AppCard>
  );
};
