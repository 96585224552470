import { Button, Input, Tooltip, Space, Form, Checkbox } from 'antd';
import { InfoCircleOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCredentials } from './authSlice';

import { useLoginMutation, UserResponse } from '../../services/auth';
import styles from './Login.module.css';
import notification from '../../app/toast/notificationService';

interface AuthValues {login: string, password: string, rememberMe: boolean};

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [login, { isLoading, error }] = useLoginMutation();

  const authenticate = async({login: username, password, rememberMe}:AuthValues)=>{
    try {
      const user = await login({login: username, password}).unwrap();
      dispatch(setCredentials(user));
      navigate('/');
    } catch (err) {
        // const error = err as  {data: {errors: {msg: string}[]}};
        // notification.error(`Ooops, error: ${error.data.errors[0].msg}`);
    }
  }

  return (
    <>
      <div className={styles.loginWrapper}>
        <h1>Выполнить вход</h1>
        <Form className={styles.loginForm} name="loginForm" onFinish={authenticate} initialValues={{rememberMe: true}}>
          <Form.Item name="login" rules={[{ required: true, message: 'Введите логин!' }]}>
            <Input 
              prefix={<UserOutlined className="site-form-item-icon" />}
              suffix={
                <Tooltip title="адрес эл. почты">
                  <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                </Tooltip>
              }
              placeholder="email"/>
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true, message: 'Введите пароль!' }]}>
            <Input.Password 
              type="password"
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="пароль"/>
          </Form.Item>
          {/* <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
          </Form.Item> */}
          <Form.Item>
               <a href='/restorePassword'>Забыли пароль?</a>
          </Form.Item>
          <Form.Item>
            <Button className='commonButton' loading={isLoading} htmlType='submit'>Войти</Button>
          </Form.Item>
          <hr role="separator" aria-orientation="horizontal" />
          <Form.Item>
              Нет аккаунта? <a href="/register">Зарегистрироваться</a>
          </Form.Item>
        </Form>        

      </div>
    </>
  );
};

export default Login;
