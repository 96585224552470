import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { FilterType, FindState, IFilter } from "../../models/base/filter";

const initialState: FindState = {
  filter: {}
};

  export const ownedBurialsSlice = createSlice({
    name: 'myBurials',
    initialState,
    reducers:{
        updateFilter: (state, action: PayloadAction<IFilter>) => {
          const filterCopy = {...state.filter};
          if (action.payload.fieldValue.trim() == '')
            delete filterCopy[action.payload.fieldName];
          else
            filterCopy[action.payload.fieldName] = action.payload.fieldValue;
          state.filter = filterCopy;
        },
        clearFilter : state => {state.filter = {}}
    }
  })

  export const {updateFilter, clearFilter} = ownedBurialsSlice.actions;
  export const selectMyBurialsFilter = (state: RootState) => state.ownedBurials.filter;

  export default ownedBurialsSlice.reducer;