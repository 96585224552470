import { Button, Row, Space, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import AppCard from '../../../app/base/card';
import AppTooltip from '../../../app/base/tooltip';
import { getUserName } from '../../../models/userUtils';
import { useGetPersonQuery } from '../../../services/person';
import PersonFamilyForm from '../../familyLink';
import styles from './PersonDetail.module.css';
import stylesCard from '../../../app/base/card/Card.module.css';
import { ToolTip_RelationLink_CanEdit } from '../../../app/consts/tooltip';


export const PersonDetail = () => {
    const navigate = useNavigate();
    const { id } = useParams<{ id: any }>();
    const {data, isLoading} = useGetPersonQuery(Number(id));

    const title =
         (<Space>
            <span className={stylesCard.pageHeader}>{`${getUserName(data)} ${data?.birth} - ${data?.death}. Нити памяти.`}</span>
            <AppTooltip title={ToolTip_RelationLink_CanEdit}/>
        </Space>)
        
    return (
        <>
        {isLoading && <Spin></Spin>}
        {data && 
            <AppCard title={title}>
                <Row className={styles.inf}>
                    <Button onClick={()=>navigate(-1)}>Назад</Button>
                    <img className={styles.pimg} src={data.image}></img>
                </Row>
                <Row className={styles.w}>
                    <PersonFamilyForm personId={Number(id)} editable={data?.canEdit || false} personName={getUserName(data)}/>                    
                </Row>

            </AppCard>
        }
        </>
    );
}