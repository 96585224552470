import { ICardProps } from './types';
import styles from './Card.module.css';

export const AppCard = (props: React.PropsWithChildren<ICardProps>) => {
  return (
    <div className={`${styles.cardWrapper} ${props.className||''}`}>
      <div className={styles.pageHeader}>  {props.title}     </div>
      <div className={styles.cardContent}>{props.children}</div>
    </div>
  );
};
