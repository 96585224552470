import AppCard from "../../../app/base/card"
import { CreateServiceType } from "./CreateServiceType"
import { ServiceTypeList } from "./List"

export const ServiceTypeManager = () => {
    return (
        <AppCard title="Управление типами услуг">
        <CreateServiceType />
        <hr />
        <ServiceTypeList />
    </AppCard>
    )
}