import { Card, Col } from "antd";
import { getUserName } from "../../../models/userUtils";
import styles from './Find.module.css';
import { IFindResultItem } from "./types";


export const FindResultItem = (props: IFindResultItem) => {
    const {item, viewItem, extraItemActions} = props;

    return (
        <>
        <Col span={8} className={styles.resultTitle}>
        {item.persons &&
                <div>
                    {item.persons.map( (i)=> 
                            <div key={`${item.id}_${i.id}`}>
                                {getUserName(i)} {i.birth} - {i.death}
                            </div>
                    )}
                </div>
            }
        </Col>
        <Col span={8}>{`${item.cityName} ${item.cemeteryName}`}</Col>
        </>
    )
}
