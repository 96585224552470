import dayjs from 'dayjs';

export const formatDateTime = (date: string): string => {
    return  dayjs(date).format("DD.MM.YYYY");
}

export const fullSitePath = (src?: string) =>{
    if (!src) return undefined;
    return `${window.location.protocol}//${window.location.host}/${src}`;
}

export const fileNameFromPath = (path?: string): string => {
    if (!path) return '';
    const regex = /[A-Za-z0-9_\-\.]+\.[A-Za-z0-9]+$/;
    const test = path.match(regex);
    return test && test.length? test[0] : path;
}

export const normFile = (e: any) => {if (Array.isArray(e)) return e; return e && e.fileList;};