import { IDictionary } from "./base/dictionary";
import { Person } from "./person";
import { getUserName } from "./userUtils";

export interface FamilyLink {
    id: number;
    person: Person;
    relativePerson: Person;
    personSiblingName: string;
    relativePersonSiblingName: string;
    personBurialId: number;
    relativePersonBurialId: number;
}

export interface CreateFamilyLinkModel {
    personId: number;
    relativePersonId:number;
    personSiblingId: number;
    relativePersonSiblingId: number;
}

export interface Sibling extends IDictionary {
}

export interface relativePerson {
    burialId: number,
    personName: string,
    siblingName: string
}
export const getRelativePersonOfFamilyLink = (fl: FamilyLink, personId: number): relativePerson => {
    const bId = fl.person.id == personId? fl.relativePersonBurialId : fl.personBurialId; 
    const personName = fl.person.id == personId? getUserName(fl.relativePerson): getUserName(fl.person);
    const sibl = fl.person.id == personId? fl.relativePersonSiblingName : fl.personSiblingName;
    return {
        burialId: bId,
        personName,
        siblingName: sibl
    };
}