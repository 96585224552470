import { Button, Card, Col, Form, List, Modal, Row, Space, Spin } from "antd"
import { Link} from "react-router-dom";
import { useCreateFamilyLinkMutation, useDeleteFamilyLinkMutation, useGetFamilyListQuery, useGetSiblingsQuery } from "../../../services/familyLink";
import styles from './PersonFamilyForm.module.css';
import { UserAddOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import { IPersonFamilyFormProps } from "./types";
import { useEffect, useState } from "react";
import { getRelativePersonOfFamilyLink } from "../../../models/familyLink";
import { FindPerson } from "../find/FindPerson";
import { CreateFamilyLink } from "../createFamilyLink/CreateFamilyLink";
import { PersonSearchResult } from "../../../models/personSearchResult";
import notificationService from "../../../app/toast/notificationService";

export const PersonFamilyForm = (props: IPersonFamilyFormProps) => {
    const {personId, editable, personName} = props;
    const [openAddFamilyPerson,setOpenAddFamilyPerson] = useState(false);
    const [openCreate,setOpenCreate] = useState(false);
    const [siblingSource, setSiblingSource] = useState<{ label: string, value: number }[]>();   
    const [selectedRelativePerson, setSelectedRelativePerson] = useState<PersonSearchResult>();
    
    const {data, isLoading} = useGetFamilyListQuery({personId});
    const [addFamilyLink, {isLoading: isAdding, isSuccess:isAddSuccess}] = useCreateFamilyLinkMutation();
    const [deleteFamilyLink, {isLoading: isDeleting}] = useDeleteFamilyLinkMutation();
    const {data:siblings} = useGetSiblingsQuery();


    useEffect(()=>{
        setSiblingSource(siblings?.map(i=>{return {label: i.name, value: i.id}}));
    }, [siblings])

    
    const add = async(personSiblingId: number, relativePersonSiblingId: number) => {
        if (selectedRelativePerson){
            const addResult = await addFamilyLink({personId, relativePersonId: selectedRelativePerson.id,personSiblingId, relativePersonSiblingId}).unwrap();
            if (isAddSuccess && addResult.id == -1) notificationService.info('Для добавления связи создан запрос владельцу персоны. Связь появится после подтверждения запроса владельцем.');
        }
        setOpenCreate(false);
    }
    const deleteFL = (id: number) => {
        deleteFamilyLink(id);
    }
    const openCreateModal = (item: PersonSearchResult)=>{
        setSelectedRelativePerson(item);
        setOpenCreate(true);
    }

    return (
    <div className={styles.w}>
        <Row> 
            {editable && 
            <Button loading={isAdding} onClick={()=>setOpenAddFamilyPerson(!openAddFamilyPerson)}>
                Добавить связь с персоной {openAddFamilyPerson ? <UpOutlined /> : <DownOutlined />}
            </Button>}
        </Row>
        {openAddFamilyPerson? <FindPerson onPersonSelected={openCreateModal} connectedPersonId={personId} /> : <></>}
        <Row>
            <span className={styles.title}>Связанные захоронения</span>
        </Row>
        <Row>
        {isLoading && <Spin size="small"></Spin>}
        {data?.items.map((item, idx)=>{
            const {burialId:bId, personName, siblingName} = getRelativePersonOfFamilyLink(item, personId);
            return (
            <div className={styles.linkItemContainer} key={`${item.person.id}${item.relativePerson.id}${idx}`}>
                <div><Link to={`/burial/${bId}`} state={{from:'list'}}>{siblingName}</Link></div>
                <div className={styles.center}>{personName}</div>
                {editable && <div><Button type="link" onClick={()=>deleteFL(item.id)}>Удалить</Button></div>}
            </div>)
        }
        )}
        </Row>
        {selectedRelativePerson &&
            <Modal open={openCreate} onCancel={()=>setOpenCreate(false)} footer={null} >
                <CreateFamilyLink person={personName} siblingSource={siblingSource||[]} relativePerson={selectedRelativePerson} onSubmit={add} isLoading={isAdding}/>
            </Modal>}
    </div>)
}