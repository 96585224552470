import { baseProtectedApi } from './baseProtectedApi';
import { ListData, ListRequest, ListResponse } from '../models/base/list';
import { ApiResponse } from './models/response';
import { formatDateTime } from './models/util';
import { News, NewsCreateUpdateModel } from '../models/news';

const url = 'news';

const newsApi = baseProtectedApi.injectEndpoints({
  endpoints: build => ({
    getNewsList: build.query<ListData<News>, ListRequest>({
        query: (params) => ({method: 'POST', url: `${url}/list/`, body: params }),
        transformResponse: (response:ListResponse<News>, meta, arg) =>{
            response.result.items.forEach(i => i.createdTimeStamp = formatDateTime(i.createdTimeStamp));
            return   {
                items: response.result.items,
                total: response.result.total
            }
        },
        providesTags: (result) => result?.items
        ? [
            ...result.items.map(({ id }) => ({ type: 'News' as const, id })),
            { type: 'News', id: 'LIST' },
          ]
        : [{ type: 'News', id: 'LIST' }],
      }),
    getNewsItem: build.query<News, number>({
        query: (id) => `${url}/${id}`,
        transformResponse: (response: ApiResponse<News>) => {
            return response.result;
        }
    }), 
    updateNewsItem: build.mutation<News, {id: number, data: NewsCreateUpdateModel}>({
        query(data) {
          const { id, data:body } = data
          return {
            url: `${url}/${id}`,
            method: 'PUT',
            body,
          }
        },
        invalidatesTags: (result, error, arg) => [{type:'News', id: arg.id}]
    }),
    deleteNewsItem: build.mutation<{ success: boolean; id: number }, number>({
        query(id) {
          return {
            url: `${url}/${id}`,
            method: 'DELETE',
          }
        },
        invalidatesTags: ['News']
    }),
    createNewsItem: build.mutation<News, NewsCreateUpdateModel>({
        query(data) {
          return {
            url: `${url}`,
            method: 'POST',
            body: data
          }
        },
        invalidatesTags: ['News']
    }),
  }),
  overrideExisting: true
});

export const { 
    useGetNewsListQuery,
    useGetNewsItemQuery,
    useCreateNewsItemMutation,
    useUpdateNewsItemMutation,
    useDeleteNewsItemMutation
 } = newsApi;
