import { FilterType } from "../../../models/base/filter";

export function FormValuesToBurialFilterType(formValues: any): FilterType  {
    const values = {...formValues};
    if(values.tags)
      values.tags = values.tags.toString().split(' ');
    if (values.deathPeriod) {
      if (values.deathPeriod[0])
        values.deathDateFrom = values.deathPeriod[0].format("DD.MM.YYYY");
      if (values.deathPeriod[1])
        values.deathDateTo = values.deathPeriod[1].format("DD.MM.YYYY");
    }
    if (values.birthPeriod) {
        if (values.birthPeriod[0])
          values.birthDateFrom = values.birthPeriod[0].format("DD.MM.YYYY");
        if (values.birthPeriod[1])
          values.birthDateTo = values.birthPeriod[1].format("DD.MM.YYYY");
      }
    if (values.ageFrom){
      values.ageFrom += ''; 
    } 
    if (values.ageTo){
      values.ageTo += ''; 
    }  
    delete values['deathPeriod'];
    delete values['birthPeriod'];   
    return values;
}