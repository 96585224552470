import { Button, Form, Input, Tooltip } from "antd";
import AppCard from "../../app/base/card";
import { InfoCircleOutlined, MailOutlined } from '@ant-design/icons';
import styles from './RestorePassword.module.css';
import { RestoreRequest, useLazyRestorePasswordQuery} from "../../services/auth";
import { useState } from "react";
import notificationService from "../../app/toast/notificationService";

export const RestorePassword = (props: {email?: string}) => {
    const [resultOpen, setResultOpen] = useState(false);
    const [resultError, setResultError] = useState<string>();
    const [registerEmail, setRegisterEmail] = useState<string>();
    const [restorePassword, {isLoading}] = useLazyRestorePasswordQuery();
    
    const reset = async(data: RestoreRequest)=>{
        await restorePassword(data).then(e=>{
            if(!e.hasOwnProperty('error')){
                setRegisterEmail(data.email); 
                notificationService.info('Письмо успешно отправлено');
            }
            else {
                //const error = e as  {data: {errors: {msg: string}[]}};
                setResultError('error');
            };
            setResultOpen(true);
        });
    }

    return (<>
        <AppCard title="Восстановление пароля">
            {!resultOpen ?
            <>
                <div>
                    Введите адрес электронной почты, указаной при регистрации - на него Вам придет письмо со ссылкой для сброса старого пароля и установки нового пароля.
                </div>
                <div className={styles.restoreForm}>
                <Form  name="resetForm" onFinish={reset}>
                    <Form.Item name="email" rules={[{ required: true, message: 'Введите email!' }]}>
                        <Input 
                            prefix={<MailOutlined className="site-form-item-icon" />}
                            suffix={
                                <Tooltip title="адрес эл. почты">
                                <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                </Tooltip>
                            }
                            placeholder="email"/>
                    </Form.Item>
                    <Form.Item>
                        <Button className={'defaultActionButton commonButton'} loading={isLoading} htmlType='submit'>Сбросить пароль</Button>
                    </Form.Item>
                </Form>
                </div>            
            </>
            :
            !resultError ?
                <div>
                    <div>
                        На указанную почту {registerEmail} выслано письмо. 
                    </div>
                    <div>
                        Для установки пароля проверьте электронную почту и перейдите по ссылке, указанной в письме.
                    </div>
                    <div>
                        <br/>
                        Если вы не видите письмо во входящих сообщениях, проверьте папку спам.
                    </div>
                </div>
                :<div>
                    {resultError}
                </div>                
            }
        </AppCard>
    </>);
}