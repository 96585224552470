import { Upload } from "antd";
import { LoadingOutlined, PlusOutlined, FileOutlined } from '@ant-design/icons';
import { IFileUploadProps } from "./types";
import { RcFile, UploadChangeParam, UploadFile, UploadProps } from "antd/es/upload";
import { beforeUpload, getBase64 } from "../../../features/fileUpload/util";
import { useEffect, useState } from "react";
import styles from './FileUpload.module.css';

export const FileUpload = (props: IFileUploadProps & UploadProps) => {
    const [file,setFile] = useState<RcFile>();
    const [loading, setLoading] = useState(false);

    const {onFileSelected, name, className, description = 'Добавить изображение'} = props;
    const beforeImageUpload = (file: RcFile) => {
        const valid = beforeUpload(file);
        if (!valid) return Upload.LIST_IGNORE;
        setFile(file);
        onFileSelected && onFileSelected(file);
        return false;
    }
    const removeImage = (file: UploadFile<any>) =>{
        if (file){
            setFile(undefined);
            onFileSelected && onFileSelected(undefined);
        }
    }
    const handleChange = (info: UploadChangeParam<UploadFile>)=>{
        // console.log(info.file.status);
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') setLoading(false);
    }
    
    return (
        <Upload
            name={name}
            listType="picture-card"
            className={className}
            // showUploadList={false}
            beforeUpload={beforeImageUpload}
            // onRemove={removeImage}
            onChange={handleChange}
            maxCount={1}
            {...props}
        >
            {(!props.fileList || props.fileList.length < 1) && 
            <div>     
                <PlusOutlined />
                <div className={styles.uploadDesc}>{description}</div>
            </div>
            }
           
        </Upload>
    )
}